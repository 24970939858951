import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Col, Form, Input,Collapse, Popconfirm, Row, Select, Spin, Switch, Table, Tooltip, Upload,} from "antd";
import {DeleteFilled, EditFilled, EyeFilled, UnorderedListOutlined,} from "@ant-design/icons";
import {convertColumns} from "../../../../utils/columnconverter";
// notification
import {getLangs, notify} from "../../../../redux/actions";
import {connect} from "react-redux";
import admin from "../../../../const/api";
import {useTranslation} from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../utils/rules";
const { Panel } = Collapse;

const {Option} = Select;
const File = (props) => {
    const {selectedProduct , productList , spin , setSpin , positions  , getMainData} = props

    const [form] = Form.useForm();
    const editor = useRef()
    const {t} = useTranslation();
    const [reportList, setReportList] = useState([]);
    const [isActive, setActive] = useState(false);
    const [file, setFile] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [selectedId, setSelectedId] = useState(undefined);
    const [editing, setEditing] = useState(null);

    const nameInput = useRef();

    // props
    const {notify} = props;

    const columns = [
        {
            title:'#',
            key: "1",
            dataIndex: "index",
        },
        {
            title: t("name"),
            key: "2",
            dataIndex: "name",
        },
        {
            title: "Status",
            dataIndex: "is_active",
            key: "6",
            render: (i) => {
                return i === 1 ? (
                    <span className="green">Aktiv</span>
                ) : (
                    <span className="red">Deaktiv</span>
                );
            },
        },
        {
            title: "Bax",
            dataIndex: "file",
            key: "6",
            render: (i) => {
                return <a href={i.path} target={'_blank'}><EyeFilled/></a>
            },
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePosition(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled/>
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => setEditingObject(i)}
                            >
                                <EditFilled/>
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];


    const setUploadFile = ({onSuccess, onError, file}) => {
        let form_data = new FormData();
        form_data.append("file", file, file.name);
        admin
            .post("file/upload/single", form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                setFile(res.data.content.file_uuid);
                onSuccess(null, file);
            })
            .catch((err) => onError());
    };


    const setEditingObject = async (i) => {
        setEditing(i);
        let selectedPriority = positions.find((pos) => {  return pos.id === parseInt(selectedId)})
        let names = selectedPriority.files.find((c) => {
            return c.id === i;
        });
        let obj = {};
        names.locales.forEach((name) => {
            obj[`name_${name.local}`] = name.name;
        });
        let arr = [];
        setFile(
            names.file_id
        );
        arr.push({ uid:  names.file_id  , url:  names.file.path });
        setFileList(arr);
        obj['report_id'] =  names.report_id
        setActive(names.is_active === 1 ? true : false)
        form.setFieldsValue(obj);
    };

    const onChange = ({fileList: newFileList}) => {
        setFileList(newFileList);
        if (newFileList.length <= 0) {
            setFile(null);
        }
    };

    const cancelEditing = () => {
        setEditing(null);
        setActive(false);
        form.resetFields();
        setFile(undefined)
        setFileList([])
    };


    const deletePosition = async (i) => {
        await admin
            .delete(`/products/priorities/files/${i}`)
            .then(() => {
                // description
                notify("silindi", true);
                getMainData(selectedProduct)
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        let obj = {
            priority_id:parseInt(selectedId),
            file_id:file,
            is_active:isActive ,
            locales: props.langs.map((l, index) => {
                return {
                    local: l.key,
                    name: values[`name_${l.key}`],
                };
            }),
        };
        if (!editing) {
            await admin
                .post("/products/priorities/files", obj)
                .then((res) => {
                    notify("", true);
                    getMainData(selectedProduct)
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await admin
                .put(`/products/priorities/files/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getMainData(selectedProduct)
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };


    function callback(key) {
        setSelectedId(key)
        form.resetFields()
    }

    return (
        <Collapse accordion className="Customcollapse" onChange={callback}>
            {positions.length > 0 &&
                positions.map((p , i)=>(
                    <Panel header={<span className={'d-inline-flex line-clamp line-1'} dangerouslySetInnerHTML={{__html: p.name}}/>} key={p.id}>
                        <Row gutter={[10, 10]}>
                            <Col xs={24}>
                                <div className="border animated fadeInDown p-1 mt-0 bg-white">
                                    <div className="flex flex-between">
                                        <div>
                                            <UnorderedListOutlined className="f-20 mr5-15" />
                                            <span className="f-20 bold">Fayllar</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Card loading={spin} className="w-100 mb-10">
                                    <Table
                                        loading={spin}
                                        size="small"
                                        className="bg-white animated fadeInLeft"
                                        columns={columns}
                                        dataSource={p.files.map((f , i)=>{
                                            return{
                                                ...f,
                                                key:i,
                                                index:i+1,
                                                name: f.locales.find((l) => {
                                                    return l.local === localStorage.getItem("locale");
                                                }).name,
                                            }
                                        })}
                                        pagination={{
                                            pageSize: 10,
                                            current_page: 1,
                                            total: positions.length,
                                        }}
                                    />
                                </Card>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Card title={t("addTo")} className={"animated fadeInRight"}>
                                    <Form layout="vertical" onFinish={savePosition} form={form}>
                                        <p className="mb-5">Adı</p>
                                        {props.langs.length > 0 ? (
                                            <div>
                                                {props.langs.map((l, i) => {
                                                    return (
                                                        <div key={l.id} className="form-lang">
                                                            <Form.Item
                                                                className="mb-5"
                                                                validateTrigger="onChange"
                                                                name={`name_${l.key}`}
                                                                rules={[whiteSpace(t("inputError"))]}
                                                            >
                                                                <Input ref={nameInput}/>
                                                            </Form.Item>
                                                            <div className="input-lang">{l.key}</div>
                                                        </div>
                                                    );
                                                })}


                                                <div className="border p-2">
                                                    {
                                                        fileList?.length > 0 && 'Fayl'
                                                    }
                                                    <Upload
                                                        fileList={fileList}
                                                        onChange={onChange}
                                                        customRequest={setUploadFile}
                                                        beforeUpload={null}
                                                        className={'w-100'}
                                                    >
                                                        {fileList?.length < 1 &&
                                                        <Button className={'w-100'} type={'primary'}>Fayl yüklə (pdf doc docx
                                                            xlsx)</Button>}
                                                    </Upload>
                                                </div>


                                                <div className="flex mt-15 mb-5">
                                                    <Switch
                                                        className={"mr-15"}
                                                        checked={isActive}
                                                        onChange={() => {
                                                            setActive(!isActive);
                                                        }}
                                                    />
                                                    <span className="mr-15">Status:</span>
                                                    <span className={isActive ? "green" : "red"}>{isActive ? "Aktiv" : "Deaktiv"}</span>
                                                </div>

                                            </div>
                                        ) : (
                                            <Spin size={"large"}/>
                                        )}

                                        <div className="flex  flex-between mt-15">
                                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                                            <Button htmlType="submit">{t("save")}</Button>
                                        </div>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Panel>
                ))
            }

        </Collapse>

    );
};

const mapStateToProps = ({langs}) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, {notify, getLangs})(File);

