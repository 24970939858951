import React, {Component} from 'react';
import {connect} from "react-redux";
import {getLangs , notify} from "../../../../redux/actions";
import {noWhitespace , whiteSpace} from "../../../../utils/rules";
import {withTranslation} from "react-i18next";
import {
    Spin,
    Col,
    Input,
    Modal,
    Form,
    Row,
    Select,
    Button,
    Switch,
} from "antd";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import admin from "../../../../const/api";
import history from "../../../../const/history";
import JoditEditor from "jodit-react";

class EditFaq extends Component {
    formRef = React.createRef();
    editor = React.createRef();

    constructor(props) {
        super(props);
    }

    state = {
        aboutt: {},
        isActive:true,
        previewVisible: false,
        spin:false,
    };


       componentDidMount() {
        this.props.getLangs()
        if (this.props.match.params.id) {
            this.setState({spin:true})
            this.formRef.current.resetFields()
            let obj = {};
            let datas = {}

            const getPost = async () => {
                await admin.get(`faqs`).then((res) => {
                    this.setState({spin:false});
                    let data = res.data.content['faqs'];
                    let editedData = data.find( (l) => {return l.id === parseInt(this.props.match.params.id) } )
                    this.setState({
                        isActive:editedData.is_active === 1 ? true : false,
                    });
                    editedData.locales.forEach((d) => {
                        obj[`question_${d.local}`] = d.question;
                        obj[`answer_${d.local}`] = d.answer;
                        datas[`answer_${d.local}`] = d.answer;
                        this.setState({
                            aboutt:datas,
                        });
                        if(this.formRef.current !== null){
                            this.formRef.current.setFieldsValue(obj);
                        }
                    });
                });
            };
            getPost();
        }
    }


    saveItem = async (values) => {
        let obj = {
            is_active: this.state.isActive ? 1 : 0,
            locales: this.props.langs.map((l) => {
                return {
                    local: l.key,
                    question: values[`question_${l.key}`],
                    answer: values[`answer_${l.key}`],
                };
            }),
        };
        if (!this.props.match.params.id) {
            await admin
                .post(`/faqs`, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    history.push('/faq')
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            await admin
                .put(`/faqs/${this.props.match.params.id}`, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    history.push('/faq')
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };



    render() {
        let { langs , t,  getLangs, notify } = this.props;

        return (
            <div>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                            <div className="page-name">
                                <PicCenterOutlined className="f-20 mr5-15" />
                                <span className="f-20 bold">{this.props.location.state.name}</span>
                            </div>
                            <Link
                                to={{
                                    pathname: `/faq`,
                                }}
                            >
                                <Button type={"primary"}>{t("cancel")}</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={24}>
                        {
                            this.state.spin ?
                                <div className={`flex animated fadeIn p-2 bg-white all-center`}>
                                    <Spin size={"large"} />
                                </div>:
                                <div className={`p-2 animated edit fadeInUp bg-white`}>
                                    <Form ref={this.formRef} onFinish={this.saveItem}  layout="vertical">
                                        <Row gutter={[8, 8]}>
                                            <Col xs={24}>
                                                <div className="flex mb-15">
                                                    <Switch
                                                        className={"mr-15"}
                                                        checked={this.state.isActive}
                                                        onChange={() => {
                                                            this.setState({isActive:!this.state.isActive});
                                                        }}
                                                    />
                                                    <span className="mr-15">Status:</span>
                                                    <span className={this.state.isActive ? "green" : "red"}>
                                                 {this.state.isActive ? "Aktiv" : "Deaktiv"}
                                                </span>
                                                </div>
                                            </Col>


                                            <Col md={24} sm={24} xs={24}>
                                                <p className={"mb-15"}>Ad</p>
                                                {this.props.langs.map((l) => (
                                                    <div key={l.id} className="form-lang">
                                                        <Form.Item
                                                            validateTrigger="onChange"
                                                            name={`question_${l.key}`}
                                                            rules={[noWhitespace(t("inputError"))]}
                                                        >
                                                            <Input className="w-100" />
                                                        </Form.Item>
                                                        <span className="input-lang ">{l.key}</span>
                                                    </div>
                                                ))}
                                            </Col>

                                            <Col xs={24}>
                                                {/*<div className="mb-10">Kontent</div>*/}
                                                {this.props.langs.map((l) => (
                                                    <div key={l.id} className="form-lang">
                                                        <div className="mb-10">
                                                            {l.name}
                                                        </div>
                                                        <Form.Item
                                                            name={`answer_${l.key}`}
                                                            validateTrigger="onChange"
                                                            rules={[noWhitespace(t("inputError"))]}
                                                        >
                                                            <JoditEditor
                                                                ref={this.editor}
                                                                value={this.state.aboutt[`about_${l.key}`]}
                                                                tabIndex={1} // tabIndex of textarea
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                ))}
                                            </Col>

                                            <Col xs={24}>
                                                <div className={"flex"}>
                                                    <Button className={"mr-15"} htmlType="submit">
                                                        {t("save")}
                                                    </Button>
                                                    <Link
                                                        to={{
                                                            pathname: `/faq`,
                                                        }}
                                                    >
                                                        <Button type={"primary"}>{t("cancel")}</Button>
                                                    </Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                        }
                    </Col>
                </Row>

                <Modal
                    visible={this.state.previewVisible}
                    title={false}
                    footer={null}
                    onCancel={() => {
                        this.setState({previewVisible:false})
                    }}
                >
                    <img alt="example" style={{ width: "100%" }} src={this.state.previewImage}  />
                </Modal>
            </div>
        );
    }
}



const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

const exp = withTranslation()(EditFaq);
export default connect(mapStateToProps, { notify, getLangs })(exp);


