import React, {useEffect, useState} from 'react';
import {
    Row,
    Col,
    Table,
    Button,
    Tooltip,
    Spin,
    Popconfirm,
    Modal, Form, Input,
} from "antd";
import {
    PicCenterOutlined,
    EyeFilled,
    DeleteFilled,
    EditFilled,
} from "@ant-design/icons";
import moment from "moment";
import admin from "../../../../const/api";
import { notify, getLangs } from "../../../../redux/actions";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import View from "../../Posts/Post/View";
import {noWhitespace} from "../../../../utils/rules";
import history from "../../../../const/history";

function Partners(props) {
    const [form] = Form.useForm();
    const [postlist, setPostList] = useState([]);
    const [locales, setLocales] = useState([]);
    const [page, setPage] = useState({});
    const [spin, setSpin] = useState(false);
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
    let [trigger, setTrigger] = useState(0);


    const cols = [
        { key: "tableIndex", value: "#", con: true },
        { key: "question", value: "Sual", con: true },
        { key: "answer", value: "Cavab", con: false },
        { key: "is_active", value: "Status", con: false },
        { key: "id", value: "", con: false },
    ];

    const initialColumns = [
        {
            title: "#",
            dataIndex: "tableIndex",
            key: "1",
            width: 60,
        },
        {
            title: "Ad",
            dataIndex: "title",
            key: "2",
        },
        {
            title: "Qısa xülasə",
            dataIndex: "description",
            key: "3",
            render: (i) => {
                return <div dangerouslySetInnerHTML={{
                    __html: i
                }}></div>
            },
        },
        {
            title: "Status",
            dataIndex: "is_active",
            key: "6",
            render: (i) => {
                return i === 1 ? (
                    <span className="green">Aktiv</span>
                ) : (
                    <span className="red">Deaktiv</span>
                );
            },
        },
        {
            title: "",
            dataIndex: "id",
            key: "9",
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Link
                                to={{
                                    pathname: `partners/edit/${i}`,
                                    state: {
                                        name: locales.length > 0 && locales?.find((l)=>{return l.local === localStorage.getItem('locale')}).title
                                    }
                                }}
                            >
                                <Button className="border-none" type="text" shape="circle">
                                    <EditFilled />
                                </Button>
                            </Link>
                        </Tooltip>
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePost(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];
    const { notify  , getLangs , langs} = props;


    const deletePost = async (i) => {
        if (i === 0 || i) {
            await admin
                .delete(`international-partners/${i}`)
                .then((res) => {
                    setTrigger(++trigger);
                    notify("silindi", true);
                })
                .catch((res) => {
                    notify(res.err, false);
                });
        }
    };


    const getPostList = () => {
        setSpin(true);
        admin.get(`international-partners/page`).then((res) => {
            res.data?.content && setSpin(false);
            if(res.data.content === {}){
              setShow(true)
            }
            else{
                setLocales(res.data.content)
            }
        });
        admin.get(`international-partners`).then((res) => {
            res.data?.content && setSpin(false);
                setPostList(
                    res.data.content.map((d, index) => {
                        return {
                            ...d,
                            key: index + 1,
                            index,
                            tableIndex: index + 1,
                            title: d.locales.find((l) => {
                                return l.local === localStorage.getItem("locale");
                            }).title,
                            description: d.locales.find((l) => {
                                return l.local === localStorage.getItem("locale");
                            }).description,
                        };
                    })
                );
        });
    };

    useEffect(() => {
        getPostList();
        getLangs();
    }, [t, trigger]);

    const triggerFetch = () =>  {
        setTrigger(++trigger)
    }

    const Edit = () =>{
        setShow(true);
        let obj = {};
        locales.forEach((name) => {
            obj[`title_${name.local}`] = name.title;
            obj[`description_${name.local}`] = name.description;
        });
        form.setFieldsValue(obj);
    }

    const saveItem = async (values) =>{
        let obj = {
            locales: props.langs.map((l) => {
                return {
                    local: l.key,
                    title: values[`title_${l.key}`],
                    description: values[`description_${l.key}`],
                };
            }),
        };
        await admin
            .post(`international-partners/page`, obj)
            .then((res) => {
                notify("", true);
                form.resetFields();
                triggerFetch()
                setShow(false)
            })
            .catch((err) => {
                notify(err.response, false);
            });
    }

    return (
        <div>
            <Row gutter={[10, 10]}>
                <Col xs={24}>
                    <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <PicCenterOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">{locales.length > 0 && locales?.find((l)=>{return l.local === localStorage.getItem('locale')}).title}</span>
                        </div>
                        <div>
                            <Link
                                to={{
                                    pathname: `partners/edit`,
                                    state: {
                                        name: locales.length > 0 && locales?.find((l)=>{return l.local === localStorage.getItem('locale')}).title
                                    }
                                }}
                            >
                                <Button type={"primary"}>Əlavə et</Button>
                            </Link>
                        </div>
                    </div>
                </Col>
                <>
                    {spin ? (
                        <Col xs={24}>
                            <div className="flex animated fadeInUp bg-white all-center p-2">
                                <Spin size={"large"} />
                            </div>
                        </Col>
                    ) : (
                        <>
                            <Col xs={24}>
                                <div className="p-2 bg-white">
                                    {!show ?
                                        <>
                                            <div className={'border p-2'}>{locales.length > 0 && locales?.find((l)=>{return l.local === localStorage.getItem('locale')}).description}</div>
                                            <Button onClick={()=>{ Edit() }} className={'mt-15'} type={'primary'}>{t('edit')}</Button>
                                        </> :
                                        <>

                                            <div className="animated fadeInUp">
                                                <Form form={form} onFinish={saveItem} layout="vertical">
                                                    <Row gutter={[8, 8]}>
                                                        <Col md={12} sm={12} xs={24}>
                                                            <p className={"mb-15"}>Başlıq</p>
                                                            {langs.map((l) => (
                                                                <div key={l.id} className="form-lang">
                                                                    <Form.Item
                                                                        validateTrigger="onChange"
                                                                        name={`title_${l.key}`}
                                                                        rules={[noWhitespace(t("inputError"))]}
                                                                    >
                                                                        <Input className="w-100" />
                                                                    </Form.Item>
                                                                    <span className="input-lang ">{l.key}</span>
                                                                </div>
                                                            ))}
                                                        </Col>
                                                        <Col md={12} sm={12} xs={24}>
                                                            <p className={"mb-15"}>Qısa xülasə</p>
                                                            {langs.map((l) => (
                                                                <div key={l.id} className="form-lang">
                                                                    <Form.Item
                                                                        validateTrigger="onChange"
                                                                        name={`description_${l.key}`}
                                                                        rules={[noWhitespace(t("inputError"))]}
                                                                    >
                                                                        <Input className="w-100" />
                                                                    </Form.Item>
                                                                    <span className="input-lang ">{l.key}</span>
                                                                </div>
                                                            ))}
                                                        </Col>
                                                        <Col xs={24}>
                                                            <div className="flex flex-between">
                                                                <div>
                                                                    <Button htmlType={'submit'} className={'mr-5'} type={'primary'}>
                                                                        {t('save')}
                                                                    </Button>
                                                                    <Button onClick={()=>{ form.resetFields(); setShow(false) }} >
                                                                        {t('cancel')}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </div>
                                        </>
                                    }
                                </div>
                            </Col>
                            <Col xs={24}>
                                <Table
                                    size="small"
                                    className="bg-white animated fadeIn"
                                    columns={initialColumns}
                                    dataSource={convertColumns(postlist, cols)}
                                    pagination={{
                                        pageSize: 25,
                                        current_page: 1,
                                    }}
                                />
                            </Col>
                        </>
                    )}
                </>
            </Row>
        </div>
    );
}


const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify , getLangs })(Partners);


