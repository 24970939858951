import React, {useEffect, useState, useRef} from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Switch,
    Spin,
    Form,
    Tooltip,
    Input,
    Popconfirm, Upload,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
} from "@ant-design/icons";
import {convertColumns} from "../../../../utils/columnconverter";
import {notify, getLangs} from "../../../../redux/actions";
import {connect} from "react-redux";
import admin from "../../../../const/api";
import {useTranslation} from "react-i18next";
import {whiteSpace} from "../../../../utils/rules";
import ImgCrop from "antd-img-crop";
import JoditEditor from "jodit-react";

const ProductList = (props) => {
    const editor = useRef()
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const [file, setFile] = useState(null);
    const [positions, setPositions] = useState([]);
    const [isActive, setActive] = useState(false);
    const [isMap, setIsMap] = useState(false);
    const [showPriority, setShowPriority] = useState(false);
    const [showDocuments, setShowDocuments] = useState(false);
    const [showCalculator, setShowCalculator] = useState(false);
    const [showChart, setShowChart] = useState(false);
    const [showRoadMap, setShowRoadMap] = useState(false);
    const [spin, setSpin] = useState(false);
    const [editing, setEditing] = useState(null);
    const cols = [
        {key: "index", value: "#", con: true},
        {key: "name", value: t("name"), con: true},
        {key: "is_active", value: "Status", con: false},
        {key: "id", value: "", con: false},
    ];
    const nameInput = useRef();
    // file upload
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState("");
    const [previewVisible, setPreviewVisible] = useState(false);
    const onChange = ({fileList: newFileList}) => {
        setFileList(newFileList);
        if (newFileList.length <= 0) {
            setFile(null);
        }
    };

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const setUploadFile = ({onSuccess, onError, file}) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("image", file, filename);
        admin
            .post("file/upload/single/image", form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                setFile(res.data.content.image_uuid);
                onSuccess(null, file);
            })
            .catch((err) => onError());
    };

    // props
    const {notify, getLangs} = props;

    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title: "",
            dataIndex: "picture",
            key: "9",
            render: (i) => {
                return <img className={"tableImage"} src={i.path} alt=""/>;
            },
        },
        {
            title: t("name"),
            key: "2",
            dataIndex: "name",
        },
        {
            title: "Status",
            dataIndex: "is_active",
            key: "6",
            render: (i) => {
                return i === 1 ? (
                    <span className="green">Aktiv</span>
                ) : (
                    <span className="red">Deaktiv</span>
                );
            },
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePosition(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled/>
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => {
                                    setEditingObject(i)
                                    setEditingObject(i)
                                }}
                            >
                                <EditFilled/>
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const setEditingObject = async (i) => {
        setEditing(i);
        form.resetFields()
        await admin.get(`products`).then((res) => {
            let names = res.data.content.find((c) => {
                return c.id === i;
            });
            let obj = {};
            let arr = [];
            setFile(names.picture_id);
            arr.push({uid: names.picture_id, url: names.picture.path});
            setFileList(arr);
            names.locales.forEach((name) => {
                obj[`name_${name.local}`] = name.name;
                obj[`description_${name.local}`] = name.description;
                obj[`document_note_${name.local}`] = name.document_note;
                obj[`priority_note_${name.local}`] = name.priority_note;
            });
            setActive(names.is_active === 1 ? true : false);
            setIsMap(names.show_map === 1 ? true : false);
            setShowPriority(names.show_priority === 1 ? true : false);
            setShowDocuments(names.show_document === 1 ? true : false);
            setShowCalculator(names.show_calculator === 1 ? true : false);
            setShowChart(names.show_chart === 1 ? true : false);
            setShowRoadMap(names.show_roadmap === 1 ? true : false);
            form.setFieldsValue(obj);
        });
    };

    const cancelEditing = () => {
        setFile(null)
        setFileList([])
        setEditing(null);
        setActive(false);
        setShowCalculator(false);
        setShowDocuments(false);
        setShowChart(false);
        setShowRoadMap(false);
        setIsMap(false);
        setShowPriority(false);
        form.resetFields();
    };

    const deletePosition = async (i) => {
        await admin
            .delete(`/products/${i}`)
            .then(() => {
                // description
                notify("silindi", true);
                getPositions();
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        let obj = {
            picture_id: file,
            is_active: isActive ? 1 : 0,
            show_map: isMap ? 1 : 0,
            show_priority: showPriority ? 1 : 0,
            show_document: showDocuments ? 1 : 0,
            show_calculator: showCalculator ? 1 : 0,
            show_roadmap:showRoadMap,
            show_chart:showChart,
            locales: props.langs.map((l, index) => {
                return {
                    local: l.key,
                    name: values[`name_${l.key}`] ? values[`name_${l.key}`]: undefined,
                    document_note:values[`document_note_${l.key}`] ? values[`document_note_${l.key}`] : undefined,
                    priority_note: values[`priority_note_${l.key}`] ? values[`priority_note_${l.key}`] : undefined,
                    description: values[`description_${l.key}`] ? values[`description_${l.key}`] : undefined
                };
            }),
        };
        if (!editing) {
            await admin
                .post("/products", obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await admin
                .put(`/products/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async () => {
        setSpin(true);
        await admin.get("products").then((res) => {
            setSpin(false);
            setPositions(
                res.data.content.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                        name: p.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).name,
                    };
                })
            );
        });
    };

    useEffect(() => {
        getPositions();
        getLangs();
    }, [t]);

    const onSwitchChange = () => {
        setActive(!isActive);
    };

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15"/>
                    <span className="f-20 bold">Məhsullar</span>
                </div>
            </Col>
            <Col lg={12} xs={24}>
                <Table
                    loading={spin}
                    size="small"
                    className="bg-white animated fadeInLeft"
                    columns={columns}
                    dataSource={convertColumns(positions, cols)}
                    pagination={{
                        pageSize: 10,
                        current_page: 1,
                        total: positions.length,
                    }}
                />
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("addTo")} className={"animated fadeInRight"}>
                    <Form layout="vertical" onFinish={savePosition} form={form}>
                        <Form.Item
                            validateTrigger="onChange"
                            name={`photo`}
                            required
                        >
                            <ImgCrop
                                className={"w-100"}
                                rotate
                                aspect={1152 / 2048}
                                grid
                            >
                                <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={onChange}
                                    onPreview={handlePreview}
                                    customRequest={setUploadFile}
                                    beforeUpload={null}
                                >
                                    {fileList.length < 1 && "+ Yüklə"}
                                </Upload>
                            </ImgCrop>
                        </Form.Item>

                        <div className="p-1 border">
                            <div className="flex flex-align-center">
                                <Switch checked={isActive} onChange={onSwitchChange}/>
                                <span className="ml-10 mr-10">Status</span>
                                <span className={isActive ? "green mr-10" : "red mr-10"}>
                                 {isActive ? "Aktiv" : "Deaktiv"}
                                </span>
                            </div>
                        </div>

                        <div className="p-1 border mt-15 mb-10">
                           <Row gutter={[16,16]}>
                               <Col md={12} xs={24}>
                                   <Switch checked={showCalculator} onChange={() => {
                                       setShowCalculator(!showCalculator)
                                   }}/>
                                   <span className="ml-10 mr-10">Kredit Kalkulyatoru</span>
                                   <span className={showCalculator ? "green mr-10" : "red mr-10"}>
                                        {showCalculator ? "Aktiv" : "Deaktiv"}
                                </span>
                               </Col>
                               <Col md={12} xs={24}>
                                   <Switch checked={showPriority} onChange={() => {
                                       setShowPriority(!showPriority)
                                   }}/>
                                   <span className="ml-10 mr-10">Prioritet istiqamətlər</span>
                                   <span className={showPriority ? "green mr-10" : "red mr-10"}>
                                      {showPriority ? "Aktiv" : "Deaktiv"}
                                   </span>
                               </Col>
                               <Col md={12} xs={24}>
                                   <Switch checked={showDocuments} onChange={() => {
                                       setShowDocuments(!showDocuments)
                                   }}/>
                                   <span className="ml-10 mr-10">Tələb olunan sənədlər</span>
                                   <span className={showDocuments ? "mr-15 green" : "mr-15 red"}>
                                        {showDocuments ? "Aktiv" : "Deaktiv"}
                                    </span>
                               </Col>
                               <Col md={12} xs={24}>
                                   <Switch checked={isMap} onChange={() => {
                                       setIsMap(!isMap)
                                   }}/>
                                   <span className="ml-15 mr-10">Xəritə</span>
                                   <span className={isMap ? "green" : "red"}>
                                       {isMap ? "Aktiv" : "Deaktiv"}
                                    </span>
                               </Col>
                               <Col md={12} xs={24}>
                                   <Switch checked={showRoadMap} onChange={() => {
                                       setShowRoadMap(!showRoadMap)
                                   }}/>
                                   <span className="ml-10 mr-10">Sahibkarlığın yol xəritəsi</span>
                                   <span className={showRoadMap ? "mr-15 green" : "mr-15 red"}>
                                        {showRoadMap ? "Aktiv" : "Deaktiv"}
                                    </span>
                               </Col>
                               <Col md={12} xs={24}>
                                   <Switch checked={showChart} onChange={() => {
                                       setShowChart(!showChart)
                                   }}/>
                                   <span className="ml-15 mr-10">Mexanizm şərhi</span>
                                   <span className={showChart ? "green" : "red"}>
                                       {showChart ? "Aktiv" : "Deaktiv"}
                                    </span>
                               </Col>
                           </Row>



                        </div>


                        <p className="mb-5 mt-15">Məhsulun adı</p>
                        {props.langs ? (
                            props.langs.map((l, i) => {
                                return (
                                    <div key={l.id} className="form-lang">
                                        <Form.Item
                                            className="mb-5"
                                            validateTrigger="onChange"
                                            name={`name_${l.key}`}
                                            rules={[whiteSpace(t("inputError"))]}
                                        >
                                            <Input ref={nameInput}/>
                                        </Form.Item>
                                        <div className="input-lang">{l.key}</div>
                                    </div>
                                );
                            })
                        ) : (
                            <Spin size={"large"}/>
                        )}


                        <p className="mb-5">Qısa xülasə</p>

                        {props.langs ? (
                            props.langs.map((l, i) => {
                                return (
                                    <div key={l.id} className="form-lang">
                                        <Form.Item
                                            className="mb-5"
                                            validateTrigger="onChange"
                                            name={`description_${l.key}`}
                                            rules={[whiteSpace(t("inputError"))]}
                                        >
                                            <Input ref={nameInput}/>
                                        </Form.Item>
                                        <div className="input-lang">{l.key}</div>
                                    </div>
                                );
                            })
                        ) : (
                            <Spin size={"large"}/>
                        )}


                        <p className="mb-5">Ümumi faiz dərəcəsi</p>
                        {props.langs ? (
                            props.langs.map((l, i) => {
                                return (
                                    <div key={l.id} className="form-lang">
                                        <Form.Item
                                            className="mb-5"
                                            validateTrigger="onChange"
                                            name={`priority_note_${l.key}`}
                                        >
                                            <p>{l.name}</p>
                                            <JoditEditor
                                                onChange={(newContent) => {
                                                    form.setFieldsValue({
                                                        [`priority_note_${l.key}`]: newContent
                                                    })
                                                }}
                                                className={'mb-15'}
                                                ref={editor}
                                                value={form.getFieldsValue()[`priority_note_${l.key}`]}
                                                tabIndex={1} // tabIndex of textarea
                                            />
                                        </Form.Item>
                                    </div>
                                );
                            })
                        ) : (
                            <Spin size={"large"}/>
                        )}

                        <h3 className={"mt-15"}>Tələb olunan sənədlərə aid qeyd</h3>
                        {props.langs.map((l, li) => (
                            <Form.Item
                                name={`document_note_${l.key}`}
                                validateTrigger="onChange"
                            >
                                <p>{l.name}</p>
                                <JoditEditor
                                    onChange={(newContent) => {
                                        form.setFieldsValue({
                                            [`document_note_${l.key}`]: newContent
                                        })
                                    }}
                                    className={'mb-15'}
                                    ref={editor}
                                    value={form.getFieldsValue()[`document_note_${l.key}`]}
                                    tabIndex={1} // tabIndex of textarea
                                />
                            </Form.Item>
                        ))}

                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({langs}) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, {notify, getLangs})(ProductList);
