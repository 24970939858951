import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
    Row,
    Col,
    Button,
    Tooltip,
    Card,
    Popconfirm,
} from "antd";
import {
    PicCenterOutlined,
    DeleteFilled,
    EditFilled,
} from "@ant-design/icons";
import admin from "../../../../const/api";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { notify } from "../../../../redux/actions";

const { Meta } = Card;

const Directory = (props) => {
    const [spin, setSpin] = useState(true);
    const [gallery, setGallery] = useState([]);
    const { notify } = props;
    let [trigger, setTrigger] = useState(0);

    const { t } = useTranslation();
    const getDirectory = () => {
        setSpin(true);
        admin.get(`company/directories`).then((res) => {
            res.data.content && setSpin(false);

            setGallery(
                res.data.content.map((d, index) => {
                    return {
                        ...d,
                        key: index + 1,
                        index,
                        name: d.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).name,
                        position: d.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).position
                    };
                })
            );
        });
    };

    const deleteDirecTory = async (i) => {
        if (i === 0 || i) {
            await admin
                .delete(`company/directories/${i}`)
                .then((res) => {
                    setTrigger(++trigger);
                    notify("silindi", true);
                })
                .catch((res) => {
                    notify(res.err, false);
                });
        }
    };

    useEffect(() => {
        getDirectory();
    }, [t, trigger]);

    return (
        <div>
            <Row gutter={[10, 10]}>
                <Col xs={24}>
                    <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <PicCenterOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Rəhbərlik</span>
                        </div>
                        <div>
                            <Link
                                to={{
                                    pathname: `/directories/edit`,
                                    state: { locales: null },
                                }}
                            >
                                <Button type={"primary"}>Əlavə et</Button>
                            </Link>
                        </div>
                    </div>
                </Col>

                <Col xs={24}>
                    <Card loading={spin}>
                        <Row  gutter={[16, 16]}>
                            {gallery.map((g, i) => (
                                <Col key={i} lg={6} md={6} sm={8} xs={12}>
                                    <Card
                                        className={"animated high w-100 zoomIn"}
                                        hoverable
                                        cover={
                                            !spin && (
                                                    <img alt="example" src={g.picture.path} />
                                            )
                                        }
                                        actions={
                                            !spin && [
                                                <Link
                                                    to={{
                                                        pathname: `/directories/edit/${g.id}`,
                                                        state: { path: g.picture.path },
                                                    }}
                                                >
                                                    <Tooltip
                                                        placement="bottom"
                                                        className="ml-5"
                                                        title={t("edit")}
                                                    >
                                                        <Button
                                                            // onClick={() => viewPurchaseDetails(i, "waiting", 1)}
                                                            className="border-none"
                                                            type="text"
                                                            shape="circle"
                                                        >
                                                            <EditFilled />
                                                        </Button>
                                                    </Tooltip>
                                                </Link>,
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={t("areYouSure")}
                                                    onConfirm={() => deleteDirecTory(g.id)}
                                                    okText={t("yes")}
                                                    cancelText={t("no")}
                                                >
                                                    <Tooltip
                                                        placement="bottom"
                                                        className="ml-5"
                                                        title={t("delete")}
                                                    >
                                                        <Button
                                                            className="border-none"
                                                            type="text"
                                                            shape="circle"
                                                        >
                                                            <DeleteFilled />
                                                        </Button>
                                                    </Tooltip>
                                                </Popconfirm>,
                                            ]
                                        }
                                    >
                                        <Meta
                                            title={
                                                g.locales.find((l) => {
                                                    return l.local === localStorage.getItem("locale");
                                                }).name
                                            }
                                            description={
                                                g.locales.find((l) => {
                                                    return l.local === localStorage.getItem("locale");
                                                }).position
                                            }
                                        />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default connect(null, { notify })(Directory);
