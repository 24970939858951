import React, { useEffect, useState } from "react";
import {
  Form,
  InputNumber,
  Input,
  Skeleton,
  Row,
  Col,
  Button,
  Card,
  Upload,
  Switch,
} from "antd";
import { noWhitespace, whiteSpace } from "../../utils/rules";
import { PicCenterOutlined } from "@ant-design/icons";
import MaskedInput from "antd-mask-input";
import {getLangs, notify} from "../../redux/actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import admin from "../../const/api";
import history from "../../const/history";

const EditContact = (props) => {
  const { notify } = props;
  const [id, setId] = useState(undefined);
  const [spin, setSpin] = useState(false);
  const [form] = Form.useForm();
  const [phones, setPhones] = useState([]);
  const { t } = useTranslation();

  const saveItem = (values) => {
    let arr = [];
    values.phones.forEach((d) => {
      let obj = {};
      obj[`value`] = d.phone;
      obj[`type`] = 1;
      arr.push(obj);
    });
    arr.push({
      value: values.fax,
      type: 2,
    });
    if (values.facebook){
      arr.push({
        value: values.facebook,
        type: 5,
      });
    }
    if (values.youtube){
      arr.push({
        value: values.youtube,
        type: 6,
      });
    }
    if (values.twitter){
      arr.push({
        value: values.twitter,
        type: 7,
      });
    }
    if (values.linkedin){
      arr.push({
        value: values.linkedin,
        type: 8,
      });
    }
    if (values.instagram){
      arr.push({
        value: values.instagram,
        type: 9,
      });
    }
      if (values.platform){
          arr.push({
              value: values.platform,
              type: 10,
          });
      }
    arr.push({
      value: values.email,
      type: 3,
    });
    admin
      .post(`contact/info`, {
        locales: props.langs.map((l, index) => {
          return {
            local: l.key,
            address: values[`address_${l.key}`],
          };
        }),
        latitude: values.latitude,
        longitude: values.longitude,
        contacts: arr,
      })
      .then(() => {
        notify("", true);
        history.push("/contact-info");
      })
      .catch((err) => {
        notify(err.response, false);
      });
  };

  const getInfos = () => {
    setSpin(true);
    admin.get(`/contact/info`).then((res) => {
      setSpin(false);
      if (res.data.content[0]){
        setId(res.data.content[0].id);
        let arr = [];
        let obj = {};
        res.data.content[0].contacts
            .filter((e) => {
              return e.type === 1;
            })
            .forEach((d) => {
              let obj = {
                phone: d.value,
              };
              arr.push(obj);
            });
        setPhones(arr);
        form.setFieldsValue({
          address: res.data.content[0].address,
          phones: arr,
          fax: res.data.content[0].contacts.find((e) => {
            return e.type === 2;
          }).value,
          email: res.data.content[0].contacts.find((e) => {
            return e.type === 3;
          }).value,
          facebook: res.data.content[0].contacts.find((e) => {
            return e.type === 5;
          })?.value,
          youtube: res.data.content[0].contacts.find((e) => {
            return e.type === 6;
          })?.value,
          twitter: res.data.content[0].contacts.find((e) => {
            return e.type === 7;
          })?.value,
          linkedin: res.data.content[0].contacts.find((e) => {
            return e.type === 8;
          })?.value,
          instagram: res.data.content[0].contacts.find((e) => {
            return e.type === 9;
          })?.value,
          platform: res.data.content[0].contacts.find((e) => {
            return e.type === 10;
          })?.value,
          latitude: eval(res.data.content[0].latitude),
          longitude: eval(res.data.content[0].longitude),
        });
      }
    });
  };

  const addPhone = () => {
    const all = [...phones];
    all.push({});
    setPhones(all);
  };

  const removePhone = (index) => {
    const all = [...phones];
    all.splice(index, 1);
    setPhones(all);
    form.setFieldsValue({ phones: all });
  };

  useEffect(() => {
    getInfos();
    props.getLangs();
  }, [t]);

  return (
    <div>
      <Form form={form} onFinish={saveItem} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
              <div className="page-name">
                <PicCenterOutlined className="f-20 mr5-15" />
                <span className="f-20 bold">Əlaqə məlumatları</span>
              </div>
              <div>
                <Button htmlType={"submit"}>{t("save")}</Button>
                <Link to={"/contact-info"}>
                  {" "}
                  <Button type={"primary"}>{t("cancel")}</Button>
                </Link>
              </div>
            </div>
          </Col>

          <Col xs={24}>
            <Card
              loading={spin}
              className={"d-block h-100 animated fadeInRight"}
            >
              <div className={"border p-2 pb-1"}>
                <Row gutter={[8, 0]}>
                  <Col xs={24}>
                    {props.langs.map((l , i)=>(
                        <div key={l.id} className="form-lang">
                          <Form.Item
                              className="mb-5"
                              validateTrigger="onChange"
                              name={`address_${l.key}`}
                              rules={[whiteSpace(t("inputError"))]}
                          >
                            <Input placeholder={'Address'} />
                          </Form.Item>
                          <div className="input-lang">{l.key}</div>
                        </div>
                    ))}
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      className={"mb-10"}
                      validateTrigger="onChange"
                      name={`latitude`}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <Input placeholder={"Latitude"} className={"w-100"} />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      className={"mb-10"}
                      validateTrigger="onChange"
                      name={`longitude`}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <Input placeholder={"Longitude"} className={"w-100"} />
                    </Form.Item>
                  </Col>

                  <Col xs={12}>
                    <div className="p-2 border">
                      <div className="flex mb-15  flex-align-center flex-between">
                        <span>Telefon</span>
                        {phones.length <= 4 && (
                          <div>
                            <Button onClick={addPhone}>{t("addTo")}</Button>
                          </div>
                        )}
                      </div>
                      {phones.map((e, i) => {
                        return (
                          <div key={i} className="flex mb-10 ">
                            <Form.Item
                              className={"mb-0 w-100 mr-10"}
                              validateTrigger="onChange"
                              name={["phones", i, "phone"]}
                              rules={[
                                noWhitespace(t("inputError")),
                                {
                                  min: 18,
                                  message:
                                    "Əlaqə nömrəsi tam daxil edilməlidir !",
                                },
                              ]}
                            >
                              <MaskedInput
                                className={"mr-10"}
                                mask="(+994WW) WWW-WW-WW"
                                placeholder="(+99412) 310-23-00"
                                {...props}
                                formatCharacters={{
                                  W: {
                                    validate(char) {
                                      return /\w/.test(char);
                                    },
                                    transform(char) {
                                      return char.toUpperCase();
                                    },
                                  },
                                }}
                              />
                            </Form.Item>
                            <Button
                              onClick={() => {
                                removePhone(i);
                              }}
                              size={"large"}
                              className="btn-danger"
                            >
                              X
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="p-2 border">
                      <span>
                        <div className="flex mb-15  flex-align-center">
                          <span>Faks</span>
                        </div>
                        <Form.Item
                          className={"mb-10"}
                          validateTrigger="onChange"
                          name={`fax`}
                          rules={[
                            noWhitespace(t("inputError")),
                            {
                              min: 18,
                              message: "Əlaqə nömrəsi tam daxil edilməlidir !",
                            },
                          ]}
                        >
                          <MaskedInput
                            className={"mr-10"}
                            mask="(+994WW) WWW-WW-WW"
                            placeholder="(+99412) 310-23-00"
                            {...props}
                            formatCharacters={{
                              W: {
                                validate(char) {
                                  return /\w/.test(char);
                                },
                                transform(char) {
                                  return char.toUpperCase();
                                },
                              },
                            }}
                          />
                        </Form.Item>
                      </span>
                      <span>
                        <div className="flex mb-15  flex-align-center">
                          <span>Email</span>
                        </div>
                        <Form.Item
                          className={"mb-10"}
                          validateTrigger="onSave"
                          name={`email`}
                          rules={[
                            {
                              type: "email",
                              message: "Emaili düzgün daxil edin !",
                            },
                            noWhitespace(t("inputError")),
                          ]}
                        >
                          <Input placeholder={"info@sif.gov.az"} />
                        </Form.Item>
                      </span>
                      <span>
                        <div className="flex mb-15  flex-align-center">
                          <span>Elektron kredit platforması</span>
                        </div>
                        <Form.Item
                          className={"mb-10"}
                          validateTrigger="onSave"
                          name={`platform`}
                          rules={[
                            noWhitespace(t("inputError")),
                          ]}
                        >
                          <Input placeholder={"e-edf.gov.az"} />
                        </Form.Item>
                      </span>

                      <span>
                        <div className="flex mb-15  flex-align-center">
                          <span>Linkedin</span>
                        </div>
                        <Form.Item
                            className={"mb-10"}
                            validateTrigger="onSave"
                            name={`linkedin`}
                            rules={[
                              noWhitespace(t("inputError")),
                            ]}
                        >
                          <Input  />
                        </Form.Item>
                      </span>

                      <span>
                        <div className="flex mb-15  flex-align-center">
                          <span>Facebook</span>
                        </div>
                        <Form.Item
                            className={"mb-10"}
                            validateTrigger="onSave"
                            name={`facebook`}
                            rules={[
                              noWhitespace(t("inputError")),
                            ]}
                        >
                          <Input  />
                        </Form.Item>
                      </span>

                      <span>
                        <div className="flex mb-15  flex-align-center">
                          <span>Twitter</span>
                        </div>
                        <Form.Item
                            className={"mb-10"}
                            validateTrigger="onSave"
                            name={`twitter`}
                            rules={[
                              noWhitespace(t("inputError")),
                            ]}
                        >
                          <Input  />
                        </Form.Item>
                      </span>

                      <span>
                        <div className="flex mb-15  flex-align-center">
                          <span>Youtube</span>
                        </div>
                        <Form.Item
                            className={"mb-10"}
                            validateTrigger="onSave"
                            name={`youtube`}
                            rules={[
                              noWhitespace(t("inputError")),
                            ]}
                        >
                          <Input  />
                        </Form.Item>
                      </span>

                      <span>
                        <div className="flex mb-15  flex-align-center">
                          <span>Instagram</span>
                        </div>
                        <Form.Item
                            className={"mb-10"}
                            validateTrigger="onSave"
                            name={`instagram`}
                            rules={[
                              noWhitespace(t("inputError")),
                            ]}
                        >
                          <Input  />
                        </Form.Item>
                      </span>







                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};


const mapStateToProps = ({langs}) => {
  return {
    langs: langs,
  };
};

export default connect(mapStateToProps, { notify, getLangs })(EditContact);
