import React,{useEffect, useState} from 'react';
import {Col, Form, Input, InputNumber, Button ,Row} from "antd";
import {noWhitespace} from "../../../../../utils/rules";
import {getLangs, notify} from "../../../../../redux/actions";
import admin from "../../../../../const/api";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

function EditCredit(props) {
    const {  notify , credit ,productId, triggerFetch , langs , getLangs ,setVisibleEditCredit } = props;
    const [form] = Form.useForm();
    const { t } = useTranslation();

    useEffect(() => {
        getLangs()
        form.resetFields();
        if (credit) {
            let objs = {
                product_id:productId,
                min_amount: credit.min_amount,
                max_amount:credit.max_amount,
                max_loan_period: credit.max_loan_period,
                grace_period: credit.grace_period
            }
            credit.locales.forEach((d) => {
                objs[`names_${d.local}`] = d.name;
                objs[`descriptions_${d.local}`] = d.description;
            });
            form.setFieldsValue(objs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credit, t]);

    const saveCredit = (values) => {
        let obj = {
            product_id:productId,
            min_amount:parseInt(values.min_amount),
            max_amount: parseInt(values.max_amount),
            max_loan_period: parseInt(values.max_loan_period),
            grace_period: parseInt(values.grace_period),
            locales: props.langs.map((l) => {
                return {
                    local: l.key,
                    name: values[`names_${l.key}`],
                    description: values[`descriptions_${l.key}`],
                };
            }),
        };
        if (credit) {
            admin
                .put(`products/properties/${credit.id}`, obj)
                .then((res) => {
                    notify("", true);
                    triggerFetch();
                    form.resetFields();
                    setVisibleEditCredit(false);
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            admin
                .post(`products/properties`, obj)
                .then((res) => {
                    notify("", true);
                    triggerFetch();
                    form.resetFields();
                    setVisibleEditCredit(false);
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };


    return (
        <Form form={form} layout="vertical" onFinish={saveCredit}>
            <div className={'mt-20 mb-15'}>
                <Row gutter={[16, 16]}>
                    <Col md={12}>
                        <div>
                            <p className={"mb-5"}>Minimum məbləğ</p>
                            <div className="form-lang">
                                <Form.Item
                                    validateTrigger="onChange"
                                    name={"min_amount"}
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                    <InputNumber className="w-100" />
                                </Form.Item>
                                <span className="input-lang ">azn</span>
                            </div>
                        </div>
                        <div>
                            <p className={"mb-5"}>Maxium məbləğ</p>
                            <div className="form-lang">
                                <Form.Item
                                    validateTrigger="onChange"
                                    name={"max_amount"}
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                    <InputNumber className="w-100" />
                                </Form.Item>
                                <span className="input-lang ">azn</span>
                            </div>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div>
                            <p className={"mb-5"}>Müddət</p>
                            <div className="form-lang">
                                <Form.Item
                                    validateTrigger="onChange"
                                    name={"max_loan_period"}
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                    <InputNumber className="w-100" />
                                </Form.Item>
                                <span className="input-lang ">il</span>
                            </div>
                        </div>
                        <div>
                            <p className={"mb-5"}>Güzəşt müddəti</p>
                            <div className="form-lang">
                                <Form.Item
                                    name={"grace_period"}
                                    validateTrigger="onChange"
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                    <InputNumber className="w-100" />
                                </Form.Item>
                                <span className="input-lang ">ay</span>
                            </div>
                        </div>
                    </Col>

                    <Col md={8} sm={12} xs={24}>
                        <p className={"mb-15"}>Ad</p>
                        {langs.map((l, li) => (
                            <div key={l.id} className="form-lang">
                                <Form.Item
                                    validateTrigger="onChange"
                                    className={'mt-15'}
                                    name={`names_${l.key}`}
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                    <Input className="w-100" />
                                </Form.Item>
                                <span className="input-lang ">{l.key}</span>
                            </div>
                        ))}
                    </Col>

                    <Col md={16} sm={12} xs={24}>
                        <p className={"mb-15"}>Qısa xülasə</p>
                        {langs.map((l, li) => (
                            <div key={l.id} className="form-lang">
                                <Form.Item
                                    className={'mt-15'}
                                    validateTrigger="onChange"
                                    name={`descriptions_${l.key}`}
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                    <Input className="w-100" />
                                </Form.Item>
                                <span className="input-lang ">{l.key}</span>
                            </div>
                        ))}
                    </Col>
                </Row>
                <div
                    className="modalButtons"
                    style={{ position: "absolute", bottom: "10px", right: "22px" }}
                >
                    <Button onClick={() => setVisibleEditCredit(false)}>
                        {t("cancel")}
                    </Button>
                    <Button type="primary" className="ml-10" htmlType="submit">
                        {t("save")}
                    </Button>
                </div>
            </div>
        </Form>
    );
}

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, {  notify , getLangs})(EditCredit);

