import React, { useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Spin,
    Form,
    Select,
    Tooltip,
    Input,
    Popconfirm, Switch,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
import { notify, getLangs } from "../../../../redux/actions";
import { connect } from "react-redux";
import admin from "../../../../const/api";
import { useTranslation } from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../utils/rules";
import JoditEditor from "jodit-react";

const {Option} = Select;


const ProductDocuments = (props) => {
    const [form] = Form.useForm();
    const editor = useRef()
    const { t } = useTranslation();
    const [positions, setPositions] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(undefined);
    const [isActive, setActive] = useState(false);
    const [spin, setSpin] = useState(false);
    const [productList, setProductList] = useState([]);
    const [editing, setEditing] = useState(null);
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "name", value: t("name"), con: true },
        { key: "is_active", value: "Status", con: false },
        { key: "id", value: "", con: false },
    ];

    // props
    const { notify, getLangs } = props;

    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title: t("name"),
            key: "2",
            dataIndex: "name",
            render: (i) => {
                return <div dangerouslySetInnerHTML={{__html: i}}/>
            },
        },
        {
            title: "Status",
            dataIndex: "is_active",
            key: "6",
            render: (i) => {
                return i === 1 ? (
                    <span className="green">Aktiv</span>
                ) : (
                    <span className="red">Deaktiv</span>
                );
            },
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePosition(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => setEditingObject(i)}
                            >
                                <EditFilled />
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];


    const getProductList = async () => {
        setSpin(true);
        await admin.get(`products`).then((res) => {
            res.data.content && setSpin(false);
            setProductList(
                res.data.content.map((d, index) => {
                    return {
                        ...d,
                        key: index + 1,
                        index,
                        name: d.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).name,
                    };
                })
            );
        });
    }

    const setEditingObject = async (i) => {
        setEditing(i);
        await admin.get(`products/documents/${selectedProduct}`).then((res) => {
            let names = positions.find((c) => {
                return c.id === parseInt(i);
            });
            let obj = {};
            names.locales.forEach((name) => {
                obj[`name_${name.local}`] = name.name;
            });
            setActive(names.is_active === 1 ? true : false);
            obj['product_id'] = names.product_id
            form.setFieldsValue(obj);
        });
    };

    const cancelEditing = () => {
        setEditing(null);
        setActive(false);
        form.resetFields();
    };

    const deletePosition = async (i) => {
        await admin
            .delete(`/products/documents/${i}`)
            .then(() => {
                // description
                notify("silindi", true);
                selectedProduct &&  getPositions(selectedProduct);
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        let obj = {
            is_active: isActive ? 1 : 0,
            product_id: values.product_id,
            locales: props.langs.map((l, index) => {
                return {
                    local: l.key,
                    name: values[`name_${l.key}`] ,
                    note:'n',
                };
            }),
        };
        if (!editing) {
            await admin
                .post("products/documents", obj)
                .then((res) => {
                    notify("", true);
                   selectedProduct &&  getPositions(selectedProduct);
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await admin
                .put(`products/documents/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    selectedProduct &&  getPositions(selectedProduct);
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async (id) => {
        console.log(selectedProduct)
        setSpin(true);
        await admin.get(`products/documents/${id}`).then((res) => {
            setSpin(false);
            setPositions(
                res.data.content.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                        name: p.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).name,
                    };
                })
            );
        });
    };

    useEffect(() => {
        getProductList()
        getLangs();
    }, [t]);

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">Tələb olunan sənədlər</span>
                </div>
            </Col>
            <Col lg={12} xs={24}>
                <div className="w-100 p-1 mb-15 bg-white">
                    <Select
                        showSearch
                        placeholder={'Məhsul'}
                        className={'w-100'}
                        onChange={(e)=>{setSelectedProduct(e); getPositions(e)}}
                        notFoundContent={null}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {
                            productList.map((w, i) => {
                                return (
                                    <Option key={i} value={w.id}>
                                        {w.name}
                                    </Option>
                                );
                            })
                        }
                    </Select>
                </div>
                <Table
                    loading={spin}
                    size="small"
                    className="bg-white animated fadeInLeft"
                    columns={columns}
                    dataSource={convertColumns(positions, cols)}
                    pagination={{
                        pageSize: 10,
                        current_page: 1,
                        total: positions.length,
                    }}
                />
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("addTo")} className={"animated fadeInRight"}>
                    <Form layout="vertical" onFinish={savePosition} form={form}>
                        <p className="mb-5">Məhsul</p>
                        <Form.Item
                            className="mb-5"
                            validateTrigger="onChange"
                            name={`product_id`}
                            rules={[noWhitespace(t("inputError"))]}
                        >
                            <Select
                                showSearch
                                notFoundContent={null}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {
                                    productList.map((w, i) => {
                                        return (
                                            <Option key={i} value={w.id}>
                                                {w.name}
                                            </Option>
                                        );
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <div className="flex mt-15 mr-15 mb-15">
                            <Switch
                                className={"mr-15"}
                                checked={isActive}
                                onChange={() => {
                                    setActive(!isActive);
                                }}
                            />
                            <span className="mr-15">Status:</span>
                            <span className={isActive ? "green" : "red"}>
                          {isActive ? "Aktiv" : "Deaktiv"}
                        </span>
                        </div>
                        <p className="mb-5">Adı</p>
                        {props.langs ? (
                            <div>
                                {props.langs.map((l, i) => {
                                    return (
                                        <div key={l.id} className="form-lang">
                                            <Form.Item
                                                name={`name_${l.key}`}
                                                validateTrigger="onChange"
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <p>{l.name}</p>
                                                <JoditEditor
                                                    className={'mb-15'}
                                                    onChange={(newContent) => {form.setFieldsValue({
                                                        [`name_${l.key}`] : newContent
                                                    })}}
                                                    ref={editor}
                                                    value={form.getFieldsValue()[`name_${l.key}`]}
                                                    tabIndex={1} // tabIndex of textarea
                                                />
                                            </Form.Item>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <Spin size={"large"} />
                        )}

                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify, getLangs })(ProductDocuments);



