import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Table,
  Button,
  Tooltip,
  Spin,
  Popconfirm,
  Modal,
} from "antd";
import {
  PicCenterOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  EyeFilled,
  DeleteFilled,
  EditFilled,
} from "@ant-design/icons";
import moment from "moment";
import admin from "../../../../const/api";
import { notify } from "../../../../redux/actions";
import { useTranslation } from "react-i18next";
import { convertColumns , createColumns} from "../../../../utils/columnconverter";
import { connect } from "react-redux";
import View from "./Modals/View";
import { Link } from "react-router-dom";
import Credit from "./Credit/Credit";

function Products(props) {
  const { id } = props.match.params;
  const [postlist, setPostList] = useState();
  const [spin, setSpin] = useState(false);
  const { t } = useTranslation();
  const [selectedData, setSelectedData] = useState({});
  const [visibleView, setVisibleView] = useState(false);
  let [trigger, setTrigger] = useState(0);

  const cols = [
    { key: "title", value: "Başlıq", con: false },
    { key: "desc", value: "Qısa xülasə", con: false },
    // { key: "createDate", value: "Yaradılma tarixi", con: false },
    { key: "status", value: "Status", con: false },
    { key: "id", value: "", con: false },
  ];

  const initialColumns = [
    {
      title: t("name"),
      dataIndex: "title",
      key: "2",
    },
    {
      title: "Qısa xülasə",
      dataIndex: "desc",
      key: "3",
    },
    // {
    //   title: t("createDate"),
    //   dataIndex: "createDate",
    //   key: "4",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "6",
      render: (i) => {
        return i === 1 ? (
          <span className="green">Aktiv</span>
        ) : (
          <span className="red">Deaktiv</span>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "9",
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Tooltip className="ml-5" title={t("edit")} placement="topRight">
              <Link
                to={{
                  pathname: `/products/edit/${i}`,
                  // state: { locales: postlist.find((p)=>{return p.id == i}).locales , id: id }
                }}
              >
                <Button className="border-none" type="text" shape="circle">
                  <EditFilled />
                </Button>
              </Link>
            </Tooltip>
            <Popconfirm
              placement="topRight"
              title={t("areYouSure")}
              onConfirm={() => deletePost(i)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Tooltip className="ml-5" title={t("delete")}>
                <Button className="border-none" type="text" shape="circle">
                  <DeleteFilled />
                </Button>
              </Tooltip>
            </Popconfirm>
            <Tooltip
              className="ml-5"
              title={t("detailed")}
              placement="topRight"
            >
              <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => viewMessage(i)}
              >
                <EyeFilled />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const { notify } = props;

  const triggerFetch = () => {
    setTrigger(++trigger);
  };

  const viewMessage = async (i) => {
    await setSelectedData(postlist.find((e) => e.id === parseInt(i)));
    setVisibleView(true);
  };

  const deletePost = async (i) => {
    if (i === 0 || i) {
      await admin
        .delete(`products/${i}`)
        .then((res) => {
          setTrigger(++trigger);
          notify("silindi", true);
        })
        .catch((res) => {
          notify(res.err, false);
        });
    }
  };

  const getPostList = () => {
    setSpin(true);
    admin.get(`products`).then((res) => {
      res.data.content && setSpin(false);
      setPostList(
        res.data.content.map((d, index) => {
          return {
            ...d,
            key: index + 1,
            index,
            tableIndex: index + 1,
            catId: d.category_id,
            title: d.locales.find((l) => {
              return l.local === localStorage.getItem("locale");
            }).name,
            desc: d.locales.find((l) => {
              return l.local === localStorage.getItem("locale");
            }).description,
            createDate: moment(d.created_at).format("DD-MM-YYYY hh:mm"),
          };
        })
      );
    });
  };

  const getExpandIcon = (p) => {
    return p.expanded ? (
        <MinusCircleOutlined
            className="expandingIcon"
            onClick={(e) => p.onExpand(p.record, e)}
        />
    ) : (
        <PlusCircleOutlined
            className="expandingIcon"
            onClick={(e) => p.onExpand(p.record, e)}
        />
    );
  };

  useEffect(() => {
    getPostList();
  }, [t, trigger, id]);

  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <PicCenterOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Fond Məhsulları</span>
            </div>
            <div>
              <Link
                to={{
                  pathname: `/products/edit`,
                  state: { locales: null, id: id },
                }}
              >
                <Button type={"primary"}>Əlavə et</Button>
              </Link>
            </div>
          </div>
        </Col>

        <>
          {spin ? (
            <Col xs={24}>
              <div className="flex animated fadeInUp bg-white all-center p-2">
                <Spin size={"large"} />
              </div>
            </Col>
          ) : (
            <Col xs={24}>
              <Table
                  size="small"
                  className="bg-white animated fadeInUp areasTable"
                  expandedRowRender={(record, i) => (
                      <Credit
                          productId={record.id}
                          triggerFetch={triggerFetch}
                          data={record.properties}
                      />
                  )}
                  expandIcon={(props) => getExpandIcon(props)}
                  columns={createColumns(cols, initialColumns)}
                  dataSource={convertColumns(postlist, cols)}
                  pagination={{
                    pageSize: 10,
                    current_page: 1,
                  }}
              />

            </Col>
          )}
        </>
      </Row>

      <Modal
        title={t("detailedInfo")}
        centered
        className={"padModal mediumModal"}
        visible={visibleView}
        onOk={() => setVisibleView(false)}
        onCancel={() => setVisibleView(false)}
        footer={[]}
      >
        <View setTrigger={setTrigger} trigger={trigger} setVisibleView={setVisibleView} selectedData={selectedData} />
      </Modal>
    </div>
  );
}

export default connect(null, { notify })(Products);
