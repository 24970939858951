export const common = {
    menu: {
        menu:'Menyu',
        close:'Bağla',
        homePage:'ANA SƏHİFƏ',
        about:'HAQQIMIZDA',
        history:'FONDUN TARİXİ',
        fundStructure:'FONDUN STRUKTURU',
        directory:'RƏHBƏRLİK',
        reports:'HESABATLAR',
        internationalPartners: 'BEYNƏLXALQ ƏMƏKDAŞLIQ',
        products:'MƏHSULLARIMIZ',
        creditOrganization:'MÜVƏKKİL KREDİT TƏŞKİLATLARI',
        roadMap:'SAHİBKARIN YOL XƏRİTƏSİ',
        legistation: 'QANUNVERİCİLİK',
        news:'Xəbərlər və elanlar',
        gallery:'Qalereya',
        contact:'ƏLAQƏ',
        beInvestor:'NÖVBƏTİ SAHİBKAR SƏN OL!',
        employer:'SAHİBKARA',
        projectTotalCost:'LAYİHƏLƏRİN ÜMUMİ DƏYƏRİ',
        givenCredit:'DÖVLƏTİN GÜZƏŞTLİ KREDİTİ VERİLMİŞDİR',
    },
    home: {
        credit: "Kredit",
        electronCreditPlatform: 'Elektron Kredit platforması',
        videoInstructions: 'Video təlimatlar',
        customers: 'Müştərilər',
        questions: 'Ən çox verilən suallar',
    },
    aboutTabs: {
        aboutFundTab: "FONDUN TARİXİ",
        fundStructureTab: "FONDUN STRUKTURU",
        leadershipTab: "RƏHBƏRLİK",
        reportsTabs: "HESABATLAR",
    },
    aboutFund: {
        title: 'Fondun tarixi',
        video: 'VIDEO',
    },
    fundStructure: {
        title: 'Fondun Strukturu',
    },
    leadership: {
        title: 'Rəhbərlik',
    },
    reports: {
        title: 'Hesabatlar'
    },
    products: {
        title: 'Məhsullarımız'
    },
    creditContentTabs: {
        creditClassificationTab: "KREDİT TƏSNİFATI",
        priorityTab: "PRİORİRET İSTİQAMƏTLƏR",
        documentsTab: "TƏLƏB OLUNAN SƏNƏDLƏR",
        mechanismTab: "MEXANİZM ŞƏRHİ",
        branchesTab: "FİLİAL ŞƏBƏKƏSİ",
        creditCalculatorTab: "KREDİT KALKULYATORU",
        legislationTab: "SAHİBKARIN YOL XƏRİTƏSİ",
    },
    creditClassification: {
        creditAmount: 'Məbləğ',
        creditDuration: 'Müddət',
        loanRatio: 'Zəmanətin kreditə nisbəti',
        creditPercent: '% -dək',
    },
    priority: {
        priorityText: 'Hər maaliyə ili üzrə Müşahidə Şurası tərəfindən prioritet istiqamətlər müəyyən olunur (Regionlar üzrə)',
        priorityTitle: 'Prioritet istiqamətlər',
    },
    documents: {
        documentsTitle: 'Tələb olunan sənədlər',
        documentsNote: 'QEYD',
    },
    mechanism: {
        mechanismTitle: 'Mexanizm şərhi',
    },
    branches: {
        branchesTitle: 'Filial şəbəkəsi',
        limitAvailable: 'Limit mövcuddur',
        noLimit: 'Limit yoxdur',
    },
    calculator: {
        calculatorTitle: 'Kredit kalkulyatoru',
        creditDate: 'Güzəştli kreditin verilmə tarixi',
        year: 'İl',
        creditYear: '2019',
        month: 'Ay',
        creditMonth: 'Yanvar',
        creditAmountTitle: 'Güzəştli kreditin məbləği',
        creditAmount: '13 000 000',
        creditDurationTitle: 'Güzəştli kreditin müddəti',
        creditDurationYear: '2020',
        creditDurationMonth: 'Mart',
        creditDurationLastYear: '2020',
        creditDurationLastMonth: 'Mart',
        creditPercent: 'Güzəştli kredit faizi',
        creditPercentNumber: '5',
        creditCalculate: 'hesabla',
    },
    creditOrganization: {
        creditOrganizationTitle: 'Müvəkkil Kredit Təşkilatları',
        creditOrganizationAnswer: 'Bizimlə işləmək istəyirsiniz?',
        creditOrganizationInformation: 'Daha çox məlumat',
    },
    regions: {
        regionsTitle: 'İnzibati rayon və şəhərlər üzrə',
        regionsBack: 'Geri',
        regionsPhotos: 'Layihə şəkilləri',
        regionsProjects: 'Layihələr',
    },
    instructions: {
        instructionsTitle: 'Video təlimatlar',
    },
    contact:{
        search:'Axtarış',
        title: 'ƏLAQƏ',
        contactUs:'Əlaqə saxla',
        address:'Ünvan',
        tel:'Tel',
        fax:'Faks',
        mail:'Email',
        service:'195-2 TELEFON XİDMƏTİ',
        serviceText:'Sizi maraqlandıran suallarla 195-2 telefon xidmətinə zəng edin!',
        videoApply:'Video müraciət',
        successMessage:'Mesajınız müvəffəqiyətlə göndərildi',
        inputError:'Xana doldurulmalıdır',
        send:'GÖNDƏR',
        name:'Ad, soyad, ata adı',
        message:'Mesajınız',
    },
    gallery:{
        noPhotoError:'Bu kateqoriya üçün şəkil mövcud deyildir.',
        gallery:'Qalereya',
        galleryText:'Uğurumuz Müştərilərimizin Uğuruna aiddir, aşağıya nəzər yetirin',
    },
    posts:{
        dateOf:'Tarix',
        postError:'Error',
        searchError:'Axtarışa uyğun nəticə mövcud deyil',
        searchResults:'Axtarış nəticələri',
    },
    legistation: {
        question:'Sizə necə kömək edə bilərik?',
        legistationText:'Bu bölmə yeni başlayacaq və mövcud fəaliyyətini genişləndirmək istəyən sahibkarlara maarifləndirici, məsləhətverici dəstək məqsədilə yaradılmışdır. Burada sahibkarlar üçün nəzərdə tutulmuş imtiyazlar, güzəştlər haqda məlumat öz əksini tapmışdır.',
        constitution:'Konstitusiya',
        laws:'Qanunlar',
        orders:'Fərman və sərəncamlar',
        decisions:'Nazirlər kabinetinin qərarları',
        internalRules: 'Daxili qaydalar',
    },
    videoApply:{
        title:'Videoqəbula yazılmaq üçün müraciət forması',
        workingTime: 'İş qrafiki',
        acceptTerms: 'Qaydalarla tanış oldum və qəbul edirəm',
        name:'Ad, Ata adı, Soyad *',
        email:'E-poçt *',
        address:'Ünvanınızı qeyd edin!',
        passportNumber:'Şəxsiyyət vəsiqənizin nömrəsi * ',
        phone:'Telefon nömrənizi qeyd edin!',
        firstRequest:'Bu sizin ilk müraciətinizdi? *',
        yes:'Bəli',
        no:'Xeyr',
        success:'Müraciətiniz müvəffəqiyətlə göndərildi',
        requestPurpose:'Qəbula yazılmağın məqsədi *',
        requestedPerson:'Kimin qəbuluna yazılmaq istəyirsiniz? *',
        date: 'Qəbulun istədiyiniz tarixi (qarşı tərəflə razılaşdırmaqla) nümunə "2021-07-05 13:00"',
        send:'Göndər',
        addCaptcha:'Captcha - daxil edin',
        errorCaptcha:'Captcha düzgün daxil edilməlidir.',
    },
    site: {
        EDF:"Sahibkarlığın inkişafı fondu",
    },
    map: {
        baku: 'Bakı',
        absheron: 'Abşeron',
        agchabedi: 'Ağcabədi',
        xochavend: 'Xocavənd',
        beyleqan: 'Beyləqan',
        qubadli: 'Qubadlı',
        sumkhayit: 'Sumqayıt',
        lachin: 'Laçın',
        fuzuli: 'Füzuli',
        shusha: 'Şuşa',
        zengilan: 'Zəngilan',
        lerik: 'Lerik',
        astara: 'Astara',
        lenkeran: 'Lənkəran',
        qazakh: 'Qazax',
        aghstafa: 'Ağstafa',
        tovuz: 'Tovuz',
        shamkir: 'Şəmkir',
        gedebey: 'Gədəbəy',
        dashkesen: 'Daşkəsən',
        bilesuvar: 'Biləsuvar',
        djalilabad: 'Cəlilabad',
        yardimli: 'Yardımlı',
        masalli: 'Masallı',
        balaken: 'Balakən',
        zakhatala: 'Zaqatala',
        qakh: 'Qax',
        sheki: 'Şəki',
        oghuz: 'Oğuz',
        khabala: 'Qəbələ',
        goychay: 'Göyçay',
        ismayilli: 'İsmayıllı',
        shabran: 'Şabran',
        siyezen: 'Siyəzən',
        khizi: 'Xızı',
        aghsu: 'Ağsu',
        shamakhi: 'Şamaxı',
        qobustan: 'Qobustan',
        goranboy: 'Goranboy',
        yevlakh: 'Yevlax',
        mingechevir: 'Mingəçevir',
        khojali: 'Xocalı',
        samukh: 'Samux',
        gandja: 'Gəncə',
        goygol: 'Göygöl',
        kalbadjar: 'Kəlbəcər',
        jabrayil: 'Cəbrayıl',
        imishli: 'İmişli',
        saatli: 'Saatlı',
        sabirabad: 'Sabirabad',
        hacikabul: 'Hacıqabul',
        shirvan: 'Şirvan',
        salyan: 'Salyan',
        neftchala: 'Neftçala',
        kusar: 'Qusar',
        xachmaz: 'Xaçmaz',
        khuba: 'Quba',
        agdash: 'Ağdaş',
        zardab: 'Zərdab',
        udjar: 'Ucar',
        kurdamir: 'Kürdəmir',
        naftalan: 'Naftalan',
        barda: 'Bərdə',
        tartar: 'Tərtər',
        aghdam: 'Ağdam',
        khankandi: 'Xankəndi',
        sadarak: 'Sədərək',
        sharur: 'Şərur',
        kangarli: 'Kəngərli',
        babak: 'Babək',
        nakhchivan: 'Naxçıvan',
        djulfa: 'Culfa',
        shahbuz: 'Şahbuz',
        ordubad: 'Ordubad',

    }
}


// Object.key(mainObj).map( item => {
//     label>Item.t(map) </
//     Object.entries(mainObj[item]).map( mapped => {
//         label -> mapped->key
//         input -> mappe-> value
//     })
// })
//
// az.json {
//     map: 'Xerite'
// }