import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Col, Form, Input, Popconfirm, Row, Select, Spin, Switch, Table, Tooltip, Upload,} from "antd";
import {DeleteFilled, EditFilled,} from "@ant-design/icons";
import {convertColumns} from "../../../../utils/columnconverter";
// notification
import {getLangs, notify} from "../../../../redux/actions";
import {connect} from "react-redux";
import admin from "../../../../const/api";
import {useTranslation} from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../utils/rules";

const {Option} = Select;
const CreateReport = (props) => {
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const [positions, setPositions] = useState([]);
    const [reportList, setReportList] = useState([]);
    const [isActive, setActive] = useState(false);
    const [file, setFile] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [spin, setSpin] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);
    const [editing, setEditing] = useState(null);
    const cols = [
        {key: "index", value: "#", con: true},
        {key: "name", value: t("name"), con: true},
        {key: "id", value: "", con: false},
    ];
    const nameInput = useRef();

    // props
    const {notify, getLangs} = props;

    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title: t("name"),
            key: "2",
            dataIndex: "name",
        },
        {
            title: "Status",
            dataIndex: "is_active",
            key: "6",
            render: (i) => {
                return i === 1 ? (
                    <span className="green">Aktiv</span>
                ) : (
                    <span className="red">Deaktiv</span>
                );
            },
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePosition(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled/>
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => setEditingObject(i)}
                            >
                                <EditFilled/>
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];


    const setUploadFile = ({onSuccess, onError, file}) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("file", file, file.name);
        admin
            .post("file/upload/single", form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                setFile(res.data.content.file_uuid);
                onSuccess(null, file);
            })
            .catch((err) => onError());
    };


    const setEditingObject = async (i) => {
        setEditing(i);
        await admin.get(`/investor/roadmap/properties/${selectedId}`).then((res) => {
            let names = res.data.content.find((c) => {
                return c.id === i;
            });
            let obj = {};
            names.locales.forEach((name) => {
                obj[`name_${name.local}`] = name.name;
            });
            let arr = [];
            setFile(
               names.file_id
            );
            arr.push({ uid:  names.file_id  , url:  names.file.path });
            setFileList(arr);
            obj['investor_road_map_id'] =  names.investor_road_map_id
            setActive(names.is_active)
            form.setFieldsValue(obj);
        });
    };

    const onChange = ({fileList: newFileList}) => {
        setFileList(newFileList);
        if (newFileList.length <= 0) {
            setFile(null);
        }
    };

    const cancelEditing = () => {
        setEditing(null);
        setActive(false);
        form.resetFields();
        setFile(undefined)
        setFileList(undefined)
    };


    const deletePosition = async (i) => {
        await admin
            .delete(`/investor/roadmap/properties/${i}`)
            .then(() => {
                // description
                notify("silindi", true);
                getPositions();
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        let obj = {
            is_active:isActive,
            investor_road_map_id:values.investor_road_map_id,
            file_id:file,
            locales: props.langs.map((l, index) => {
                return {
                    local: l.key,
                    name: values[`name_${l.key}`],
                    description: 'n',
                };
            }),
            link: values.link
        };
        if (!editing) {
            await admin
                .post("/investor/roadmap/properties", obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await admin
                .put(`/investor/roadmap/properties/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async () => {
        setSpin(true);
        await admin.get("investor/roadmap").then((res) => {
            setSpin(false);
            setReportList(
                res.data.content.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                        name: p.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).name,
                    };
                })
            )
            res.data?.content[0]?.id && getMainData(res.data.content[0].id)
        });
    };

    const getMainData = (id) => {
        setSelectedId(id)
        admin.get(`investor/roadmap/properties/${id}`).then((res) => {
            setSpin(false);
            setPositions(
                res.data.content.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                        name: p.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).name,
                    };
                })
            );
        });
    }

    useEffect(() => {
        getPositions();
        getLangs();
    }, [t, props.tabKey]);

    return (
        <Row gutter={[10, 10]}>
            <Col lg={12} xs={24}>
                <Card loading={spin} className="w-100 mb-10">
                    <Select
                        className={'w-100'}
                        showSearch
                        defaultValue={selectedId}
                        onChange={(e) => {
                            getMainData(e)
                        }}
                        notFoundContent={null}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {
                            reportList.map((r) => (
                                <Option value={r.id}>
                                    {r.name}
                                </Option>
                            ))
                        }

                    </Select>
                    <Table
                        loading={spin}
                        size="small"
                        className="bg-white animated fadeInLeft"
                        columns={columns}
                        dataSource={convertColumns(positions, cols)}
                        pagination={{
                            pageSize: 10,
                            current_page: 1,
                            total: positions.length,
                        }}
                    />
                </Card>
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("addTo")} className={"animated fadeInRight"}>
                    <Form layout="vertical" onFinish={savePosition} form={form}>
                        <p className="mb-5">Adı</p>
                        {props.langs ? (
                            <div>
                                {props.langs.map((l, i) => {
                                    return (
                                        <div key={l.id} className="form-lang">
                                            <Form.Item
                                                className="mb-5"
                                                validateTrigger="onChange"
                                                name={`name_${l.key}`}
                                                rules={[whiteSpace(t("inputError"))]}
                                            >
                                                <Input ref={nameInput}/>
                                            </Form.Item>
                                            <div className="input-lang">{l.key}</div>
                                        </div>
                                    );
                                })}
                                <p className="mb-5">Növü</p>
                                <Form.Item
                                    className="mb-5"
                                    validateTrigger="onChange"
                                    name={`investor_road_map_id`}
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                    <Select
                                        className={'w-100'}
                                        showSearch
                                        notFoundContent={null}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {
                                            reportList.map((r) => (
                                                <Option value={r.id}>
                                                    {r.name}
                                                </Option>
                                            ))
                                        }

                                    </Select>
                                </Form.Item>

                               <div className="border p-2">
                                {
                                    fileList?.length > 0 && 'Fayl'
                                }
                                <Upload
                                    fileList={fileList}
                                    onChange={onChange}
                                    customRequest={setUploadFile}
                                    beforeUpload={null}
                                    className={'w-100'}
                                >
                                    {!fileList?.length &&
                                    <Button className={'w-100'} type={'primary'}>Fayl yüklə (pdf doc docx
                                        xlsx)</Button>}
                                </Upload>
                              </div>


                                <div className="flex mt-15 mb-5">
                                    <Switch
                                        className={"mr-15"}
                                        checked={isActive}
                                        onChange={() => {
                                            setActive(!isActive);
                                        }}
                                    />
                                    <span className="mr-15">Status:</span>
                                    <span className={isActive ? "green" : "red"}>{isActive ? "Aktiv" : "Deaktiv"}</span>
                                </div>

                            </div>
                        ) : (
                            <Spin size={"large"}/>
                        )}

                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({langs}) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, {notify, getLangs})(CreateReport);




