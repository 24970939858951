import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Modal, Row, Select, Switch, Upload} from "antd";
import ImgCrop from "antd-img-crop";
import admin from "../../../../const/api";
import {noWhitespace} from "../../../../utils/rules";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getLangs, notify} from "../../../../redux/actions";

const {Option} = Select
const Add = (props) => {
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const [isActive, setActive] = useState(false)
    const {
        langs,
        productList,
        editing,
        notify,
        modalSetting,
        getLangs
    } = props;


    useEffect(() => {
            getLangs()
            form.resetFields();
            if (editing) {
                let objs = {
                    product_id:editing.product_id,
                }
                editing.locales.forEach((d) => {
                    objs[`tab_name_${d.local}`] = d.tab_name;
                    objs[`description_${d.local}`] = d.description;
                });
                setActive(editing.is_active === 1 ? true : false);
                form.setFieldsValue(objs);
            }
        }
        , [editing])


    const saveCredit = (values) => {
        let obj = {
            is_active: isActive ? 1 : 0,
            product_id:values.product_id,
            locales: props.langs.map((l) => {
                return {
                    local: l.key,
                    tab_name: values[`tab_name_${l.key}`],
                    description: values[`description_${l.key}`] ? values[`description_${l.key}`] : undefined,
                };
            }),
        };
        if (editing) {
            admin
                .put(`products/classifications/${editing.id}`, obj)
                .then((res) => {
                    notify("", true);
                    modalSetting(false, true)
                    form.resetFields();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            admin
                .post(`products/classifications`, obj)
                .then((res) => {
                    notify("", true);
                    modalSetting(false, true)
                    form.resetFields();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    }

    return (
        <>
            <div className="p-2">
                <Form form={form} layout="vertical" onFinish={saveCredit}>
                    <Row gutter={[8, 8]}>
                        <Col md={8} sm={12} xs={24}>
                            <p className={"mb-5"}>Ad</p>
                            {langs.map((l) => (
                                <div key={l.id} className="form-lang">
                                    <Form.Item
                                        validateTrigger="onChange"
                                        name={`tab_name_${l.key}`}
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Input className="w-100"/>
                                    </Form.Item>
                                    <span className="input-lang ">{l.key}</span>
                                </div>
                            ))}
                        </Col>
                        <Col md={16} sm={12} xs={24}>
                            <p className={"mb-5"}>Qeyd</p>
                            {langs.map((l) => (
                                <div key={l.id} className="form-lang">
                                    <Form.Item
                                        validateTrigger="onChange"
                                        name={`description_${l.key}`}
                                    >
                                        <Input className="w-100"/>
                                    </Form.Item>
                                    <span className="input-lang ">{l.key}</span>
                                </div>
                            ))}
                        </Col>
                        <Col md={20} sm={12} xs={24}>
                            <Form.Item
                                className="mb-5"
                                validateTrigger="onChange"
                                name={`product_id`}
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Select
                                    className={'w-100'}
                                    showSearch
                                    placeholder={'Məhsul'}
                                    notFoundContent={null}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    {
                                        productList.map((r) => (
                                            <Option value={r.id}>
                                                {r.title}
                                            </Option>
                                        ))
                                    }

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={4} sm={12} xs={24}>
                            <div className="flex mt-5">
                                <Switch
                                    className={"mr-15"}
                                    checked={isActive}
                                    onChange={() => {
                                        setActive(!isActive)
                                    }}
                                />
                                <span className="mr-15">Status:</span>
                                <span className={isActive ? "green" : "red"}>
                                     {isActive ? "Aktiv" : "Deaktiv"}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <div
                        className="modalButtons"
                        style={{position: "absolute", bottom: "10px", right: "22px"}}
                    >
                        <Button onClick={() => modalSetting(false, false)}>
                            {t("cancel")}
                        </Button>
                        <Button type="primary" className="ml-10" htmlType="submit">
                            {t("save")}
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );
};


const mapStateToProps = ({langs}) => {
    return {
        langs
    };
};


export default connect(mapStateToProps, {notify, getLangs})(Add);
