import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Switch,
  Spin,
  Form,
  Tooltip,
  Input,
  Popconfirm,
} from "antd";
import {
  UnorderedListOutlined,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
import { notify, getLangs } from "../../../../redux/actions";
import { connect } from "react-redux";
import admin from "../../../../const/api";
import { useTranslation } from "react-i18next";
import { whiteSpace } from "../../../../utils/rules";

const Categories = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [positions, setPositions] = useState([]);
  const [isActive, setActive] = useState(false);
  const [spin, setSpin] = useState(false);
  const [editing, setEditing] = useState(null);
  const cols = [
    { key: "index", value: "#", con: true },
    { key: "name", value: t("name"), con: true },
    { key: "status", value: "Status", con: false },
    { key: "id", value: "", con: false },
  ];
  const nameInput = useRef();

  // props
  const { notify, getLangs } = props;

  const columns = [
    {
      title: "#",
      key: "1",
      dataIndex: "index",
      width: 80,
    },
    {
      title: t("name"),
      key: "2",
      dataIndex: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "6",
      render: (i) => {
        return i === 1 ? (
            <span className="green">Aktiv</span>
        ) : (
            <span className="red">Deaktiv</span>
        );
      },
    },
    {
      title: "",
      key: "3",
      dataIndex: "id",
      width: 30,
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Popconfirm
              placement="topRight"
              title={t("areYouSure")}
              onConfirm={() => deletePosition(i)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Tooltip className="ml-5" title={t("delete")}>
                <Button className="border-none" type="text" shape="circle">
                  <DeleteFilled />
                </Button>
              </Tooltip>
            </Popconfirm>
            <Tooltip className="ml-5" title={t("edit")} placement="topRight">
              <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => setEditingObject(i)}
              >
                <EditFilled />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const setEditingObject = async (i) => {
    setEditing(i);
    await admin.get(`categories`).then((res) => {
      let names = res.data.content.find((c) => {
        return c.id === i;
      });
      let obj = {};
      names.locales.forEach((name) => {
        obj[`name_${name.local}`] = name.name;
      });
      setActive(names.status === 1 ? true : false);
      form.setFieldsValue(obj);
    });
  };

  const cancelEditing = () => {
    setEditing(null);
    setActive(false);
    form.resetFields();
  };

  const deletePosition = async (i) => {
    await admin
      .delete(`/categories/${i}`)
      .then(() => {
        // description
        notify("silindi", true);
        getPositions();
      })
      .catch((err) => {
        //error
        notify(err.response, false);
      });
  };

  const savePosition = async (values) => {
    let obj = {
      status: isActive ? 1 : 0,
      locales: props.langs.map((l, index) => {
        return { local: l.key, name: values[`name_${l.key}`] };
      }),
    };
    if (!editing) {
      await admin
        .post("/categories", obj)
        .then((res) => {
          notify("", true);
          getPositions();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    } else {
      obj["id"] = editing;
      await admin
        .put(`/categories/${editing}`, obj)
        .then((res) => {
          notify("", true);
          getPositions();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    }
  };

  const getPositions = async () => {
    setSpin(true);
    await admin.get("categories").then((res) => {
      setSpin(false);
      setPositions(
        res.data.content.map((p, index) => {
          return {
            key: index + 1,
            ...p,
            index: index + 1,
            name: p.locales.find((l) => {
              return l.local === localStorage.getItem("locale");
            }).name,
          };
        })
      );
    });
  };

  useEffect(() => {
    getPositions();
    getLangs();
  }, [t]);

  const onSwitchChange = () => {
    setActive(!isActive);
  };

  return (
    <Row gutter={[10, 10]}>
      <Col xs={24}>
        <div className="border animated fadeInDown p-2 mt-0 bg-white">
          <UnorderedListOutlined className="f-20 mr5-15" />
          <span className="f-20 bold">Kateqoriyalar</span>
        </div>
      </Col>
      <Col lg={12} xs={24}>
        <Table
          loading={spin}
          size="small"
          className="bg-white animated fadeInLeft"
          columns={columns}
          dataSource={convertColumns(positions, cols)}
          pagination={{
            pageSize: 10,
            current_page: 1,
            total: positions.length,
          }}
        />
      </Col>
      <Col lg={12} xs={24}>
        <Card title={t("addTo")} className={"animated fadeInRight"}>
          <Form layout="vertical" onFinish={savePosition} form={form}>
            <p className="mb-5">Kateqoriyanın adı</p>
            {props.langs ? (
              props.langs.map((l, i) => {
                return (
                  <div key={l.id} className="form-lang">
                    <Form.Item
                      className="mb-5"
                      validateTrigger="onChange"
                      name={`name_${l.key}`}
                      rules={[whiteSpace(t("inputError"))]}
                    >
                      <Input ref={nameInput} />
                    </Form.Item>
                    <div className="input-lang">{l.key}</div>
                  </div>
                );
              })
            ) : (
              <Spin size={"large"} />
            )}

            <div className="flex mt-10 flex-align-center">
              <Switch checked={isActive} onChange={onSwitchChange} />
              <span className="ml-10 mr-10">Status</span>
              <span className={isActive ? "green" : "red"}>
               {isActive ? "Aktiv" : "Deaktiv"}
              </span>
            </div>

            <div className="flex  flex-between mt-15">
              <Button onClick={cancelEditing}>{t("cancel")}</Button>
              <Button htmlType="submit">{t("save")}</Button>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ langs }) => {
  return {
    langs: langs,
  };
};

export default connect(mapStateToProps, { notify, getLangs })(Categories);
