import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Col, Form, Input, Popconfirm, Row, Select, Spin, Switch, Table, Tooltip, Upload,} from "antd";
import {DeleteFilled, EditFilled,} from "@ant-design/icons";
import {convertColumns} from "../../../../utils/columnconverter";
// notification
import {getLangs, notify} from "../../../../redux/actions";
import {connect} from "react-redux";
import admin from "../../../../const/api";
import {useTranslation} from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../utils/rules";
import JoditEditor from "jodit-react";

const {Option} = Select;
const List = (props) => {
    const {selectedProduct , productList , spin , setSpin , positions  , getMainData} = props
    const [form] = Form.useForm();
    const editor = useRef()
    const {t} = useTranslation();
    const [isActive, setActive] = useState(false);
    const [editing, setEditing] = useState(null);
    const cols = [
        {key: "index", value: "#", con: true},
        {key: "name", value: t("name"), con: true},
        {key: "id", value: "", con: false},
    ];

    // props
    const {notify} = props;

    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title: t("name"),
            key: "2",
            dataIndex: "name",
            render: (i) => {
                return <div className={'line-clamp line-1'} dangerouslySetInnerHTML={{__html: i}}/>
            },
        },
        {
            title: "Status",
            dataIndex: "is_active",
            key: "6",
            render: (i) => {
                return i === 1 ? (
                    <span className="green">Aktiv</span>
                ) : (
                    <span className="red">Deaktiv</span>
                );
            },
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePosition(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled/>
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => setEditingObject(i)}
                            >
                                <EditFilled/>
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];


    const setEditingObject = async (i) => {
        setEditing(i);
        let names = positions.find((c) => {
            return c.id === i;
        });
        let obj = {};
        names.locales.forEach((name) => {
            obj[`name_${name.local}`] = name.name;
            obj[`note_${name.local}`] = name.note;
        });
        obj['product_id'] =  names.product_id
        setActive(names.is_active === 1 ? true : false)
        form.setFieldsValue(obj);
    };


    const cancelEditing = () => {
        setEditing(null);
        setActive(false);
        form.resetFields();
    };


    const deletePosition = async (i) => {
        await admin
            .delete(`products/priorities/${i}`)
            .then(() => {
                // description
                notify("silindi", true);
                getMainData(selectedProduct);
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        let obj = {
            product_id:values.product_id,
            is_active: isActive ? 1 : 0,
            locales: props.langs.map((l, index) => {
                return {
                    local: l.key,
                    name: values[`name_${l.key}`],
                    note: values[`note_${l.key}`],
                    description: 'n',
                };
            }),
        };
        if (!editing) {
            await admin
                .post("products/priorities", obj)
                .then((res) => {
                    notify("", true);
                    form.resetFields();
                    getMainData(selectedProduct);
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await admin
                .put(`products/priorities/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getMainData(selectedProduct);
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };






    return (
        <Row gutter={[10, 10]}>
            <Col lg={12} xs={24}>
                <Card loading={spin} className="w-100 mb-10">
                    <Table
                        loading={spin}
                        size="small"
                        className="bg-white animated fadeInLeft"
                        columns={columns}
                        dataSource={convertColumns(positions, cols)}
                        pagination={{
                            pageSize: 10,
                            current_page: 1,
                            total: positions.length,
                        }}
                    />
                </Card>
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("addTo")} className={"animated fadeInRight"}>
                    <Form layout="vertical" onFinish={savePosition} form={form}>
                        <p className="mb-5">Məhsul</p>
                        <Form.Item
                            className="mb-5"
                            validateTrigger="onChange"
                            name={`product_id`}
                            rules={[noWhitespace(t("inputError"))]}
                        >
                            <Select
                                showSearch
                                notFoundContent={null}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {
                                    productList.map((w, i) => {
                                        return (
                                            <Option key={i} value={w.id}>
                                                {w.name}
                                            </Option>
                                        );
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <div className="flex mt-15 mb-5">
                            <Switch
                                className={"mr-15"}
                                checked={isActive}
                                onChange={() => {
                                    setActive(!isActive);
                                }}
                            />
                            <span className="mr-15">Status:</span>
                            <span className={isActive ? "green" : "red"}>{isActive ? "Aktiv" : "Deaktiv"}</span>
                        </div>

                        <p className="mb-5">Adı</p>
                        {props.langs.length > 0 ? (
                            <div>
                                {props.langs.map((l, i) => {
                                    return (
                                        <div key={l.id} className="form-lang">
                                            <Form.Item
                                                name={`name_${l.key}`}
                                                validateTrigger="onChange"
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <p>{l.name}</p>
                                                <JoditEditor
                                                    className={'mb-15'}
                                                    onChange={(newContent) => {form.setFieldsValue({
                                                        [`name_${l.key}`] : newContent
                                                    })}}
                                                    ref={editor}
                                                    value={form.getFieldsValue()[`name_${l.key}`]}
                                                    tabIndex={1} // tabIndex of textarea
                                                />
                                            </Form.Item>
                                        </div>
                                    );
                                })}



                            </div>
                        ) : (
                            <Spin size={"large"}/>
                        )}

                        <p className="mb-5">Qeyd</p>
                        {props.langs.length > 0 ? (
                            <div>
                                {props.langs.map((l, i) => {
                                    return (
                                        <div key={l.id} className="form-lang">
                                            <Form.Item
                                                name={`note_${l.key}`}
                                                validateTrigger="onChange"
                                            >
                                                <p>{l.name}</p>
                                                <JoditEditor
                                                    className={'mb-15'}
                                                    onChange={(newContent) => {form.setFieldsValue({
                                                        [`note_${l.key}`] : newContent
                                                    })}}
                                                    ref={editor}
                                                    value={form.getFieldsValue()[`note_${l.key}`]}
                                                    tabIndex={1} // tabIndex of textarea
                                                />
                                            </Form.Item>
                                        </div>
                                    );
                                })}



                            </div>
                        ) : (
                            <Spin size={"large"}/>
                        )}

                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({langs}) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, {notify, getLangs})(List);





