import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getLangs, notify} from "../../../../redux/actions";
import {Button, Col, Form, Input, Row, Select, Spin, Table} from "antd";
import {useTranslation} from "react-i18next";
import {PicCenterOutlined} from "@ant-design/icons";
import admin from "../../../../const/api";
import {noWhitespace, whiteSpace} from "../../../../utils/rules";
import {common} from './object'
const {Option} =  Select
function Translations(props) {
    const [spin, setSpin] = useState(false);
    const {notify, getLangs , langs} = props;
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const [selected , setSelected] = useState(undefined);
    const [az , setAz] = useState({})
    const [translations , settranslations] = useState([]);

    useEffect(() => {
        getTranslations()
    }, [t]);

    useEffect(() => {
        langs.length <= 0 && getLangs();
        form.setFieldsValue(az)
    },[az])

    const getTranslations = (local) => {
        form.resetFields()
        setSpin(true)
        admin.get(`languages/localization`).then((res)=>{
            settranslations(res.data.content)
            setSpin(false)
        })
    }

    const save = async (values) => {
        let obj = {
            local: selected,
            is_active: true,
            data: {...values}
        };
        await admin
            .post(`languages/localization`, obj)
            .then((res) => {
                notify("", true);
                change(selected)
            })
            .catch((err) => {
                notify(err.response, false);
            });
    }

    const change = (local) => {
        form.resetFields()
        console.log(translations.find(f=>f.local === local).data)
        setAz(translations.find(f=>f.local === local).data)
        form.setFieldsValue(az)
        setSelected(local)
    }

    return (
        <div>
            <Form layout="vertical" onFinish={save} form={form}>
               <Row gutter={[10, 10]}>
                <Col xs={24}>
                    <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <PicCenterOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Tərcümələr</span>
                        </div>
                        <Button type={'primary'} htmlType="submit">{t("save")}</Button>
                    </div>
                </Col>
                <>
                    {langs.length > 0 &&
                    <Col xs={24} >
                        <div className="bg-white p-2">
                            <Select
                                placeholder="Dil seçin"
                                onChange={(e)=>{change(e)}}
                                className={'w-100'}
                                showSearch
                                notFoundContent={null}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {
                                    langs.map((w, i) => {
                                        return (
                                            <Option key={i} value={w.key}>
                                                {w?.name}
                                            </Option>
                                        );
                                    })
                                }
                            </Select>
                        </div>
                    </Col>
                    }
                    {spin ? (
                        <Col xs={24}>
                            <div className="flex animated fadeInUp bg-white all-center p-2">
                                <Spin size={"large"} />
                            </div>
                        </Col>
                    ) : (
                        <>
                            <Col xs={24}>
                                {selected &&
                                  <div className="p-1 bg-white border">
                                    <div className={'border p-1'}>
                                        {Object.keys(common).map((l , index)=>(
                                            <Row gutter={[16,8]}>
                                                <Col xs={24}>
                                                    <div className="page-name p-1 mt-10 border">
                                                        <PicCenterOutlined className="f-18 mr5-15" />
                                                        <span className="f-18 bold">{t(l)}</span>
                                                    </div>
                                                </Col>
                                                {Object.entries(common[l]).map((o , i) => (
                                                    <Col md={8} sm={12} xs={24} key={i} className=" form-lang">
                                                        <div className="border p-2">
                                                            <Form.Item
                                                                label={o[1]?.toString()}
                                                                className="mb-5"
                                                                validateTrigger="onChange"
                                                                name={[l , o[0]]}
                                                                rules={[noWhitespace(t("inputError"))]}
                                                            >
                                                                <Input placeholder={o[1]} />
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        ))}
                                    </div>
                                    <div className="flex  flex-between mt-15">
                                        {/*<Button onClick={cancelEditing}>{t("cancel")}</Button>*/}
                                        <Button type={'primary'} htmlType="submit">{t("save")}</Button>
                                    </div>
                                </div>
                                }
                            </Col>
                        </>
                    )}
            </>
            </Row>
            </Form>
        </div>
    );
}

const mapStateToProps = ({langs}) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, {notify, getLangs})(Translations);

//
