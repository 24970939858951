import React, {Component, useState} from 'react';
import {connect} from "react-redux";
import {getLangs, notify} from "../../../../redux/actions";
import {withTranslation} from "react-i18next";
import {
    Spin,
    Col,
    Input,
    Modal,
    Form,
    Row,
    Button,
} from "antd";
import {noWhitespace} from "../../../../utils/rules";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import JoditEditor from "jodit-react";

import admin from "../../../../const/api";

class EditTerms extends Component {
    formRef = React.createRef();
    editor = React.createRef();

    constructor(props) {
        super(props);
    }

    state = {
        spin: false,
        about: {},
        previewImage: "",
        previewVisible: false,
        file: null,
        fileList:[]
    };

    getPost = async () => {
        let obj = {};
        let abouts = {}
        await admin.get(`terms`).then((res) => {
            res.data.content && res.data.content.locales.forEach((d) => {
                obj[`description_${d.local}`] = d.description;
                obj[`name_${d.local}`] = d.name;
                abouts[`description_${d.local}`] = d.description;
            });
            let arr = [...this.state.fileList];
            if(res.data.content?.working_date_file_id){
                this.setState({ file:res.data?.content?.working_date_file_id});
                arr.push({ uid:  res.data.content?.working_date_file_id  , url:  res.data.content.working_date_file.path });
                this.setState({fileList:arr})
            }
            this.setState({about:abouts});
            this.setState({spin:false});
            if(this.formRef.current !== null){
                this.formRef.current.setFieldsValue(obj);
            }
        });
    };


    componentDidMount() {
        this.props.getLangs()
        this.getPost()
        this.formRef.current.resetFields()
        this.setState({spin:true});
    }



    saveItem = async (values) => {
        let obj = {
            is_active:true,
            working_date_file_id:this.state.file,
            locales: this.props.langs.map((l) => {
                return {
                    local: l.key,
                    description: values[`description_${l.key}`],
                    name: values[`name_${l.key}`],
                };
            }),
        };
        await admin
            .post(`terms`, obj)
            .then((res) => {
                this.props.notify("", true);
                this.formRef.current.resetFields();
                window.history.back();
            })
            .catch((err) => {
                notify(err.response, false);
            });
    };

    setUploadFile = ({onSuccess, onError, file}) => {
        let form_data = new FormData();
        form_data.append("file", file, file.name);
        admin
            .post("file/upload/single", form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                this.setState({file:res.data.content.file_uuid});
                onSuccess(null, file);
            })
            .catch((err) => onError());
    };

    onChange = ({fileList: newFileList}) => {
        this.setState({fileList:newFileList});
        if (newFileList.length <= 0) {
            this.setState({file:null});
        }
    };


    render() {
        let { t } = this.props;
        return (
            <div>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                            <div className="page-name">
                                <PicCenterOutlined className="f-20 mr5-15" />
                                <span className="f-20 bold">Şərtlər və qaydalar</span>
                            </div>
                            <Link
                                to={{
                                    pathname: `/terms`,
                                    state: { locales: null },
                                }}
                            >
                                <Button type={"primary"}>{t("cancel")}</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={24}>
                        {(this.state.spin && !this.props.langs) ? (
                            <div className="flex animated fadeIn p-2 bg-white all-center">
                                <Spin size={"large"} />
                            </div>
                        ) : (
                            <div className="p-2 animated edit fadeInUp bg-white">
                                <Form ref={this.formRef} onFinish={this.saveItem} layout="vertical">
                                    <Row gutter={[8, 8]}>
                                        <Col xs={24}>
                                            <p className="mb-10">Görüş üçün qrafik</p>
                                            <div className="border p-2">
                                                {
                                                    this.state.fileList?.length > 0 && 'Fayl'
                                                }
                                                <Upload
                                                    fileList={this.state.fileList}
                                                    onChange={this.onChange}
                                                    customRequest={this.setUploadFile}
                                                    beforeUpload={null}
                                                    className={'w-100'}
                                                >
                                                    {this.state.fileList?.length < 1 &&
                                                    <Button className={'w-100'} type={'primary'}>Fayl yüklə (pdf doc docx
                                                        xlsx)</Button>}
                                                </Upload>
                                            </div>
                                        </Col>
                                        <Col md={16} xs={24}>
                                            <div>Ad</div>
                                            {this.props.langs.map((l) => (
                                                <div key={l.id} className="form-lang">
                                                    <div className="">
                                                        {l.name}
                                                    </div>
                                                    <Form.Item
                                                        name={`name_${l.key}`}
                                                        validateTrigger="onChange"
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <Input className="w-100" />
                                                    </Form.Item>

                                                </div>
                                            ))}
                                        </Col>


                                        <Col md={24} sm={24} xs={24}>
                                            {this.props.langs.map((l) => (
                                                <div key={l.id} className="form-lang">
                                                    <div className="mb-10">
                                                        {l.name}
                                                    </div>
                                                    <Form.Item
                                                        name={`description_${l.key}`}
                                                        validateTrigger="onChange"
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <JoditEditor
                                                            ref={this.editor}
                                                            value={this.state.about[`description_${l.key}`]}
                                                            tabIndex={1} // tabIndex of textarea
                                                        />
                                                    </Form.Item>

                                                </div>
                                            ))}
                                        </Col>
                                        <Col xs={24}>
                                            <div className={"flex"}>
                                                <Button className={"mr-15"} htmlType="submit">
                                                    {t("save")}
                                                </Button>
                                                <Link
                                                    to={{
                                                        pathname: `/about`,
                                                        state: { locales: null },
                                                    }}
                                                >
                                                    <Button type={"primary"}>{t("cancel")}</Button>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        )}
                    </Col>
                </Row>

                <Modal
                    visible={this.state.previewVisible}
                    title={false}
                    footer={null}
                    onCancel={() => {
                        this.setState({previewVisible:false})
                    }}
                >
                    <img alt="example" style={{ width: "100%" }} src={this.state.previewImage} />
                </Modal>
            </div>
        );
    }
}


const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

const exp = withTranslation()(EditTerms);
export default connect(mapStateToProps, { notify, getLangs })(exp);

