import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Switch,
  Form,
  Tooltip,
  Input,
  Popconfirm,
} from "antd";
import {
  UnorderedListOutlined,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
// notification
import { notify } from "../../../../redux/actions";
import { connect } from "react-redux";
import admin from "../../../../const/api";
import { useTranslation } from "react-i18next";
import { whiteSpace } from "../../../../utils/rules";

const Languages = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [positions, setPositions] = useState([]);
  const [isActive, setActive] = useState(false);
  const [editing, setEditing] = useState(null);
  const [spin, setSpin] = useState(false);
  const cols = [
    { key: "index", value: "#", con: true },
    { key: "name", value: t("name"), con: true },
    { key: "key", value: "Açar", con: true },
    { key: "id", value: "", con: false },
  ];
  const nameInput = useRef();

  // props
  const { notify, getLangs } = props;

  const columns = [
    {
      title: "#",
      key: "1",
      dataIndex: "index",
      width: 80,
    },
    {
      title: t("name"),
      key: "2",
      dataIndex: "name",
    },
    {
      title: "Açar",
      key: "3",
      dataIndex: "key",
    },
    {
      title: "",
      key: "3",
      dataIndex: "id",
      width: 30,
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Popconfirm
              placement="topRight"
              title={t("areYouSure")}
              onConfirm={() => deletePosition(i)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Tooltip className="ml-5" title={t("delete")}>
                <Button className="border-none" type="text" shape="circle">
                  <DeleteFilled />
                </Button>
              </Tooltip>
            </Popconfirm>
            <Tooltip className="ml-5" title={t("edit")} placement="topRight">
              <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => setEditingObject(i)}
              >
                <EditFilled />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const setEditingObject = async (i) => {
    setEditing(i);
    await admin.get(`languages`).then((res) => {
      let data = res.data.content.find((c) => {
        return c.id === i;
      });
      setActive(data.is_active === 1 ? true : false);
      form.setFieldsValue({
        name: data.name,
        key: data.key,
      });
    });
  };

  const cancelEditing = () => {
    setEditing(null);
    setActive(false);
    form.resetFields();
  };

  const deletePosition = async (i) => {
    await admin
      .delete(`/languages/${i}`)
      .then(() => {
        // description
        notify("silindi", true);
        getLang();
      })
      .catch((err) => {
        //error
        notify(err.response, false);
      });
  };

  const savePosition = async (values) => {
    let obj = {
      status: isActive ? 1 : 0,
      name: values.name,
      key: values.key,
    };
    if (!editing) {
      await admin
        .post("/languages", obj)
        .then((res) => {
          notify("", true);
          getLang();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    } else {
      obj["id"] = editing;
      await admin
        .put(`/languages/${editing}`, obj)
        .then((res) => {
          notify("", true);
          getLang();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    }
  };

  const getLang = async () => {
    setSpin(true);
    await admin.get("languages").then((res) => {
      setSpin(false);
      setPositions(
        res.data.content.map((p, index) => {
          return { key: index + 1, ...p, index: index + 1, name: p.name };
        })
      );
    });
  };

  useEffect(() => {
    getLang();
  }, [t]);

  const onSwitchChange = () => {
    setActive(!isActive);
  };

  return (
    <Row gutter={[10, 10]}>
      <Col xs={24}>
        <div className="border p-2 mt-0  animated fadeInDown bg-white">
          <UnorderedListOutlined className="f-20 mr5-15" />
          <span className="f-20 bold">Dillər</span>
        </div>
      </Col>
      <Col lg={12} xs={24}>
        <Table
          loading={spin}
          size="small"
          className="bg-white animated fadeInLeft"
          columns={columns}
          dataSource={convertColumns(positions, cols)}
          pagination={{
            pageSize: 10,
            current_page: 1,
            total: positions.length,
          }}
        />
      </Col>
      <Col lg={12} xs={24}>
        <Card title={t("addTo")} className={"animated fadeInRight"}>
          <Form layout="vertical" onFinish={savePosition} form={form}>
            <div className="form-lang">
              <Form.Item
                className="mb-5"
                validateTrigger="onChange"
                name={"name"}
                rules={[whiteSpace(t("inputError"))]}
              >
                <Input placeholder={"Adı"} ref={nameInput} />
              </Form.Item>
            </div>
            <div className="form-lang">
              <Form.Item
                className="mb-5"
                validateTrigger="onChange"
                name={"key"}
                rules={[whiteSpace(t("inputError"))]}
              >
                <Input
                  placeholder={"Açar söz (max 3) (məs  az , en ,ru"}
                  ref={nameInput}
                />
              </Form.Item>
            </div>
            <p className={"mr-10"}>Status</p>
            <div className="flex mt-10 flex-align-center">
              <Switch checked={isActive} onChange={onSwitchChange} />
              <span className="ml-10">{isActive ? "Aktiv" : "Deaktiv"}</span>
            </div>

            <div className="flex  flex-between mt-15">
              <Button onClick={cancelEditing}>{t("cancel")}</Button>
              <Button htmlType="submit">{t("save")}</Button>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default connect(null, { notify })(Languages);
