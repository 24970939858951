import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Button,
  Tooltip,
  Card,
  Popconfirm,
} from "antd";
import {
  PicCenterOutlined,
  EyeFilled,
  DeleteFilled,
  EditFilled,
} from "@ant-design/icons";
import admin from "../../../../const/api";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";
import { notify } from "../../../../redux/actions";

const { Meta } = Card;

const Gallery = (props) => {
  const [spin, setSpin] = useState(true);
  const [gallery, setGallery] = useState([]);
  const { notify } = props;
  let [trigger, setTrigger] = useState(0);

  const { t } = useTranslation();
  const getGalleries = () => {
    setSpin(true);
    admin.get(`galleries`).then((res) => {
      res.data.content && setSpin(false);
      setGallery(
        res.data.content.map((d, index) => {
          return {
            ...d,
            key: index + 1,
            index,
            name: d.locales[0].name,
            description: d.locales[0].description,
            createDate: moment(d.created_at).format("DD-MM-YYYY hh:mm"),
          };
        })
      );
    });
  };

  const deleteGallery = async (i) => {
    if (i === 0 || i) {
      await admin
        .delete(`galleries/${i}`)
        .then((res) => {
          setTrigger(++trigger);
          notify("silindi", true);
        })
        .catch((res) => {
          notify(res.err, false);
        });
    }
  };

  useEffect(() => {
    getGalleries();
  }, [t, trigger]);

  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <PicCenterOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Qalereya</span>
            </div>
            <div>
              <Link
                to={{
                  pathname: `/gallery/edit`,
                  state: { locales: null },
                }}
              >
                <Button type={"primary"}>Əlavə et</Button>
              </Link>
            </div>
          </div>
        </Col>

        <Col xs={24}>
          <Card loading={spin}>
            <Row gutter={[16, 16]}>
              {gallery.map((g, i) => (
                <Col key={i} md={8} sm={24}>
                  <Card
                    className={"animated w-100 zoomIn"}
                    hoverable
                    cover={
                      !spin && (
                        <Link
                          to={{
                            pathname: `/gallery/view/${g.id}`,
                            state: {
                              name: g.locales.find((l) => {
                                return (
                                  l.local === localStorage.getItem("locale")
                                );
                              }).name,
                            },
                          }}
                        >
                          <img alt="example" src={g.thumbnail.path} />
                        </Link>
                      )
                    }
                    actions={
                      !spin && [
                        <Link
                          to={{
                            pathname: `/gallery/edit/${g.id}`,
                            state: { path: g.thumbnail.path },
                          }}
                        >
                          <Tooltip
                            placement="bottom"
                            className="ml-5"
                            title={t("edit")}
                          >
                            <Button
                              // onClick={() => viewPurchaseDetails(i, "waiting", 1)}
                              className="border-none"
                              type="text"
                              shape="circle"
                            >
                              <EditFilled />
                            </Button>
                          </Tooltip>
                          ,
                        </Link>,
                        <Link
                          to={{
                            pathname: `/gallery/view/${g.id}`,
                            state: {
                              name: g.locales.find((l) => {
                                return (
                                  l.local === localStorage.getItem("locale")
                                );
                              }).name,
                            },
                          }}
                        >
                          <Tooltip
                            placement="bottom"
                            className="ml-5"
                            title={t("detailed")}
                          >
                            <Button
                              // onClick={() => viewPurchaseDetails(i, "waiting", 1)}
                              className="border-none"
                              type="text"
                              shape="circle"
                            >
                              <EyeFilled />
                            </Button>
                          </Tooltip>
                        </Link>,
                        <Popconfirm
                          placement="topRight"
                          title={t("areYouSure")}
                          onConfirm={() => deleteGallery(g.id)}
                          okText={t("yes")}
                          cancelText={t("no")}
                        >
                          <Tooltip
                            placement="bottom"
                            className="ml-5"
                            title={t("delete")}
                          >
                            <Button
                              className="border-none"
                              type="text"
                              shape="circle"
                            >
                              <DeleteFilled />
                            </Button>
                          </Tooltip>
                        </Popconfirm>,
                      ]
                    }
                  >
                    <Link
                      to={{
                        pathname: `/gallery/view/${g.id}`,
                        state: {
                          name: g.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                          }).name,
                        },
                      }}
                    >
                      <Meta
                        title={
                          g.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                          }).name
                        }
                        description={
                          g.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                          }).description
                        }
                      />
                    </Link>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default connect(null, { notify })(Gallery);
