import React, {useState , useEffect} from 'react';
import {
    Row,
    Col,
    Table,
    Button,
    Tooltip,
    Popconfirm,
    Modal,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {convertColumns} from "../../../../../utils/columnconverter";
import admin from "../../../../../const/api";
import EditCredit from "../Modals/EditCredit";
import {connect} from "react-redux";
import {notify} from "../../../../../redux/actions";

function Credit(props) {
    const [tableData , setTableData] = useState([])
    const [selected, setSelected] = useState(null);
    const [editCredits, setVisibleEditCredit] = useState(false);

    const { t } = useTranslation();
    const { notify } = props;
    const deleteCredit = async (index) => {
        if (index === 0 || index) {
            await admin
                .delete(`products/properties/${tableData[index].id}`)
                .then((res) => {
                    notify("silindi", true);
                    props.triggerFetch()
                })
                .catch((res) => {
                    notify(res.err, false);
                });
        }
    };


    const editCredit = (e, index) => {
        setSelected(index);
        setVisibleEditCredit(true);
    };


    const cols = [
        { key: "tableIndex", value: "#", con: false },
        { key: "name", value: t('name'), con: false },
        { key: "min_amount", value: "Qısa xülasə", con: false },
        { key: "max_amount", value: "Qısa xülasə", con: false },
        { key: "max_loan_period", value: "Qısa xülasə", con: false },
        { key: "grace_period", value: "Yaradılma tarixi", con: false },
        { key: "index", value: "", con: false },
    ];


    const initialColumns = [
        {
            title: "#",
            dataIndex: "tableIndex",
            key: "1",
            width: 60,
        },
        {
            title: t("name"),
            dataIndex: "name",
            key: "2",
        },
        {
            title: 'Minimal məbləğ (azn)',
            dataIndex: "min_amount",
            align: 'center',
            key: "3",
        },
        {
            title: 'Maximal məbləğ (azn)',
            dataIndex: "max_amount",
            align: 'center',
            key: "2",
        },
        {
            title: 'Maximal müddət (il)',
            dataIndex: "max_loan_period",
            align: 'center',
            key: "2",
        },
        {
            title: 'Güzəşt müddəti (ay)',
            dataIndex: "grace_period",
            align: 'center',
            key: "2",
        },
        {
            title: "",
            dataIndex: "index",
            key: "9",
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                                <Button
                                    onClick={(e) => editCredit(e, i)}
                                    className="border-none" type="text" shape="circle">
                                    <EditFilled />
                                </Button>
                        </Tooltip>
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deleteCredit(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];


    useEffect(()=>{
        setTableData(
            props.data?.map((d, index)=>{
                return {
                    ...d,
                    tableIndex: index + 1,
                    index,
                    name: d.locales.find((l) => {
                        return l.local === localStorage.getItem("locale");
                    }).name,
                    description: d.locales.find((l) => {
                        return l.local === localStorage.getItem("locale");
                    }).description,
                }
            })
        )
    }, [props.data , t])



    return (
        <div className={'w-100 animated fadeIn'}>
            <Row gutter={[10, 10]}>
                <Col xs={24}>
                    <div className="border flex-between flex p-1  flex-align-center px-2 mt-0">
                        <div>
                            <UnorderedListOutlined className="f-14 mr5-15" />
                            <span className="f-14 bold">Kredit növləri</span>
                        </div>
                        <Button
                            onClick={(e) => editCredit(e, null)}
                            size={'small'}
                            className={'f-13'}
                            type="primary">
                            {t("addTo")}
                        </Button>
                    </div>
                </Col>
                <Col xs={24}>
                    <Table
                        size="small"
                        className="border sektorTable"
                        columns={initialColumns}
                        dataSource={convertColumns(tableData, cols)}
                        pagination={false}
                    />
                </Col>
            </Row>


            <Modal
                title={`Kredit növü ${tableData[selected] ? 'redaktə et' : "əlavə et"}`}
                centered
                className="addTaskModal padModal"
                visible={editCredits}
                onOk={() => setVisibleEditCredit(false)}
                onCancel={() => setVisibleEditCredit(false)}
                footer={null}
            >
                <EditCredit
                    credit={tableData[selected]}
                    productId={props.productId}
                    triggerFetch={props.triggerFetch}
                    setVisibleEditCredit={setVisibleEditCredit}
                />
            </Modal>


        </div>
    );
}

export default connect(null, { notify })(Credit);

