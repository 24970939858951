import React, {Component} from 'react';
import {connect} from "react-redux";
import {getLangs , notify} from "../../../../redux/actions";
import {whiteSpace , noWhitespace} from "../../../../utils/rules";
import {withTranslation} from "react-i18next";
import {
    Spin,
    Col,
    Input,
    Modal,
    Form,
    Row,
    Select,
    Button,
    Switch,
} from "antd";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import admin from "../../../../const/api";
import JoditEditor from "jodit-react";
const { Option } = Select;

class EditProjects extends Component {
    formRef = React.createRef();
    editor = React.createRef();

    constructor(props) {
        super(props);
    }

    state = {
        aboutt: {},
        isActive:false,
        file: null,
        photos: [],
        fileList: [],
        previewImage: "",
        previewVisible: false,
        spin:false,
        loading:false,
        regions:[],
    };

    onChange = ({ fileList: newFileList }) => {
        console.log(newFileList)
        this.setState({fileList:newFileList})
        if (newFileList.length <= 0) {
            this.setState({file:null})
        }
    };

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        })
    }
    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage:file.url || file.preview,
            previewVisible:true
        })
    };


    View = (file) => {
        let newFile = file
        if (!file.url) {
            this.getBase64(file.originFileObj).then((res) => {
                if (res) {
                    newFile["url"] = res
                }
            })
        }
        return newFile
    };

    setUploadFile = ({ onSuccess, onError, file }) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("image", file, filename);
        admin
            .post("file/upload/single/image", form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                let arr =  [...this.state.photos]
                let obj ={
                    photo_id: res.data.content.image_uuid,
                    is_cover: false,
                    is_active:true
                }
                arr.push(obj)
                console.log(arr)
                this.setState({file: res.data.content.image_uuid , photos: arr})
                onSuccess(null, file);
            })
            .catch((err) => onError());
    };


    componentDidMount() {
        this.props.getLangs()
        if (this.props.match.params.id) {
            this.setState({
                spin:true,
            })
            this.formRef.current.resetFields()
            let obj = {};
            let datas = {}
            const getPost = async () => {
                await admin.get(`projects/${this.props.match.params.id}`).then((res) => {
                    let arr = [...this.state.fileList];
                    this.setState({file:res.data.content.photo_id});
                    res.data.content.photos.forEach((d) => {
                        let obj = {};
                        obj[`uid`] = d.photo_id;
                        obj[`photo_id`] = d.photo_id;
                        obj[`is_active`] = d.is_active ? true : false;
                        obj[`is_cover`] = d.is_cover ?  true : false;
                        obj[`url`] = d.photo.path;
                        obj[`id`] = d.id;
                        arr.push(obj);
                    });
                    console.log(arr)
                    this.setState({
                        spin:false,
                        fileList:arr,
                        photos:arr,
                        isActive: res.data.content.is_active === 1 ? true : false
                    });
                    res.data.content.locales.forEach((d) => {
                        obj[`name_${d.local}`] = d.name;
                        obj[`description_${d.local}`] = d.description;
                        datas[`description_${d.local}`] = d.description;
                    });
                    obj[`administrative_region_id`] = res.data.content.administrative_region_id;
                    this.setState({
                        aboutt:datas,
                    });
                    if(this.formRef.current !== null){
                        this.formRef.current.setFieldsValue(obj);
                    }
                });
            };
            getPost();
        }
        const getRegions = async () => {
            this.setState({
                spin:true,
            })
            await admin.get(`adm-regions`).then((res) => {
                this.setState({
                    spin:false,
                    regions:res.data.content
                })
            });
        };
        getRegions()
    }


    saveItem = async (values) => {
        let arr = this.state.photos.map((a , i) =>{
            return {
                photo_id:a.photo_id,
                is_cover:a.is_cover,
                is_active:a.is_active,
            }
        })
        arr[0]['is_cover'] = true

        let obj = {
            is_active: this.state.isActive ? 1 : 0,
            administrative_region_id: values.administrative_region_id,
            photos:arr,
            locales: this.props.langs.map((l) => {
                return {
                    local: l.key,
                    name: values[`name_${l.key}`],
                    description: values[`description_${l.key}`],
                };
            }),
        };
        if (!this.props.match.params.id) {
            await admin
                .post(`/projects`, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = this.props.match.params.id;
            await admin
                .put(`/projects/${this.props.match.params.id}`, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };



    render() {
        let { langs , t,  getLangs, notify } = this.props;

        return (
            <div>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                            <div className="page-name">
                                <PicCenterOutlined className="f-20 mr5-15" />
                                <span className="f-20 bold">Layihələr</span>
                            </div>
                            <Link
                                to={{
                                    pathname: `/projects`,
                                }}
                            >
                                <Button type={"primary"}>{t("cancel")}</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={24}>
                        {
                            this.state.spin ?
                                <div className={`${this.props.match.params.id ? 'flex animated fadeIn p-2 bg-white all-center' : 'd-none'}`}>
                                    <Spin size={"large"} />
                                </div>:
                                <div className={`p-2 animated edit fadeInUp bg-white `}>
                                    <Form ref={this.formRef} onFinish={this.saveItem}  layout="vertical">
                                        <Row gutter={[8, 8]}>
                                            <Col sm={24} className={"gallery custom"}>
                                                <Upload
                                                    multiple
                                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture-card"
                                                    fileList={this.state.fileList}
                                                    onChange={this.onChange}
                                                    onPreview={this.handlePreview}
                                                    customRequest={this.setUploadFile}
                                                    beforeUpload={null}
                                                >
                                                    {this.state.fileList.length < 20 &&
                                                    <div className="text-white">+ YÜklə</div>
                                                    }
                                                </Upload>
                                            </Col>

                                            <Col xs={24}>
                                                <div className="flex mb-15">
                                                    <Switch
                                                        className={"mr-15"}
                                                        checked={this.state.isActive}
                                                        onChange={() => {
                                                            this.setState({isActive:!this.state.isActive});
                                                        }}
                                                    />
                                                    <span className="mr-15">Status:</span>
                                                    <span className={this.state.isActive ? "green" : "red"}>
                                                 {this.state.isActive ? "Aktiv" : "Deaktiv"}
                                                </span>
                                                </div>
                                            </Col>
                                            <Col xs={24}>
                                                <Form.Item
                                                    label={'Rayonlar'}
                                                    name={"administrative_region_id"}
                                                    validateTrigger="onChange"
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <Select
                                                        showSearch
                                                        notFoundContent={null}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {
                                                            this.state.regions.map((w, index) => {
                                                                return (
                                                                    <Option key={index} value={w.id}>
                                                                        {w.name}
                                                                    </Option>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24}>
                                                <p className={"mb-15"}>Ad</p>
                                                {this.props.langs.map((l) => (
                                                    <div key={l.id} className="form-lang">
                                                        <Form.Item
                                                            validateTrigger="onChange"
                                                            name={`name_${l.key}`}
                                                            rules={[noWhitespace(t("inputError"))]}
                                                        >
                                                            <Input className="w-100" />
                                                        </Form.Item>
                                                        <span className="input-lang ">{l.key}</span>
                                                    </div>
                                                ))}
                                            </Col>


                                            <Col xs={24}>
                                                {this.props.langs.map((l) => (
                                                    <div key={l.id} className="form-lang">
                                                        <div className="mb-10">
                                                            {l.name}
                                                        </div>
                                                        <Form.Item
                                                            name={`description_${l.key}`}
                                                            validateTrigger="onChange"
                                                            rules={[noWhitespace(t("inputError"))]}
                                                        >
                                                            <JoditEditor
                                                                ref={this.editor}
                                                                value={this.state.aboutt[`description_${l.key}`]}
                                                                tabIndex={1} // tabIndex of textarea
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                ))}
                                            </Col>
                                            <Col xs={24}>
                                                <div className={"flex"}>
                                                    <Button className={"mr-15"} htmlType="submit">
                                                        {t("save")}
                                                    </Button>
                                                    <Link
                                                        to={{
                                                            pathname: `/projects/${this.props.location.state.id}`,
                                                            state: {
                                                                locales: null,
                                                                name: this.props.location.state.name,
                                                            },
                                                        }}
                                                    >
                                                        <Button type={"primary"}>{t("cancel")}</Button>
                                                    </Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                        }

                    </Col>
                </Row>

                <Modal
                    visible={this.state.previewVisible}
                    title={false}
                    footer={null}
                    onCancel={() => {
                        this.setState({previewVisible:false})
                    }}
                >
                    <img alt="example" style={{ width: "100%" }} src={this.state.previewImage}  />
                </Modal>
            </div>
        );
    }
}



const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

const exp = withTranslation()(EditProjects);
export default connect(mapStateToProps, { notify, getLangs })(exp);

