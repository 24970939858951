import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, InputNumber, Row, Switch } from "antd";
import { noWhitespace, whiteSpace } from "../../../../../utils/rules";

function CreditTypes(props) {
  const { t } = useTranslation();
  const {  langs } = props;


  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col md={12}>
          <div>
            <p className={"mb-5"}>Minimum məbləğ</p>
            <div className="form-lang">
              <Form.Item
                validateTrigger="onChange"
                name={"min_amount"}
                rules={[noWhitespace(t("inputError"))]}
              >
                <InputNumber className="w-100" />
              </Form.Item>
              <span className="input-lang ">azn</span>
            </div>
          </div>
          <div>
            <p className={"mb-5"}>Maxium məbləğ</p>
            <div className="form-lang">
              <Form.Item
                validateTrigger="onChange"
                name={"max_amount"}
                rules={[noWhitespace(t("inputError"))]}
              >
                <InputNumber className="w-100" />
              </Form.Item>
              <span className="input-lang ">azn</span>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div>
            <p className={"mb-5"}>Müddət</p>
            <div className="form-lang">
              <Form.Item
                validateTrigger="onChange"
                name={"max_loan_period"}
                rules={[noWhitespace(t("inputError"))]}
              >
                <InputNumber className="w-100" />
              </Form.Item>
              <span className="input-lang ">il</span>
            </div>
          </div>
          <div>
            <p className={"mb-5"}>Güzəşt müddəti</p>
            <div className="form-lang">
              <Form.Item
                name={"grace_period"}
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <InputNumber className="w-100" />
              </Form.Item>
              <span className="input-lang ">ay</span>
            </div>
          </div>
        </Col>

        <Col md={8} sm={12} xs={24}>
          <p className={"mb-15"}>Ad</p>
          {langs.map((l, li) => (
            <div key={l.id} className="form-lang">
              <Form.Item
                validateTrigger="onChange"
                name={`names_${l.key}`}
                rules={[noWhitespace(t("inputError"))]}
              >
                <Input className="w-100" />
              </Form.Item>
              <span className="input-lang ">{l.key}</span>
            </div>
          ))}
        </Col>

        <Col md={16} sm={12} xs={24}>
          <p className={"mb-15"}>Qısa xülasə</p>
          {langs.map((l, li) => (
            <div key={l.id} className="form-lang">
              <Form.Item
                validateTrigger="onChange"
                name={`descriptions_${l.key}`}
                rules={[noWhitespace(t("inputError"))]}
              >
                <Input className="w-100" />
              </Form.Item>
              <span className="input-lang ">{l.key}</span>
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
}

export default CreditTypes;
