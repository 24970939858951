import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Col, Form, Input, Tree, Popconfirm, Row, Select, Spin} from "antd";
import {DeleteFilled, EditFilled, UnorderedListOutlined,} from "@ant-design/icons";
import {getLangs, notify} from "../../../../redux/actions";
import {connect} from "react-redux";
import admin from "../../../../const/api";
import {useTranslation} from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../utils/rules";
import { TreeSelect } from 'antd';
import OrgChart from 'react-orgchart';
import 'react-orgchart/index.css';
const Structure = (props) => {
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const [structures, setStructures] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [data, setD] = useState([]);
    const [spin, setSpin] = useState(false);
    const [editing, setEditing] = useState(null);
    const nameInput = useRef();
    const {notify, getLangs} = props;
    const setEditingObject = async (i) => {
        setEditing(i);
        await admin.get(`/company/structures`).then((res) => {
            let data = searchTree(res.data.content[0] , i)
            if (i === structures.id || !data.parent_id || data?.children?.length ) {setDisabled(true)}  else  {setDisabled(false)}
            let obj = {};
            data?.locales.length > 0 && data.locales.forEach((name) => {
                obj[`name_${name.local}`] = name.name;
                obj[`description_${name.local}`] = name.description;
            })
            obj['parent_id'] =  data?.parent_id
            form.setFieldsValue(obj);
        });
    };
    function searchTree(element, id){
        if(element.id == id){
            return element;
        }else if (element.children != null){
            var i;
            var result = null;
            for(i=0; result == null && i < element.children.length; i++){
                result = searchTree(element.children[i], id);
            }
            return result;
        }
        return null;
    }
    const cancelEditing = () => {
        setDisabled(false)
        setEditing(null);
        form.resetFields();
    };
    const deletePosition = async (i) => {
        await admin
            .delete(`company/structures/${i}`)
            .then(() => {
                notify("silindi", true);
                getPositions();
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };
    const savePosition = async (values) => {
        let obj = {
            locales: props.langs.map((l, index) => {
                return {
                    local: l.key,
                    name: values[`name_${l.key}`],
                    description: 's',
                };
            }),
        };
        if(values?.parent_id) {
            obj['parent_id'] = values.parent_id;
        }
        if (!editing) {
            await admin
                .post("company/structures", obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await admin
                .put(`/company/structures/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async () => {
        setSpin(true);
        await admin.get("company/structures").then((res) => {
            res.data.content.length  && setStructures(res.data.content[0])
            setSpin(false);
            createData(res.data.content[0])
        });
    };

    const createData = (data)  =>{
        let obj = {
            ...data,
            id:data?.id,
            value:data?.id,
            parent_id:data?.parent_id,
            title:data?.locales?.find((t)=>{return t.local === localStorage.getItem( 'locale')}).name,
            children:data?.children
        }
        data?.children && data.children.forEach((s, i)=>{
            obj.children[i] = {
                value:s.id,
                id:s.id,
                parent_id:s.parent_id,
                title:s.locales?.find((t)=>{return t.local === localStorage.getItem( 'locale')}).name,
                children:s.children
            }
            createData(s)
        })
        setD([obj])
    }

    const MyNodeComponent = ({node}) => {
        return (
            <div className="initechNode " >
                <div> {node.title ? node.title : node?.locales?.find((s)=>{return s.local === localStorage.getItem('locale') }).name}</div>
                <Button  className="border-none" type="text" shape="circle"  onClick={() =>{setEditingObject(node.id ? node.id : node.value)}} ><EditFilled/></Button>
                <Popconfirm
                    placement="bottomRight"
                    title={t("areYouSure")}
                    onConfirm={() => deletePosition(node.id ? node.id : node.value)}
                    okText={t("yes")}
                    cancelText={t("no")}
                >
                    <Button className="border-none" type="text" shape="circle">
                        <DeleteFilled />
                    </Button>
                </Popconfirm>
            </div>
        );
    };


    useEffect(() => {
        getPositions();
        getLangs();
    }, [t, props.render]);

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">Fondun strukturu</span>
                </div>
            </Col>
                <Col lg={24} xs={24}>
                    {structures.id &&
                        <Card loading={spin} style={{overflowX: 'auto'}} className="strcte w-100  mb-10">
                            <OrgChart tree={structures} NodeComponent={MyNodeComponent} />
                        </Card>
                    }
                </Col>
            <Col lg={24} xs={24}>
                <Card title={t("addTo")} className={"animated fadeInRight"}>
                    <Form layout="vertical" onFinish={savePosition} form={form}>
                        <p className="mb-5">Adı</p>
                        {props.langs ? (
                            <div>
                                {props.langs.map((l, i) => {
                                    return (
                                        <div key={l.id} className="form-lang">
                                            <Form.Item
                                                className="mb-5"
                                                validateTrigger="onChange"
                                                name={`name_${l.key}`}
                                                rules={[whiteSpace(t("inputError"))]}
                                            >
                                                <Input ref={nameInput}/>
                                            </Form.Item>
                                            <div className="input-lang">{l.key}</div>
                                        </div>
                                    );
                                })}


                                {structures.id &&
                                    <>
                                        <p className="mb-5">Asılı olacağı bölmə</p>
                                        <Form.Item
                                            className="mb-5"
                                            validateTrigger="onChange"
                                            name={`parent_id`}
                                            rules={structures.id !== editing && [noWhitespace(t("inputError"))]}
                                        >
                                            <TreeSelect
                                                disabled={disabled}
                                                showSearch
                                                style={{ width: '100%' }}
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                allowClear
                                                treeDefaultExpandAll
                                                treeData={data}
                                            />
                                        </Form.Item>
                                    </>
                                }

                            </div>
                        ) : (
                            <Spin size={"large"}/>
                        )}

                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({langs}) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, {notify, getLangs})(Structure);




