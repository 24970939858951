import React , {useState , useEffect} from 'react';
import {UnorderedListOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {
    Row,
    Col,
    Button,
    Tabs, Select,
} from "antd";
import List from "./List";
import File from "./File";
import admin from "../../../../const/api";
import {connect} from "react-redux";
import {getLangs, notify} from "../../../../redux/actions";
const { TabPane } = Tabs;
const { Option } = Select;
function Priority(props) {
    const { getLangs} = props;
    const { t } = useTranslation();
    const [tabKey, setTabKey] = useState("1");
    const [selectedProduct, setSelectedProduct] = useState(undefined);
    const [productList, setProductList] = useState([]);
    const [spin, setSpin] = useState(false);
    const [positions, setPositions] = useState([]);

    const handleTabChange = (e) => {
        setTabKey(e);
    };
    const getProductList = async () => {
        await admin.get(`products`).then((res) => {
            setProductList(
                res.data.content.map((d, index) => {
                    return {
                        ...d,
                        key: index + 1,
                        index,
                        name: d.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).name,
                    };
                })
            );
        });
    }

    useEffect(() => {
        let mounted = true
        if (mounted){
            getLangs()
            getProductList()
        }
        return function cleanup() {
            mounted = false
        }
    }, [])

    useEffect(() => {
        getMainData(selectedProduct)
    },[selectedProduct])

    const getMainData = (id) => {
        admin.get(`products/priorities/${id}`).then((res) => {
            setSpin(false);
            setPositions(
                res.data.content.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                        name: p.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).name,
                    };
                })
            );
        });
    }


    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <div className="flex flex-between">
                        <div>
                            <UnorderedListOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Prioritet istiqamətlər</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={24}>
                <div className="bg-white p-1 mb-15">
                    <Select
                        showSearch
                        onChange={(e)=>{setSelectedProduct(e)}}
                        placeholder={'Məhsul'}
                        className={'w-100'}
                        notFoundContent={null}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {
                            productList.map((w, i) => {
                                return (
                                    <Option key={i} value={w.id}>
                                        {w.name}
                                    </Option>
                                );
                            })
                        }
                    </Select>
                </div>
                <div className="tab-section">
                    {selectedProduct &&
                    <Tabs
                        onChange={handleTabChange}
                        defaultActiveKey={tabKey}
                        className="bg-white w-100"
                    >
                        <TabPane tab={'Siyahı'} key="1">
                            <div className="p-2">
                                <List
                                    spin={spin}
                                    positions={positions}
                                    getMainData={getMainData}
                                    setSpin={setSpin}
                                    selectedProduct={selectedProduct}
                                    productList={productList}
                                    tabKey={tabKey} />
                            </div>
                        </TabPane>
                        <TabPane tab={'Fayllar'} key="2">
                            <div className="p-2">
                                <File
                                    spin={spin}
                                    positions={positions}
                                    getMainData={getMainData}
                                    setSpin={setSpin}
                                    selectedProduct={selectedProduct}
                                    productList={productList}
                                    tabKey={tabKey}
                                />
                            </div>
                        </TabPane>
                    </Tabs>
                    }
                </div>
            </Col>
        </Row>
    );
}

export default connect(null, {getLangs})(Priority);



