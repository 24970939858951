import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Form, Input, Spin, Card, Table, Tooltip, Popconfirm} from "antd";
import {DeleteFilled, EditFilled, PicCenterOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import admin from "../../../../const/api";
import history from "../../../../const/history";
import {noWhitespace} from "../../../../utils/rules";
import {connect} from "react-redux";
import {getLangs, notify} from "../../../../redux/actions";
import {convertColumns} from "../../../../utils/columnconverter";

const Mission = (props)  => {
    const { t } = useTranslation();
    const {langs , getLangs , notify} = props
    const [data, setData] = useState([]);
    let [trigger, setTrigger] = useState(0);
    let [editingId, setEditingId] = useState(undefined);
    const [spin, setSpin] = useState(true);
    const [edit, setEdit] = useState(false);
    const [form] = Form.useForm();
    const [isActive, setActive] = useState(false);
    const cols = [
        { key: "key", value: "#", con: false },
        { key: "url", value: " ", con: false },
        { key: "name", value: "Başlıq", con: false },
        { key: "description", value: "Qısa xülasə", con: false },
        { key: "is_active", value: "Status", con: false },
        { key: "editAndDelete", value: "", con: false },
    ];
    const initialColumns = [
        {
            title: '#',
            dataIndex: "key",
            key: "4",
            width: '30px',
        },
        {
            title: ' ',
            dataIndex: "url",
            width: '30px',
            key: "1",
            render: (i) => {
                return <img className={"tableImage"} src={i} alt=""/>
            },
        },
        {
            title: t("name"),
            dataIndex: "name",
            key: "2",
        },
        {
            title: "Qısa xülasə",
            dataIndex: "description",
            key: "3",
        },
        {
            title: "Status",
            dataIndex: "is_active",
            key: "6",
            render: (i) => {
                return i === 1 ? (
                    <span className="green">Aktiv</span>
                ) : (
                    <span className="red">Deaktiv</span>
                );
            },
        },
        {
            title: "",
            dataIndex: "editAndDelete",
            key: "9",
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Link
                                to={{
                                    pathname: `/mission/edit/${i.id}`,
                                    state: {
                                        name: i.name,
                                        missionId: i.missionId
                                    }
                                }}
                            >
                                <Button className="border-none" type="text" shape="circle">
                                    <EditFilled />
                                </Button>
                            </Link>
                        </Tooltip>
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePost(i.id)}
                            okText={t("yes")}
                            cancelText={t("no")}
                            loading={spin}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    const deletePost = async (i) => {
        setSpin(true)
        if (i === 0 || i) {
            await admin
                .delete(`mission-vision/items/${i}`)
                .then((res) => {
                    setSpin(false)
                    props.notify("silindi", true);
                    triggerFetch(++trigger)
                })
                .catch((res) => {
                    setSpin(false)
                    triggerFetch(++trigger)
                    props.notify(res.err, false);
                });
        }
    };

    const triggerFetch = () =>  {
        setTrigger(++trigger)
    }

    const Edit = (id) =>{
        setEdit(true);
        setEditingId(id)
        let editingdata =  data.find((d)=>{return d.id === id})
        let obj = {};
        editingdata.locales.forEach((name) => {
            obj[`title_${name.local}`] = name.title;
            obj[`description_${name.local}`] = name.description;
        });
        form.setFieldsValue(obj);
    }

    const saveItem = async (values) =>{
        let obj = {
            is_active: 1,
            locales: props.langs.map((l) => {
                return {
                    local: l.key,
                    title: values[`title_${l.key}`],
                    description: values[`description_${l.key}`],
                };
            }),
        };
        if (!editingId) {
            await admin
                .post(`mission-vision`, obj)
                .then((res) => {
                    notify("", true);
                    form.resetFields();
                    triggerFetch()
                    setEdit(false)
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            await admin
                .put(`mission-vision/${editingId}`, obj)
                .then((res) => {
                    notify("", true);
                    form.resetFields();
                    triggerFetch()
                    setEdit(false)
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    }

    const getTableData = (data , name) =>{
        return data.map((d ,i )=>{
            return {
                ...d,
                key: i+1,
                url:d.icon.path,
                name: d.locales.find((l)=>{return l.local === localStorage.getItem('locale') }).title,
                description: d.locales.find((l)=>{return l.local === localStorage.getItem('locale') }).description,
                editAndDelete: { missionId : d.mission_vision_id , id: d.id , name},
            }
        })
    }

    useEffect(()=>{
        getLangs();
        admin.get(`mission-vision`).then((res) => {
            res.data.content && setSpin(false);
            setData(
                res.data.content.map((r , index)=>{
                    return {
                        ...r,
                        key: index+1,
                        name: r.locales.find((l)=>{return l.local === localStorage.getItem('locale') }).title,
                        description: r.locales.find((l)=>{return l.local === localStorage.getItem('locale') }).description
                    }
                })
            );
            if(res.data.content === {}){
                history.push('/mission/edit')
            }
        });
    } , [trigger , t])

    return (
        <Row gutter={[16, 16]}>
            {!data.length &&
                <Col xs={24}>
                    <div className="bg-white flex all-center p-2">
                        <Spin size={'large'} />
                    </div>
                </Col>
            }
            {(!spin && data.length < 1)  &&
                <div className="bg-white p-2 animated fadeInUp">
                    <Form form={form} onFinish={saveItem} layout="vertical">
                        <Row gutter={[8, 8]}>
                            <Col md={12} sm={12} xs={24}>
                                <p className={"mb-15"}>Başlıq</p>
                                {langs.map((l) => (
                                    <div key={l.id} className="form-lang">
                                        <Form.Item
                                            validateTrigger="onChange"
                                            name={`title_${l.key}`}
                                            rules={[noWhitespace(t("inputError"))]}
                                        >
                                            <Input className="w-100" />
                                        </Form.Item>
                                        <span className="input-lang ">{l.key}</span>
                                    </div>
                                ))}
                            </Col>
                            <Col md={12} sm={12} xs={24}>
                                <p className={"mb-15"}>Qısa xülasə</p>
                                {langs.map((l) => (
                                    <div key={l.id} className="form-lang">
                                        <Form.Item
                                            validateTrigger="onChange"
                                            name={`description_${l.key}`}
                                            rules={[noWhitespace(t("inputError"))]}
                                        >
                                            <Input className="w-100" />
                                        </Form.Item>
                                        <span className="input-lang ">{l.key}</span>
                                    </div>
                                ))}
                            </Col>
                            <Col xs={24}>
                                <div className="flex flex-between">
                                    <div>
                                        <Button htmlType={'submit'} className={'mr-5'} type={'primary'}>
                                            {t('save')}
                                        </Button>
                                        <Button onClick={()=>{ form.resetFields(); setEdit(false) }} >
                                            {t('cancel')}
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            }
            {data.map((d , index)=>(
                <React.Fragment key={index}>
                    <Col xs={24}>
                        <div className="border  flex-between page-heading flex p-2 mt-0 bg-white">
                            <div className="page-name">
                                <PicCenterOutlined className="f-20 mr5-15" />
                                <span className="f-20 bold">{d.name}</span>
                            </div>
                            {d.mission_vision_items.length < 7 &&
                                <div>
                                    <Link to={{
                                        pathname: `/mission/edit`,
                                        state: {name: d.name , missionId : d.id }
                                    }}>
                                        {" "}
                                        <Button type={"primary"}>{t("addTo")}</Button>
                                    </Link>
                                </div>
                            }

                        </div>
                    </Col>
                    <Col xs={24}>
                        {
                            !edit ?
                                <Card className={'animated fadeInUp'} loading={spin}>
                                    <div className="border p-1">
                                        {d.description}
                                    </div>
                                    <div><Button onClick={()=>{Edit(d.id)}} className={'mt-15'} type={'primary'}>{t('edit')}</Button></div>
                                </Card>:
                                <div className="bg-white p-2 animated fadeInUp">
                                    <Form form={form} onFinish={saveItem} layout="vertical">
                                        <Row gutter={[8, 8]}>
                                            <Col md={12} sm={12} xs={24}>
                                                <p className={"mb-15"}>Başlıq</p>
                                                {langs.map((l) => (
                                                    <div key={l.id} className="form-lang">
                                                        <Form.Item
                                                            validateTrigger="onChange"
                                                            name={`title_${l.key}`}
                                                            rules={[noWhitespace(t("inputError"))]}
                                                        >
                                                            <Input className="w-100" />
                                                        </Form.Item>
                                                        <span className="input-lang ">{l.key}</span>
                                                    </div>
                                                ))}
                                            </Col>
                                            <Col md={12} sm={12} xs={24}>
                                                <p className={"mb-15"}>Qısa xülasə</p>
                                                {langs.map((l) => (
                                                    <div key={l.id} className="form-lang">
                                                        <Form.Item
                                                            validateTrigger="onChange"
                                                            name={`description_${l.key}`}
                                                            rules={[noWhitespace(t("inputError"))]}
                                                        >
                                                            <Input className="w-100" />
                                                        </Form.Item>
                                                        <span className="input-lang ">{l.key}</span>
                                                    </div>
                                                ))}
                                            </Col>
                                            <Col xs={24}>
                                                <div className="flex flex-between">
                                                    <div>
                                                        <Button htmlType={'submit'} className={'mr-5'} type={'primary'}>
                                                            {t('save')}
                                                        </Button>
                                                        <Button onClick={()=>{ form.resetFields(); setEdit(false) }} >
                                                            {t('cancel')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                        }
                    </Col>
                    <Col xs={24}>
                        <div className={'bg-white  animated fadeInUp'}>
                            <div className={'w-100'}>
                                <Table
                                    loading={spin}
                                    columns={initialColumns}
                                    dataSource={convertColumns(getTableData(d.mission_vision_items , d.name), cols)}
                                />
                            </div>
                        </div>
                    </Col>
                </React.Fragment>
            ))}
        </Row>

    );
}


const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify, getLangs })(Mission);

