import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Modal, Row, Switch, Upload } from "antd";
import { noWhitespace, whiteSpace } from "../../../../../utils/rules";
import { useTranslation } from "react-i18next";
import MaskedInput from "antd-mask-input";

function Documents(props) {
  const { t } = useTranslation();
  const { form, langs, useCases, stepper } = props;

  const [inputs, setInputs] = useState([{}]);

  useEffect(() => {
    if (stepper["2"].docs) {
      let keys = Object.keys(stepper["2"].docs);
      let inps = [];
      for (let i = 0; i < stepper["2"].docs[keys[0]].length; i++) {
        inps.push({});
      }
      setInputs(inps);
    }
  }, [stepper]);
  const addinput = () => {
    const all = [...inputs];
    all.push({});
    setInputs(all);
  };

  const removePhone = (index) => {
    const all = [...inputs];
    all.splice(index, 1);
    setInputs(all);
    let vals = form.getFieldsValue();
    Object.keys(vals.docs).forEach((k) => {
      vals.docs[k].splice(index, 1);
    });
    form.setFieldsValue({ docs: vals.docs });
  };

  return (
    <>
      <div className={'mt-10 mb-10'}>
        <Button type={'primary'} onClick={addinput}>{t("addTo")}</Button>
      </div>
      <Row gutter={[16, 16]}>
        {langs.map((lang, index) => {
          return (
            <Col key={index} md={8} xs={24}>
              <p>{lang.name}</p>
              <Row gutter={[16, 16]}>
                {inputs.map((e, i) => {
                  return (
                    <Col xs={24} key={i} className="flex mb-10 ">
                      <Form.Item
                        className={"mb-0 w-100 mr-10"}
                        validateTrigger="onChange"
                        name={["docs", lang.key, i]}
                        rules={[whiteSpace(t("inputError"))]}
                      >
                        <Input className={"mr-10"} />
                      </Form.Item>
                      {inputs.length >= 2 &&
                        <>
                          {lang === langs[langs.length - 1] &&
                          <Button
                              onClick={() => {
                                removePhone(i);
                              }}
                              size={"large"}
                              className="btn-danger"
                          >
                            X
                          </Button>
                          }
                        </>
                      }
                    </Col>
                  );
                })}
              </Row>
            </Col>
          );
        })}
      </Row>
    </>
  );
}

export default Documents;
