import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card, Image } from "antd";
import {
    PicCenterOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import admin from "../../../../const/api";
import { useTranslation } from "react-i18next";
import history from "../../../../const/history";

function Terms(props) {
    const [spin, setSpin] = useState(false);
    const [infos, setInfos] = useState({});
    const [videoID , setVideoId] = useState('')
    const { t } = useTranslation();
    const getInfos = () => {
        setSpin(true);
        admin.get(`terms`).then((res) => {
            res.data.content && setSpin(false);
            setInfos(res.data.content);
            if(!res.data.content){
                history.push('/terms/edit')
            }
        });
    };
    useEffect(() => {
        getInfos();
    }, [t]);
    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <div className="border  flex-between page-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <PicCenterOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Şərtlər və qaydalar</span>
                        </div>
                        <div>
                            <Link to={{
                                pathname: `/terms/edit`
                            }}>
                                {" "}
                                <Button type={"primary"}>{t("edit")}</Button>
                            </Link>
                        </div>
                    </div>
                </Col>
                <Col md={24}>
                    <Card loading={spin} className={"d-block h-100 animated fadeInRight"}>
                        <div
                            className={'font-weight-bold f-16'}
                            dangerouslySetInnerHTML={{
                                __html:
                                infos?.locales?.find((l) => {
                                    return l.local === localStorage.getItem("locale");
                                }).name
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                infos?.locales?.find((l) => {
                                    return l.local === localStorage.getItem("locale");
                                }).description
                            }}
                        />

                    </Card>
                </Col>

            </Row>
        </div>
    );
}

export default Terms;



