import React, { useEffect, useState } from "react";
import { Col, Row, Spin, Button, Card, Form, InputNumber } from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  PicCenterOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import admin from "../../../../const/api";
import { Pie, G2 } from "@ant-design/charts";
import { useTranslation } from "react-i18next";
import { noWhitespace } from "../../../../utils/rules";
import { connect } from "react-redux";
import { notify } from "../../../../redux/actions";

function Currency(props) {
  const { notify } = props;
  const { registerTheme } = G2;
  registerTheme("custom-theme", {
    colors10: ["#171717", "#b2a283", "#404040"],
  });
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const trigger = 0;
  const [spin, setSpin] = useState();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [showWarn, setShowWarn] = useState(false);
  const [curr, setCurr] = useState([]);
  const getCurrency = () => {
    setSpin(true);
    admin.get(`/currencies`).then((res) => {
      res.data.content && setSpin(false);
      setCurr(res.data.content);
      setShowWarn(res.data.content.length === 0);
    });
  };

  const saveItem = async (values) => {
    await admin
      .post(`/statistics`, values)
      .then((res) => {
        notify("", true);
        form.resetFields();
        setShow(false);
        getStatistics();
      })
      .catch((err) => {
        notify(err.response, false);
      });
  };

  const config = {
    data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    label: false,
    state: {
      active: {
        style: {
          lineWidth: 0,
          fillOpacity: 0.65,
        },
      },
    },
    theme: "custom-theme",
    interactions: [
      { type: "element-single-selected" },
      { type: "element-active" },
    ],
  };

  const getStatistics = async () => {
    setSpin(true);
    await admin.get(`statistics`).then((res) => {
      res.data.content && setSpin(false);
      let datas = [
        {
          type: "Sahibkar",
          value: res.data.content?.entrepreneur_count,
        },
        {
          type: "Layihə dəyəri",
          value: res.data.content?.projects_total_cost,
        },
        {
          type: "Güzəştli kredit",
          value: res.data.content?.state_soft_loan_cost,
        },
      ];
      setData(datas);
      form.setFieldsValue(res.data.content);
    });
  };

  useEffect(() => {
    getCurrency();
    getStatistics();
  }, [trigger]);

  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col xs={24}>
          <div className="border animated fadeInDown flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <PicCenterOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Valyuta məzənnəsi və statistika</span>
            </div>
          </div>
        </Col>
        {spin ? (
          <Col xs={24}>
            <div className="animated fadeIn bg-white p-2 w-100 flex all-center">
              <Spin size={"large"} />
            </div>
          </Col>
        ) : (
          <>
            <Col md={10}>
              <Card className={"animated zoomIn"}>
                <div className="flex flex-align-center flex-between">
                  <p>Statistika</p>
                  {!show && (
                    <Button
                      onClick={() => {
                        setShow(true);
                      }}
                      size={"small"}
                      className={"f-12"}
                      type={"primary"}
                    >
                      {t("edit")}
                    </Button>
                  )}
                </div>
                {!show ? (
                  <Pie className={"animated fadeIn pie"} {...config} />
                ) : (
                  <div className={"mt-15 animated fadeIn"}>
                    <Form form={form} onFinish={saveItem} layout="vertical">
                      <Row gutter={[8, 8]}>
                        <Col xs={24}>
                          <Form.Item
                            validateTrigger="onChange"
                            className={"mb-5"}
                            label={"Sahibkar sayı"}
                            name={`entrepreneur_count`}
                            rules={[noWhitespace(t("inputError"))]}
                          >
                            <InputNumber size={"small"} className="w-100" />
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            label={"Layihə dəyəri"}
                            validateTrigger="onChange"
                            className={"mb-5"}
                            name={`projects_total_cost`}
                            rules={[noWhitespace(t("inputError"))]}
                          >
                            <InputNumber size={"small"} className="w-100" />
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            validateTrigger="onChange"
                            className={"mb-5"}
                            label={"Güzəştli kredit kredit (azn)"}
                            name={`state_soft_loan_cost`}
                            rules={[noWhitespace(t("inputError"))]}
                          >
                            <InputNumber size={"small"} className="w-100" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Button
                        className={"f-12 mr-10"}
                        htmlType={"submit"}
                        type={"primary"}
                      >
                        {t("save")}
                      </Button>
                      <Button
                        className={"f-12 mr-10"}
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        {t("cancel")}
                      </Button>
                    </Form>
                  </div>
                )}
              </Card>
            </Col>
            <Col md={14}>
              <Row gutter={[8, 8]}>
                {showWarn && (
                  <Col xs={24}>
                    <div className="flex p-2 bg-white">
                      <WarningOutlined
                        size={"large"}
                        className={"f-25 mt-10 mr-10 red"}
                      />
                      <span className={"f-14"}>
                        Valyuta məzənnəsi <b>Mərkəzi bank</b> tərəfindən
                        yenilənir. Zəhmət olmasa bir müddət sonra yenidən
                        yoxlayın.
                      </span>
                    </div>
                  </Col>
                )}
                {curr.map((e, index) => {
                  return (
                    <Col
                      key={index}
                      className={"animated zoomIn"}
                      md={12}
                      xs={24}
                    >
                      <div className="box-currency p-2 position-relative bg-white">
                        <div className="flex f-14 mb-15">
                          <p className={"mr-15"}>{e.name}</p>
                          <div className={"flex"}>
                            <span className={"mr-10"}>{e.value} azn </span>
                            <span>
                              {e.percent > 0 ? (
                                <b className={`green`}>
                                  <ArrowUpOutlined />{" "}
                                </b>
                              ) : (
                                <b className="red">
                                  <ArrowDownOutlined />
                                </b>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="flex f-14">
                          <span className={"mr-15"}>
                            <b className={e.percent > 0 ? "green" : "red"}>
                              {Math.abs(e.percent)} %
                            </b>
                            {e.percent > 0 ? (
                              <b className={`ml-10 green`}>
                                <ArrowUpOutlined />{" "}
                              </b>
                            ) : (
                              <b className="ml-10 red">
                                <ArrowDownOutlined />
                              </b>
                            )}
                          </span>
                          <p className={"f-12"}>Keçən həftəyə nəzərən</p>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}

export default connect(null, { notify })(Currency);
