import React , {useState} from 'react';
import CreateReport from "./CreateReport";
import ReportTable from "./ReportTable";
import {UnorderedListOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {
    Row,
    Col,
    Button,
    Tabs,
} from "antd";
const { TabPane } = Tabs;
function Reports(props) {
    const { t } = useTranslation();
    const [tabKey, setTabKey] = useState("1");

    const handleTabChange = (e) => {
        setTabKey(e);
    };

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <div className="flex flex-between">
                        <div>
                            <UnorderedListOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Hesabatlar</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={24}>
                <div className="tab-section">
                    <Tabs
                        onChange={handleTabChange}
                        defaultActiveKey={tabKey}
                        className="bg-white w-100"
                    >
                            <TabPane tab={'Hesabatlar'} key="1">
                                <div className="p-2">
                                    <CreateReport tabKey={tabKey} />
                                </div>
                            </TabPane>
                            <TabPane tab={'Hesabat növləri'} key="2">
                                <div className="p-2">
                                     <ReportTable  tabKey={tabKey} />
                                </div>
                            </TabPane>
                    </Tabs>
                </div>
            </Col>
        </Row>
    );
}


export default Reports;
