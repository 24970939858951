import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
    Row,
    Col,
    Table,
    Button,
    Tooltip,
    Spin,
    Popconfirm,
    Modal,
    Select
} from "antd";
import {
    PicCenterOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined,
    EyeFilled,
    DeleteFilled,
    EditFilled,
} from "@ant-design/icons";
import moment from "moment";
import admin from "../../../../const/api";
import { notify , getLangs } from "../../../../redux/actions";
import { useTranslation } from "react-i18next";
import { convertColumns , createColumns} from "../../../../utils/columnconverter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Credit from "./Credit/Credit";
import Add from "./Add"
const {Option} = Select
function ProductProperties(props) {
    const { id } = props.match.params;
    const [postlist, setPostList] = useState();
    const [editing, setEditing] = useState(undefined);
    const [selectedProduct, setSelectedProduct] = useState(undefined);
    const [productList, setProductList] = useState([]);
    const [spin, setSpin] = useState(false);
    const { t } = useTranslation();
    const [visibleAdd, setVisibleAdd] = useState(false);
    let [trigger, setTrigger] = useState(0);

    const cols = [
        { key: "title", value: "Başlıq", con: false },
        { key: "desc", value: "Qısa xülasə", con: false },
        { key: "is_active", value: "Status", con: false },
        { key: "id", value: "", con: false },
    ];

    const initialColumns = [
        {
            title: t("name"),
            dataIndex: "title",
            key: "2",
        },
        {
            title: "Qısa xülasə",
            dataIndex: "desc",
            key: "3",
        },
        {
            title: "Status",
            dataIndex: "is_active",
            key: "6",
            render: (i) => {
                return i === 1 ? (
                    <span className="green">Aktiv</span>
                ) : (
                    <span className="red">Deaktiv</span>
                );
            },
        },
        {
            title: "",
            dataIndex: "id",
            key: "9",
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                                <Button onClick={()=>{addClassification(i)}} className="border-none" type="text" shape="circle">
                                    <EditFilled />
                                </Button>
                        </Tooltip>
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePost(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    const { notify } = props;


    const triggerUpdate = () => {
        getPostList(selectedProduct)
    }

    const triggerFetch = () => {
      setTrigger(++trigger)
        triggerUpdate()
    }

    const modalSetting = (modal , trigger) => {
        setVisibleAdd(modal);
       if  ( trigger ) triggerFetch()
    }

    const deletePost = async (i) => {
        if (i === 0 || i) {
            await admin
                .delete(`products/classifications/${i}`)
                .then((res) => {
                   getPostList(selectedProduct);
                    notify("silindi", true);
                })
                .catch((res) => {
                    notify(res.err, false);
                });
        }
    };

    const getPostList = (id) => {
        setSpin(true);
        admin.get(`products/classifications/${id}`).then((res) => {
            res.data.content && setSpin(false);
            setPostList(
                res.data.content.map((d, index) => {
                    return {
                        ...d,
                        key: index + 1,
                        index,
                        tableIndex: index + 1,
                        catId: d.category_id,
                        title: d.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).tab_name,
                        desc: d.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).description,
                        createDate: moment(d.created_at).format("DD-MM-YYYY hh:mm"),
                    };
                })
            );
        });
    };

    const getExpandIcon = (p) => {
        return p.expanded ? (
            <MinusCircleOutlined
                className="expandingIcon"
                onClick={(e) => p.onExpand(p.record, e)}
            />
        ) : (
            <PlusCircleOutlined
                className="expandingIcon"
                onClick={(e) => p.onExpand(p.record, e)}
            />
        );
    };

    const getProductList = () => {
        setSpin(true);
        admin.get(`products`).then((res) => {
            res.data.content && setSpin(false);
            setProductList(
                res.data.content.map((d, index) => {
                    return {
                        ...d,
                        key: index + 1,
                        index,
                        title: d.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).name,
                    };
                })
            );
        });
    }

    useEffect(() => {
       getProductList()
    }, [t, trigger, id]);

    const addClassification = (data) => {
        setVisibleAdd(true)
       if(data){ setEditing(postlist.find((l) => l.id === data))}
       else {setEditing(undefined)}
    }

    return (
        <div>
            <Row gutter={[10, 10]}>
                <Col xs={24}>
                    <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <PicCenterOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Məhsul xüsusiyyətləri</span>
                        </div>
                        <div>
                            <Button onClick={()=>{addClassification(null)}} type={"primary"}>Əlavə et</Button>
                        </div>
                    </div>
                </Col>

                <>
                    {productList.length > 0 &&
                        <Col xs={24} >
                        <div className="bg-white p-2">
                            <Select
                                placeholder="Məhsul seçin"
                                onChange={(e)=>{getPostList(e)  ;setSelectedProduct(e) }}
                                className={'w-100'}
                                showSearch
                                notFoundContent={null}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {
                                    productList.map((w, i) => {
                                        return (
                                            <Option key={i} value={w.id}>
                                                {w.title}
                                            </Option>
                                        );
                                    })
                                }
                            </Select>
                        </div>
                    </Col>
                    }
                    {spin ? (
                        <Col xs={24}>
                            <div className="flex animated fadeInUp bg-white all-center p-2">
                                <Spin size={"large"} />
                            </div>
                        </Col>
                    ) : (
                        <>
                            <Col xs={24}>
                                <Table
                                    size="small"
                                    className="bg-white animated fadeInUp areasTable"
                                    expandedRowRender={(record, i) => (
                                        <Credit
                                            productId={record.product_id}
                                            triggerFetch={triggerUpdate}
                                            data={record.properties}
                                        />
                                    )}
                                    expandIcon={(props) => getExpandIcon(props)}
                                    columns={createColumns(cols, initialColumns)}
                                    dataSource={convertColumns(postlist, cols)}
                                    pagination={{
                                        pageSize: 10,
                                        current_page: 1,
                                    }}
                                />

                            </Col>
                        </>
                    )}
                </>
            </Row>

            <Modal
                title={`Məhsul  ${editing ? 'xüsusiyyətini redaktə et' : "xüsusiyyəti əlavə et"}`}
                centered
                className="addTaskModal padModal"
                visible={visibleAdd}
                onOk={() => setVisibleAdd(false)}
                onCancel={() => setVisibleAdd(false)}
                footer={null}
            >
                <Add
                    editing={editing}
                    productList={productList}
                    modalSetting={modalSetting}
                />
            </Modal>
        </div>
    );
}


const mapStateToProps = ({ langs }) => {
    return {
        langs,
    };
};


export default connect(mapStateToProps, { notify , getLangs })(ProductProperties);


