import React from "react";
import {Row, Col, Button} from "antd";
import { useTranslation } from "react-i18next";
// import moment from "moment";

const View = (props) => {
    const w = props.message;
    const { t } = useTranslation();

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                    <table className="customtable">
                        <tbody>
                            <tr>
                                <td>Ad</td>
                                <td>{w.name}</td>
                            </tr>
                            <tr>
                                <td>Mail</td>
                                <td>{w.email}</td>
                            </tr>
                            <tr>
                                <td>Telefon</td>
                                <td>{w.phone}</td>
                            </tr>
                            <tr>
                                <td>Vəsiqə nömrəsi</td>
                                <td>{w.passport_number}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>{w.address}</td>
                            </tr>
                            <tr>
                                <td>Müraciət məqsədi</td>
                                <td>{w.request_purpose}</td>
                            </tr>
                             <tr>
                                <td>Qəbulna yazıldığı şəxs</td>
                                <td>{w.person.locale.name}</td>
                            </tr>
                            <tr>
                                <td>Görüş tarixi</td>
                                <td>{w.sendDate}</td>
                            </tr>
                            <tr>
                                <td>İlk müraciət</td>
                                <td>{w.is_first_request ? "Bəli": "Xeyr"}</td>
                            </tr>

                        </tbody>
                    </table>
                </Col>
            </Row>
            <div
                className="modalButtons"
                style={{ position: "absolute", bottom: "15px", right: "22px" }}
            >
                <Button className={'mr-15'} onClick={() => props.setVisibleView(false)}>
                    {t("close")}
                </Button>
                <Button type={'primary'}>
                    <a target='_blank' href={`mailto:${w.email}`}>Mail ilə cavabla</a>
                </Button>
            </div>
        </div>
    );
};

export default View;
