import React,{useEffect, useState , useRef} from 'react';
import {Col, Form, Input, InputNumber, Button, Row, Select} from "antd";
import admin from "../../../../../const/api";
import {getLangs, notify} from "../../../../../redux/actions"
import {noWhitespace , whiteSpace} from "../../../../../utils/rules";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import JoditEditor from "jodit-react";
const {Option} = Select;

function EditCredit(props) {
    const editor = useRef()
    const {  notify , credit ,productId, triggerFetch , langs , getLangs ,setVisibleEditCredit } = props;
    const [isActive , setIsActive] = useState([])
    const [classifications , setClassifications] = useState([])
    const [form] = Form.useForm();
    const { t } = useTranslation();
    useEffect(() => {
        getLangs()
        getClassifications(productId)
        form.resetFields();
        if (credit) {
            let objs = {
                classification_id:credit.classification_id,
                min: credit.min,
                max:credit.max,
                year: credit.year,
            }
            credit.locales.forEach((d) => {
                objs[`name_${d.local}`] = d?.name;
                objs[`text_${d.local}`] = d?.text;
                objs[`loan_period_info_${d.local}`] = d?.loan_period_info;
                objs[`warranty_loan_${d.local}`] = d?.warranty_loan;
                objs[`credit_percentage_${d.local}`] = d?.credit_percentage;
                objs[`ratio_of_loan_${d.local}`] = d?.ratio_of_loan;
            });
            form.setFieldsValue(objs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credit, t]);

    const getClassifications = (id) => {
        admin.get(`products/classifications/${id}`).then(
            (res)=>{
                console.log(res.data.content)
                setClassifications(
                    res.data.content.map((s , i)=>{
                        return {
                            ...s,
                            key:i+1,
                            name: s.locales.find(r => r.local === localStorage.getItem('locale')).tab_name
                        }
                    })
                )
            }
        )
        .catch((err)=>{
            console.log(err)
        })
    }

    const saveCredit = (values) => {
        let obj = {
            product_id:productId,
            classification_id:values.classification_id,
            is_active: isActive ? 1 : 0,
            year: (values.year ? values.year.toString(): '0'),
            locales: props.langs.map((l) => {
                return {
                        local: l.key,
                        name: (values[`name_${l.key}`] ? values[`name_${l.key}`] : undefined),
                        text: (values[`text_${l.key}`] ? values[`text_${l.key}`] : undefined),
                        loan_period_info: (values[`loan_period_info_${l.key}`] ? values[`loan_period_info_${l.key}`] : undefined),
                        credit_percentage: (values[`credit_percentage_${l.key}`] ? values[`credit_percentage_${l.key}`] : undefined),
                        ratio_of_loan: (values[`ratio_of_loan_${l.key}`] ? values[`ratio_of_loan_${l.key}`] : undefined),
                        warranty_loan: (values[`warranty_loan_${l.key}`] ? values[`warranty_loan_${l.key}`] : undefined),
                    };
            })
        }
        obj['min'] = values.min ? values.min.toString() : null
        obj['max'] = values.max ? values.max.toString() : null
        if (credit) {
            admin
                .put(`products/classifications/properties/${credit.id}`, obj)
                .then((res) => {
                    notify("", true);
                    triggerFetch();
                    form.resetFields();
                    setVisibleEditCredit(false);
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            admin
                .post(`products/classifications/properties`, obj)
                .then((res) => {
                    notify("", true);
                    triggerFetch();
                    form.resetFields();
                    setVisibleEditCredit(false);
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };


    return (
        <Form form={form} layout="vertical" onFinish={saveCredit}>
            <div className={'mt-20 mb-15'}>
                <Row gutter={[16, 16]}>
                    <Col md={24} sm={12} xs={24}>
                        <p className={"mb-5"}>Növü</p>
                        <Form.Item
                            className="mb-5"
                            validateTrigger="onChange"
                            name={`classification_id`}
                            rules={[noWhitespace(t("inputError"))]}
                        >
                            <Select
                                className={'w-100'}
                                showSearch
                                notFoundContent={null}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {
                                    classifications.map((r) => (
                                        <Option value={r.id}>
                                            {r.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <strong>Qeyd</strong> minimum və ya maximum məbləğin hər hansı birini yazamaya bilərsiniz.
                    </Col>
                    <Col md={8} xs={24}>
                        <div>
                            <p className={"mb-5"}>Minimum məbləğ</p>
                            <div className="form-lang">
                                <Form.Item
                                    validateTrigger="onChange"
                                    name={"min"}
                                >
                                    <InputNumber className="w-100" />
                                </Form.Item>
                                <span className="input-lang ">azn</span>
                            </div>
                        </div>
                    </Col>
                    <Col md={8} xs={24}>
                        <div>
                            <p className={"mb-5"}>Maxium məbləğ</p>
                            <div className="form-lang">
                                <Form.Item
                                    validateTrigger="onChange"
                                    name={"max"}
                                >
                                    <InputNumber className="w-100" />
                                </Form.Item>
                                <span className="input-lang ">azn</span>
                            </div>
                        </div>
                    </Col>
                    <Col md={8} xs={24}>
                        <div>
                            <p className={"mb-5"}>Müddət</p>
                            <div className="form-lang">
                                <Form.Item
                                    validateTrigger="onChange"
                                    name={"year"}
                                >
                                    <InputNumber className="w-100" />
                                </Form.Item>
                                <span className="input-lang ">il</span>
                            </div>
                        </div>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <p className={"mb-15"}>Ad</p>
                        {langs.map((l, li) => (
                            <div key={l.id} className="form-lang">
                                <Form.Item
                                    validateTrigger="onChange"
                                    className={'mt-15'}
                                    name={`name_${l.key}`}
                                >
                                    <Input className="w-100" />
                                </Form.Item>
                                <span className="input-lang ">{l.key}</span>
                            </div>
                        ))}
                    </Col>
                    {/*<Col md={12} sm={12} xs={24}>*/}
                    {/*    <p className={"mb-15"}>Faiz (rəqəm və ya söz)</p>*/}
                    {/*    {langs.map((l, li) => (*/}
                    {/*        <div key={l.id} className="form-lang">*/}
                    {/*            <Form.Item*/}
                    {/*                validateTrigger="onChange"*/}
                    {/*                className={'mt-15'}*/}
                    {/*                name={`credit_percentage_${l.key}`}*/}
                    {/*            >*/}
                    {/*                <Input className="w-100" />*/}
                    {/*            </Form.Item>*/}
                    {/*            <span className="input-lang ">{l.key}</span>*/}
                    {/*        </div>*/}
                    {/*    ))}*/}
                    {/*</Col>*/}



                    <Col md={12} sm={12} xs={24}>
                        <p className={"mb-15"}>Kredit (Subsidiya) miqdarı haqqında (Rəqəm aralığı olmadıqda qeyd edin)</p>
                        {langs.map((l, li) => (
                            <Form.Item
                                name={`loan_period_info_${l.key}`}
                                validateTrigger="onChange"
                            >
                                <p>{l.name}</p>
                                <JoditEditor
                                    className={'mb-15'}
                                    onChange={(newContent) => {form.setFieldsValue({
                                        [`loan_period_info_${l.key}`] : newContent
                                     })}}
                                    ref={editor}
                                    value={form.getFieldsValue()[`loan_period_info_${l.key}`]}
                                    tabIndex={1} // tabIndex of textarea
                                />
                            </Form.Item>
                        ))}
                    </Col>

                    <Col md={12} sm={12} xs={24}>
                        <p className={"mb-15"}>Kredit ili və Güzəşt müddəti haqqında (il rəqəmlə qeyd edilibsə yanında olacaq cümlə)</p>
                        {langs.map((l, li) => (
                            <Form.Item
                                name={`warranty_loan_${l.key}`}
                                validateTrigger="onChange"
                            >
                                <p>{l.name}</p>
                                <JoditEditor
                                    onChange={(newContent) => {form.setFieldsValue({
                                        [`warranty_loan_${l.key}`] : newContent
                                    })}}
                                    className={'mb-15'}
                                    ref={editor}
                                    value={form.getFieldsValue()[`warranty_loan_${l.key}`]}
                                    tabIndex={1} // tabIndex of textarea
                                />
                            </Form.Item>
                        ))}
                    </Col>

                    <Col md={12} sm={12} xs={24}>
                        <p className={"mb-15"}>Zəmanətin kreditə nisbəti</p>
                        {langs.map((l, li) => (
                            <Form.Item
                                name={`ratio_of_loan_${l.key}`}
                                validateTrigger="onChange"
                            >
                                <p>{l.name}</p>
                                <JoditEditor
                                    className={'mb-15'}
                                    ref={editor}
                                    onChange={(newContent) => {form.setFieldsValue({
                                        [`ratio_of_loan_${l.key}`] : newContent
                                    })}}
                                    value={form.getFieldsValue()[`ratio_of_loan_${l.key}`]}
                                    tabIndex={1} // tabIndex of textarea
                                />
                            </Form.Item>
                        ))}
                    </Col>

                    <Col md={12} sm={12} xs={24}>
                        <p className={"mb-15"}>Ümumi qeyd</p>
                        {langs.map((l, li) => (
                            <Form.Item
                                name={`text_${l.key}`}
                                validateTrigger="onChange"
                            >
                                <p>{l.name}</p>
                                <JoditEditor
                                    onChange={(newContent) => {form.setFieldsValue({
                                        [`text_${l.key}`] : newContent
                                    })}}
                                    className={'mb-15'}
                                    ref={editor}
                                    value={form.getFieldsValue()[`text_${l.key}`]}
                                    tabIndex={1} // tabIndex of textarea
                                />
                            </Form.Item>
                        ))}
                    </Col>
                </Row>
                <div
                    className="modalButtons"
                    style={{ position: "absolute", bottom: "10px", right: "22px" }}
                >
                    <Button onClick={() => setVisibleEditCredit(false)}>
                        {t("cancel")}
                    </Button>
                    <Button type="primary" className="ml-10" htmlType="submit">
                        {t("save")}
                    </Button>
                </div>
            </div>
        </Form>
    );
}

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, {  notify , getLangs})(EditCredit);

