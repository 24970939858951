import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Button,
  Card,
  Modal,
} from "antd";
import {
  PicCenterOutlined,
} from "@ant-design/icons";
import admin from "../../../../const/api";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { notify } from "../../../../redux/actions";
import { Upload } from "antd";


function Full(props) {
  const { notify } = props;
  let id = props.match.params.id;
  let t = 1;
  // file upload
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const onRemove = async (file) => {
    if (file.id === 0 || file.id) {
      await admin
        .delete(`galleries/items/${file.id}`)
        .then((res) => {
          notify("silindi", true);
        })
        .catch((res) => {
          notify(res.err, false);
        });
    }
  };

  const getImages = async () => {
    await admin.get(`galleries/${id}`).then((res) => {
      let arr = [...fileList];
      res.data.content.items.forEach((d) => {
        let obj = {};
        obj[`uid`] = d.media_id;
        obj[`url`] = d.media.path;
        obj[`id`] = d.id;
        arr.push(obj);
      });
      setFileList(arr);
    });
  };

  useEffect(() => {
    getImages();
  }, [t]);

  const setUploadFile = ({ onSuccess, onError, file }) => {
    let form_data = new FormData();
    const filename = Math.random(1, 999999) + Date.now() + file.name;
    form_data.append("image", file, filename);
    admin
      .post("file/upload/single/image", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        let obj = {
          status: 1,
          media_id: res.data.content.image_uuid,
          gallery_id: id,
        };
        admin
          .post(`/galleries/items`, obj)
          .then((res) => {})
          .catch((err) => {
            notify(err.response, false);
          });
        onSuccess(null, file);
      })
      .catch((err) => onError());
  };

  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <PicCenterOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">{props.location.state.name}</span>
            </div>
            <div>
              <Link
                to={{
                  pathname: `/gallery`,
                  state: { locales: null },
                }}
              >
                <Button type={"primary"}>Qalereya</Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={24}>
          <Card>
            <Row gutter={[16, 16]}>
              <Col sm={24} className={"gallery"}>
                {/*<ImgCrop className={'w-100'} rotate aspect={1024 / 576} grid>*/}
                <Upload
                  multiple
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  multiple
                  onPreview={handlePreview}
                  onRemove={onRemove}
                  customRequest={setUploadFile}
                  beforeUpload={null}
                >
                  {fileList.length < 20 && (
                    <div className="text-white">+ YÜklə</div>
                  )}
                </Upload>
                {/*</ImgCrop>*/}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Modal
        visible={previewVisible}
        title={false}
        footer={null}
        onCancel={() => {
          setPreviewVisible(false);
        }}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
}

export default connect(null, { notify })(Full);
