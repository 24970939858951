import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Image,
  Typography,
  List,
  Tabs,
} from "antd";
import { useTranslation } from "react-i18next";
import {connect} from "react-redux";
import { notify} from "../../../../../redux/actions";

const { TabPane } = Tabs;
const { Item } = List;

const View = (props) => {
  const { t } = useTranslation();
  const [useCaseArray, setUseCaseArray] = useState([]);
  const [docsArray, setDocsArray] = useState([]);
  const { selectedData } = props;




  useEffect(() => {
    if (selectedData.use_cases !== null) {
      let useCases =
        selectedData.use_cases[`${localStorage.getItem("locale")}`];
      setUseCaseArray(useCases)
    }
    if (selectedData.required_documents !== null) {
      let docs =
        selectedData.required_documents[`${localStorage.getItem("locale")}`];
      setDocsArray(docs);
    }
  }, [t, selectedData]);

  return (
    <div className="tab-section viewproduct">
      <Tabs defaultActiveKey="1" className="bg-white w-100">
        {selectedData && (
          <TabPane tab="Əsas" key="1">
            <div className="mt-10">
              <Row gutter={[16, 16]}>
                <Col md={8}>
                  {selectedData?.photo && (
                    <Image
                      className={"w-100"}
                      src={selectedData?.photo.path}
                      preview={{
                        maskClassName: "customize-mask",
                      }}
                    />
                  )}
                </Col>
                <Col span={16}>
                  <List size="small" header={false} footer={false} bordered>
                    <Item>
                      <div className="flex w-100 flex-between">
                        <div className="w-50 shorttext">{t("name")}</div>
                        <div className="w-50 text-right ">
                          {selectedData.title}
                        </div>
                      </div>
                    </Item>
                    <Item>
                      <div className="flex w-100 flex-between">
                        <div className="w-50  shorttext">Status</div>
                        <div className="w-50 text-right">
                          {selectedData.status === 1 ? (
                            <div className="green">Aktiv</div>
                          ) : (
                            <div className="red">Passiv</div>
                          )}
                        </div>
                      </div>
                    </Item>
                    <Item>
                      <div className="flex w-100 flex-between">
                        <div className="w-50  shorttext">Yaradılma tarixi</div>
                        <div className="w-50 text-right">
                          {selectedData.createDate}
                        </div>
                      </div>
                    </Item>
                    <Item>
                      <div className="flex w-100 flex-between">
                        <div className="w-50  shorttext">Fond Faizi</div>
                        <div className="w-50 text-right">
                          {selectedData.loan_percent_by_sif} %
                        </div>
                      </div>
                    </Item>
                    <Item>
                      <div className="flex w-100 flex-between">
                        <div className="w-50  shorttext">Bank Faizi</div>
                        <div className="w-50 text-right">
                          {selectedData.loan_percent_by_bank} %
                        </div>
                      </div>
                    </Item>

                    <Item>
                      <div className="w-100">
                        <div className="shorttext">Qısa xülasə</div>
                        <div>{selectedData.desc}</div>
                      </div>
                    </Item>
                  </List>
                </Col>
              </Row>
            </div>
          </TabPane>
        )}

        {selectedData.properties.length > 0 && (
          <TabPane tab="Kredit növləri" key="3">
            {/*<div className="flex p-2">*/}
            {/*  <Button>Əlavə et</Button>*/}
            {/*</div>*/}
            <div className="mt-10">
              {selectedData.properties.map((e, i) => (
                <Card
                  key={i}
                  className={"mb-15 border-primary"}
                  size={"small"}
                  title={false}
                  bordered={true}
                >
                  <div>
                    <span className={"mr-5"}>{i + 1}</span>
                    <span>
                      {
                        e.locales?.find((l) => {
                          return l?.local === localStorage.getItem("locale");
                        }).name
                      }
                    </span>
                  </div>
                  <p>
                    {
                      e.locales?.find((l) => {
                        return l?.local === localStorage.getItem("locale");
                      }).description
                    }
                  </p>
                  <p>
                    Kredit məbləği -{" "}
                    <span className="text-primary font-weight-bold">
                      {e.min_amount}m - {e.max_amount}m
                    </span>
                  </p>
                  <p>
                    Kredit müddəti -{" "}
                    <span className="text-primary font-weight-bold">
                      {" "}
                      {e.max_loan_period}{" "}
                    </span>{" "}
                    ilədək kredit müddəti olmaqla,{" "}
                    <span className="text-primary font-weight-bold">
                      {e.grace_period}{" "}
                    </span>{" "}
                    ayadək güzəşt müddəti
                  </p>
                </Card>
              ))}
            </div>
          </TabPane>
        )}

        {selectedData.use_cases !== null && (
          <TabPane tab="Prioritet istiqamətlər" key="2">
            <List
              className={"mt-5"}
              header={false}
              footer={false}
              bordered
            >
              {useCaseArray.map((item , i)=>(
                  <List.Item key={i}>
                    <Typography.Text>{i + 1}</Typography.Text> {item[i+1]}
                  </List.Item>
              ))}
            </List>
          </TabPane>
        )}

        {selectedData.required_documents !== null && (
          <TabPane tab="Tələb olunan sənədlər" key="5">
            <List
                className={"mt-5"}
                header={false}
                footer={false}
                bordered
            >
              {docsArray.map((item , i)=>(
                  <List.Item key={i}>
                    <Typography.Text>{i + 1}</Typography.Text> {item[i+1]}
                  </List.Item>
              ))}
            </List>
          </TabPane>
        )}
      </Tabs>

      <div
        className="modalButtons"
        style={{ position: "absolute", bottom: "15px", right: "20px" }}
      >
        <Button onClick={() => props.setVisibleView(false)}>
          {t("close")}
        </Button>
      </div>
    </div>
  );
};


export default connect(null, { notify })(View);
