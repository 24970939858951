import React, {Component} from 'react';
import {connect} from "react-redux";
import {getLangs , notify} from "../../../../redux/actions";
import {noWhitespace , whiteSpace} from "../../../../utils/rules";
import {withTranslation} from "react-i18next";
import {
    Spin,
    Col,
    Input,
    Modal,
    Form,
    Row,
    Select,
    Button,
    Switch,
} from "antd";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import admin from "../../../../const/api";
import history from "../../../../const/history";
class EditMission extends Component {
    formRef = React.createRef();
    editor = React.createRef();

    constructor(props) {
        super(props);
    }

    state = {
        aboutt: {},
        isActive:false,
        file: null,
        fileList: [],
        previewImage: "",
        previewVisible: false,
        spin:false,
    };

    onChange = ({ fileList: newFileList }) => {
        this.setState({fileList:newFileList})
        if (newFileList.length <= 0) {
            this.setState({file:null})
        }
    };

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    handlePreview = async (file) => {
        console.log(file)
        if (!file.url && !file.preview) {
            // file.preview = await this.getBase64(file.originFileObj);
            file.preview = file.originFileObj
        }
        console.log(file.preview)
        console.log(file.url)
        this.setState({
            previewImage:file.url || file.preview,
            previewVisible:true
        })
    };

    setUploadFile = ({ onSuccess, onError, file }) => {
        console.log(file)
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("image", file, filename);
        console.log(form_data)
        admin
            .post("file/upload/single/image", form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                this.setState({file: res.data.content.image_uuid})
                onSuccess(null, file);
            })
            .catch((err) => onError());
    };


    componentDidMount() {
        this.props.getLangs()
        if (this.props.match.params.id) {
            this.setState({spin:true})
            this.formRef.current.resetFields()
            let obj = {};
            let datas = {}

            const getPost = async () => {
                await admin.get(`mission-vision`).then((res) => {
                    this.setState({spin:false})
                    console.log(res.data.content)
                    let editedMission = res.data.content.find((d)=>{return d.id === this.props.location.state.missionId } )
                    console.log(editedMission)
                    let editedItem =  editedMission.mission_vision_items.find((d)=>{return d.id === parseInt(this.props.match.params.id)})
                    console.log(editedItem)
                    let arr = [...this.state.fileList];
                    this.setState({file:editedItem.icon_id});
                    arr.push({
                        uid: editedItem.icon_id,
                        url: editedItem.icon.path,
                    });
                    this.setState({
                        fileList:arr,
                        isActive: editedItem.is_active === 1 ? true : false
                    });
                    editedItem.locales.forEach((d) => {
                        obj[`title_${d.local}`] = d.title;
                        obj[`description_${d.local}`] = d.description;
                    });
                    this.setState({
                        aboutt:datas,
                    });
                    if(this.formRef.current !== null){
                        this.formRef.current.setFieldsValue(obj);
                    }
                });
            };
            getPost();
        }
    }


    saveItem = async (values) => {
        let obj = {
            mission_vision_id: this.props.location.state.missionId,
            is_active: this.state.isActive ? 1 : 0,
            icon_id: this.state.file,
            locales: this.props.langs.map((l) => {
                return {
                    local: l.key,
                    title: values[`title_${l.key}`],
                    description: values[`description_${l.key}`],
                };
            }),
        };
        if (!this.props.match.params.id) {
            await admin
                .post(`/mission-vision/items`, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    history.push('/mission')
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = this.props.match.params.id;
            await admin
                .put(`/mission-vision/items/${this.props.match.params.id}`, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    history.push('/mission')
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };



    render() {
        let { langs , t,  getLangs, notify } = this.props;

        return (
            <div>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                            <div className="page-name">
                                <PicCenterOutlined className="f-20 mr5-15" />
                                <span className="f-20 bold">{this.props.location.state.name}</span>
                            </div>
                            <Link
                                to={{
                                    pathname: `/mission`,
                                }}
                            >
                                <Button type={"primary"}>{t("cancel")}</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={24}>
                        {
                            this.state.spin ?
                            <div className={`flex animated fadeIn p-2 bg-white all-center`}>
                                <Spin size={"large"} />
                            </div>:
                            <div className={`p-2 animated edit fadeInUp bg-white`}>
                            <Form ref={this.formRef} onFinish={this.saveItem}  layout="vertical">
                                <Row gutter={[8, 8]}>
                                    <Col xs={4}>
                                        <Form.Item
                                            validateTrigger="onChange"
                                            name={`photo`}
                                            required
                                        >
                                            {/*<ImgCrop*/}
                                            {/*    className={"w-100"}*/}
                                            {/*    rotate*/}
                                            {/*    aspect={1200 / 1500}*/}
                                            {/*    grid*/}
                                            {/*>*/}
                                            {/*    <Upload*/}
                                            {/*        listType="picture-card"*/}
                                            {/*        fileList={this.state.fileList}*/}
                                            {/*        onChange={this.onChange}*/}
                                            {/*        onPreview={this.handlePreview}*/}
                                            {/*        customRequest={this.setUploadFile}*/}
                                            {/*        beforeUpload={null}*/}
                                            {/*    >*/}
                                            {/*        {this.state.fileList.length < 1 && "+ Yüklə"}*/}
                                            {/*    </Upload>*/}
                                            {/*</ImgCrop>*/}

                                            <Upload
                                                listType="picture-card"
                                                fileList={this.state.fileList}
                                                onChange={this.onChange}
                                                onPreview={this.handlePreview}
                                                customRequest={this.setUploadFile}
                                                beforeUpload={null}
                                            >
                                                {this.state.fileList.length < 1 && "+ Yüklə"}
                                            </Upload>
                                        </Form.Item>
                                        <div className="flex mb-15">
                                            <Switch
                                                className={"mr-15"}
                                                checked={this.state.isActive}
                                                onChange={() => {
                                                    this.setState({isActive:!this.state.isActive});
                                                }}
                                            />
                                            <span className="mr-15">Status:</span>
                                            <span className={this.state.isActive ? "green" : "red"}>
                                                 {this.state.isActive ? "Aktiv" : "Deaktiv"}
                                                </span>
                                        </div>
                                    </Col>


                                    <Col md={10} sm={12} xs={24}>
                                        <p className={"mb-15"}>Ad</p>
                                        {this.props.langs.map((l) => (
                                            <div key={l.id} className="form-lang">
                                                <Form.Item
                                                    validateTrigger="onChange"
                                                    name={`title_${l.key}`}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <Input className="w-100" />
                                                </Form.Item>
                                                <span className="input-lang ">{l.key}</span>
                                            </div>
                                        ))}
                                    </Col>

                                    <Col md={10} sm={12} xs={24}>
                                        <p className={"mb-15"}>Qısa xülasə</p>
                                        {this.props.langs.map((l) => (
                                            <div key={l.id} className="form-lang">
                                                <Form.Item
                                                    validateTrigger="onChange"
                                                    name={`description_${l.key}`}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <Input className="w-100" />
                                                </Form.Item>
                                                <span className="input-lang ">{l.key}</span>
                                            </div>
                                        ))}
                                    </Col>

                                    <Col xs={24}>
                                        <div className={"flex"}>
                                            <Button className={"mr-15"} htmlType="submit">
                                                {t("save")}
                                            </Button>
                                            <Link
                                                to={{
                                                    pathname: `/mission`,
                                                }}
                                            >
                                                <Button type={"primary"}>{t("cancel")}</Button>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        }
                    </Col>
                </Row>

                <Modal
                    visible={this.state.previewVisible}
                    title={false}
                    footer={null}
                    onCancel={() => {
                        this.setState({previewVisible:false})
                    }}
                >
                    <img alt="example" style={{ width: "100%" }} src={this.state.previewImage}  />
                </Modal>
            </div>
        );
    }
}



const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

const exp = withTranslation()(EditMission);
export default connect(mapStateToProps, { notify, getLangs })(exp);


