import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Switch, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { noWhitespace } from "../../../../../utils/rules";
import admin from "../../../../../const/api";
import { useTranslation } from "react-i18next";

const Main = (props) => {
  const { t } = useTranslation();
  const {
    file,
    setFile,
    fileList,
    isActive,
    setActive,
    setFileList,
    setFormImage,
    langs,
  } = props;

  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length <= 0) {
      setFile(null);
    }
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const setUploadFile = ({ onSuccess, onError, file }) => {
    let form_data = new FormData();
    const filename = Math.random(1, 999999) + Date.now() + file.name;
    form_data.append("image", file, filename);
    admin
      .post("file/upload/single/image", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setFile(res.data.content.image_uuid);
        setFormImage(res.data.content.image_uuid);
        onSuccess(null, file);
      })
      .catch((err) => onError());
  };

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col xs={12}>
          <Form.Item validateTrigger="onChange" name={`photo`} required>
            <ImgCrop className={"w-100"} rotate aspect={2048 / 1152} grid>
              <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={handlePreview}
                customRequest={setUploadFile}
                beforeUpload={null}
              >
                {fileList.length < 1 && "+ Yüklə"}
              </Upload>
            </ImgCrop>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <div>
            <p className={"mb-5"}>Fond faizi</p>
            <div className="form-lang">
              <Form.Item
                validateTrigger="onChange"
                name={`loan_percent_by_sif`}
                rules={[noWhitespace(t("inputError"))]}
              >
                <Input className="w-100" />
              </Form.Item>
              <span className="input-lang ">%</span>
            </div>
          </div>
          <div>
            <p className={"mb-5"}>Bank faizi</p>
            <div className="form-lang">
              <Form.Item
                validateTrigger="onChange"
                name={`loan_percent_by_bank`}
                rules={[noWhitespace(t("inputError"))]}
              >
                <Input className="w-100" />
              </Form.Item>
              <span className="input-lang ">%</span>
            </div>
          </div>
          <div className="flex mb-5">
            <Switch
              className={"mr-15"}
              checked={isActive}
              onChange={() => {
                setActive(!isActive);
              }}
            />
            <span className="mr-15">Status:</span>
            <span className={isActive ? "green" : "red"}>
              {isActive ? "Aktiv" : "Deaktiv"}
            </span>
          </div>
        </Col>
        <Col md={8} sm={12} xs={24}>
          <p className={"mb-5"}>Ad</p>
          {langs.map((l) => (
            <div key={l.id} className="form-lang">
              <Form.Item
                validateTrigger="onChange"
                name={`name_${l.key}`}
                rules={[noWhitespace(t("inputError"))]}
              >
                <Input className="w-100" />
              </Form.Item>
              <span className="input-lang ">{l.key}</span>
            </div>
          ))}
        </Col>
        <Col md={16} sm={12} xs={24}>
          <p className={"mb-5"}>Qısa xülasə</p>
          {langs.map((l) => (
            <div key={l.id} className="form-lang">
              <Form.Item
                validateTrigger="onChange"
                name={`description_${l.key}`}
                rules={[noWhitespace(t("inputError"))]}
              >
                <Input className="w-100" />
              </Form.Item>
              <span className="input-lang ">{l.key}</span>
            </div>
          ))}
        </Col>
      </Row>
      <Modal
        visible={previewVisible}
        title={false}
        footer={null}
        onCancel={() => {
          setPreviewVisible(false);
        }}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

Main.propTypes = {};
Main.defaultProps = {};

export default Main;
