import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Table,
  Button,
  Tooltip,
  Popconfirm,
  Modal,
  Spin,
} from "antd";
import {
  EyeFilled,
  DeleteFilled,
  UsergroupAddOutlined, EditFilled,
} from "@ant-design/icons";
import moment from "moment";
import admin from "../../../../const/api";
import { notify } from "../../../../redux/actions";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import { connect } from "react-redux";
import View from "./View/View";

function Messages(props) {
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [viewIndex, setViewIndex] = useState(null);
  const [visibleView, setVisibleView] = useState(false);
  const [spin, setSpin] = useState(false);
  let [trigger, setTrigger] = useState(0);

  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "name", value: t("name"), con: true },
    { key: "email", value: t("email"), con: true },
    { key: "sendDate", value: "Göndərilmə tarixi", con: true },
    { key: "status", value:"Status", con: false },
    { key: "id", value: "", con: false },
    { key: "index", value: "", con: false },
  ];

  const setViewed = async (i) => {
    if (i === 0 || i) {
      await admin
          .put(`contact/us/${messages[i].id}` , {status: 0})
          .then((res) => {
            setTrigger(++trigger);
            notify("Oxundu işarələndi", true);
          })
          .catch((err) => {
            notify(err.response, false);
          });
    }
  };

  const initialColumns = [
    {
      title: "#",
      dataIndex: "tableIndex",
      key: "1",
      width: 60,
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "2",
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "3",
    },
    {
      title: "Göndərilmə tarixi",
      dataIndex: "sendDate",
      key: "4",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "5",
      render: (i) => {
        return (
            <div>
              {
                i === 1 ? <span className="green">Yeni</span> : <span className="red">Oxunmuş</span>
              }
            </div>
        )
      }
    },
    {
      title: "",
      dataIndex: "index",
      key: "9",
      render: (i) => {
        return (
          <div className="flex flex-end">
            {
              messages[i].status === 1 &&
              <Popconfirm
                  placement="topRight"
                  title={'Oxundu işarələsin ?'}
                  onConfirm={() => setViewed(i)}
                  okText={t("yes")}
                  cancelText={t("no")}
              >
                <Tooltip className="ml-5" title={"Statusu dəyiş"}>
                  <Button className="border-none" type="text" shape="circle">
                    <EditFilled />
                  </Button>
                </Tooltip>
              </Popconfirm>
            }
            <Popconfirm
              placement="topRight"
              title={t("areYouSure")}
              onConfirm={() => deleteMessage(i)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Tooltip className="ml-5" title={t("delete")}>
                <Button className="border-none" type="text" shape="circle">
                  <DeleteFilled />
                </Button>
              </Tooltip>
            </Popconfirm>
            <Tooltip
              className="ml-5"
              title={t("detailed")}
              placement="topRight"
            >
              <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => viewMessage(i)}
              >
                <EyeFilled />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const { notify } = props;

  const viewMessage = (i) => {
    setViewIndex(i);
    setVisibleView(true);
  };

  const deleteMessage = async (i) => {
    if (i === 0 || i) {
      await admin
        .delete(`contact/us/${messages[i].id}`)
        .then((res) => {
          setTrigger(++trigger);
          notify("silindi", true);
        })
        .catch((err) => {
          notify(err.response, false);
        });
    }
  };

  useEffect(() => {
    getUsers();
  }, [t, trigger]);

  const getUsers = () => {
    setSpin(true);
    admin.get("contact/us").then((res) => {
      res.data.content && setSpin(false);
      setMessages(
        res.data.content.reverse().map((d, index) => {
          return {
            ...d,
            key: index + 1,
            index,
            tableIndex: index + 1,
            sendDate: moment(d.created_at).format("DD-MM-YYYY hh:mm"),
          };
        })
      );
    });
  };

  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <div className="border animated fadeInDown page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <UsergroupAddOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Mesajlar</span>
            </div>
          </div>
        </Col>
        <Col xs={24}>
          {spin ? (
            <div className="flex animated fadeIn p-2 bg-white all-center">
              <Spin size={"large"} />
            </div>
          ) : (
            <Table
              size="small"
              className="bg-white animated fadeInUp"
              columns={initialColumns}
              dataSource={convertColumns(messages, cols)}
              pagination={{
                pageSize: 10,
                current_page: 1,
              }}
            />
          )}
        </Col>
      </Row>
      <Modal
        title={t("detailedInfo")}
        centered
        className={"padModal"}
        visible={visibleView}
        onOk={() => setVisibleView(false)}
        onCancel={() => setVisibleView(false)}
        footer={[]}
      >
        <View setVisibleView={setVisibleView} message={messages[viewIndex]} />
      </Modal>
    </div>
  );
}

export default connect(null, { notify })(Messages);
