import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Table,
  Button,
  Tooltip,
  Spin,
  Popconfirm,
  Modal,
} from "antd";
import {
  PicCenterOutlined,
  EyeFilled,
  DeleteFilled,
  EditFilled,
} from "@ant-design/icons";
import moment from "moment";
import admin from "../../../../const/api";
import { notify, setEdit, getCats } from "../../../../redux/actions";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import { connect } from "react-redux";
import View from "./View";
import { Link } from "react-router-dom";
function Post(props) {
  const { setEdit, edit, cats, getCats } = props;
  const { id } = props.match.params;
  const [postlist, setPostList] = useState([]);
  const [spin, setSpin] = useState(false);
  // const [locales , setLocales] = useState()
  const { t } = useTranslation();
  const [selectedData, setSelectedData] = useState(null);
  // const [editing , setEditing] = useState(null)
  const [visibleView, setVisibleView] = useState(false);
  let [trigger, setTrigger] = useState(0);

  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "title", value: "Başlıq", con: true },
    { key: "desc", value: "Qısa xülasə", con: true },
    { key: "createDate", value: "Yaradılma tarixi", con: false },
    { key: "status", value: "Status", con: false },
    { key: "id", value: "", con: false },
  ];

  const initialColumns = [
    {
      title: "#",
      dataIndex: "tableIndex",
      key: "1",
      width: 60,
    },
    {
      title: "Başlıq",
      dataIndex: "title",
      key: "2",
    },
    {
      title: "Tarixi",
      dataIndex: "date",
      key: "4",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "6",
      render: (i) => {
        return i === 1 ? (
          <span className="green">Aktiv</span>
        ) : (
          <span className="red">Deaktiv</span>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "9",
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Tooltip className="ml-5" title={t("edit")} placement="topRight">
              <Link
                to={{
                  pathname: `/post/${i}`,
                  state: {
                    name: props.location.state.name,
                    locales: postlist.find((p) => {
                      return p.id == i;
                    }).locales,
                    id: id,
                  },
                }}
              >
                <Button className="border-none" type="text" shape="circle">
                  <EditFilled />
                </Button>
              </Link>
            </Tooltip>
            <Popconfirm
              placement="topRight"
              title={t("areYouSure")}
              onConfirm={() => deletePost(i)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Tooltip className="ml-5" title={t("delete")}>
                <Button className="border-none" type="text" shape="circle">
                  <DeleteFilled />
                </Button>
              </Tooltip>
            </Popconfirm>
            <Tooltip
              className="ml-5"
              title={t("detailed")}
              placement="topRight"
            >
              <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => viewMessage(i)}
              >
                <EyeFilled />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const { notify } = props;

  const viewMessage = async (i) => {
    await setSelectedData(i);
    setVisibleView(true);
  };

  const deletePost = async (i) => {
    if (i === 0 || i) {
      await admin
        .delete(`posts/${i}`)
        .then((res) => {
          setTrigger(++trigger);
          notify("silindi", true);
        })
        .catch((res) => {
          notify(res.err, false);
        });
    }
  };

  const getPostList = () => {
    setSpin(true);
    admin.get(`posts`, { params: { category_id: id } }).then((res) => {
      res.data?.content && setSpin(false);
      setPostList(
        res.data.content.map((d, index) => {
          return {
            ...d,
            key: index + 1,
            index,
            tableIndex: index + 1,
            catId: d.category_id,
            title: d.locales.find((l) => {
              return l.local === localStorage.getItem("locale");
            }).title,
            desc: d.locales.find((l) => {
              return l.local === localStorage.getItem("locale");
            }).except,
            createDate: moment(d.created_at).format("DD-MM-YYYY hh:mm"),
          };
        })
      );
    });
  };

  useEffect(() => {
    getPostList();
    getCats();
  }, [t, trigger, id]);

  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <PicCenterOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">{props.location.state.name}</span>
            </div>
            <div>
              <Link
                to={{
                  pathname: `/post`,
                  state: {
                    locales: null,
                    name: props.location.state.name,
                    id: id,
                  },
                }}
              >
                <Button type={"primary"}>Əlavə et</Button>
              </Link>
            </div>
          </div>
        </Col>

        <>
          {spin ? (
            <Col xs={24}>
              <div className="flex animated fadeInUp bg-white all-center p-2">
                <Spin size={"large"} />
              </div>
            </Col>
          ) : (
            <Col xs={24}>
              <Table
                size="small"
                className="bg-white animated fadeIn"
                columns={initialColumns}
                dataSource={convertColumns(postlist, cols)}
                pagination={{
                  pageSize: 25,
                  current_page: 1,
                }}
              />
            </Col>
          )}
        </>
      </Row>

      <Modal
        title={t("detailedInfo")}
        centered
        className={"padModal mediumModal"}
        visible={visibleView}
        onOk={() => setVisibleView(false)}
        onCancel={() => setVisibleView(false)}
        footer={[]}
      >
        <View setVisibleView={setVisibleView} id={selectedData} />
      </Modal>
    </div>
  );
}

const mapStateToProps = ({ edit, cats }) => {
  return { edit, cats };
};

export default connect(mapStateToProps, { notify, setEdit, getCats })(Post);
