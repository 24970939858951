import React, { useEffect, useState } from "react";
import {Row, Col, Spin, Button, Image, Space, Card} from "antd";
import admin from "../../../../const/api";
import { useTranslation } from "react-i18next";

function View(props) {
  const { t } = useTranslation();

  const [postData, setPostData] = useState({});
  const [localed, setLocaledData] = useState({});
  useEffect(() => {
    const getPostList = async () => {
      await admin.get(`posts/${props.id}`).then((res) => {
        setPostData(res.data.content);
        setLocaledData(
          res.data.content.locales.find((e) => {
            return e.local === localStorage.getItem("locale");
          })
        );
      });
    };
    getPostList();
  }, [props.id, t]);

  return (
    <div>
      {props.id === postData.id ? (
        <Row className={"animated fadeInUp"} gutter={[8, 8]}>
          <Col xs={24}>
            <h2>{localed && localed.title}</h2>
          </Col>
          <Col xs={24}>
            {postData?.photo && (
              <div className="flex all-center animated fadeIn">
                <Image
                  className={"w-50 "}
                  src={postData?.photo.path}
                  preview={{
                    maskClassName: "customize-mask",
                  }}
                />
              </div>
            )}
          </Col>
          <Col xs={24}>
            <h4
                dangerouslySetInnerHTML={{
                  __html:
                      localed && localed.body
                }}
                className={"pl-2"}/>
          </Col>
        </Row>
      ) : (
        <div className="w-100 flex all-center">
          <Spin size="large" />
        </div>
      )}
      <div
        className="modalButtons"
        style={{ position: "absolute", bottom: "10px", right: "20px" }}
      >
        <Button className={"mr-15"} onClick={() => props.setVisibleView(false)}>
          {t("close")}
        </Button>
      </div>
    </div>
  );
}

export default View;
