import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Col, Form, Input, Tree, Popconfirm, Row, Select, Spin, InputNumber} from "antd";
import {DeleteFilled, EditFilled, UnorderedListOutlined,} from "@ant-design/icons";
import {getLangs, notify} from "../../../../redux/actions";
import {connect} from "react-redux";
import admin from "../../../../const/api";
import {useTranslation} from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../utils/rules";
import { TreeSelect } from 'antd';
import OrgChart from 'react-orgchart';
import 'react-orgchart/index.css';
const {Option} = Select
const Chart = (props) => {
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const [selectedProduct, setSelectedProduct] = useState(undefined);
    const [productList, setProductList] = useState([]);
    const [structures, setStructures] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [data, setD] = useState([]);
    const [spin, setSpin] = useState(false);
    const [editing, setEditing] = useState(null);
    const nameInput = useRef();
    const {notify, getLangs} = props;
    const setEditingObject = async (i) => {
        setEditing(i);
        await admin.get(`/products/charts/options/${selectedProduct}`).then((res) => {
            let data = searchTree(res.data.content[0] , i)
            if (i === structures.id || !data.parent_id || data?.children?.length ) {setDisabled(true)}  else  {setDisabled(false)}
            let obj = {};
            data?.locales?.length > 0 && data.locales.forEach((name) => {
                obj[`name_${name.local}`] = name.name;
                obj[`number`] = name.description;
            })
            obj['parent_id'] =  data?.parent_id
            obj['yes'] =  data?.yes
            form.setFieldsValue(obj);
        });
    };

    function searchTree(element, id){
        if(element.id == id){
            return element;
        }else if (element.children != null){
            var i;
            var result = null;
            for(i=0; result == null && i < element.children.length; i++){
                result = searchTree(element.children[i], id);
            }
            return result;
        }
        return null;
    }
    const cancelEditing = () => {
        setDisabled(false)
        setEditing(null);
        form.resetFields();
    };
    const deletePosition = async (i) => {
        await admin
            .delete(`products/charts/options/${i}`)
            .then(() => {
                notify("silindi", true);
                getPositions(selectedProduct);
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };
    const savePosition = async (values) => {
        let obj ={
            product_id: selectedProduct,
            is_active:true,
            locales: props.langs.map((l, index) => {
                return {
                    local: l.key,
                    name: values[`name_${l.key}`],
                    description: values.number.toString(),
                };
            }),
        };
        if(values?.parent_id) {
            obj['parent_id'] = values.parent_id;
        }
        obj['yes'] = values.yes === 1 ? true : false;
        if (!editing) {
            await admin
                .post("products/charts/options", obj)
                .then((res) => {
                    notify("", true);
                    getPositions(selectedProduct);
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await admin
                .put(`/products/charts/options/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getPositions(selectedProduct);
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async (i) => {
        setSpin(true);
        await admin.get(`products/charts/options/${i}`).then((res) => {
            console.log(res)
            res?.data?.content?.length > 0  ? setStructures(res.data.content[0]) : setStructures([])
            let  d = res?.data?.content[0];
            setSpin(false);
            createData(d)
        });
    };

    const createData = (data)  =>{
        console.log(data)
        let obj = {
            ...data,
            id:data?.id,
            value:data?.id,
            locales: data?.locales,
            parent_id:data?.parent_id,
            yes: data?.yes,
            title:data?.locales?.find((t)=>{return t.local === localStorage.getItem( 'locale')}).name,
            description:data?.locales?.find((t)=>{return t.local === localStorage.getItem( 'locale')}).description,
            children:data?.children,
            locale:data?.locale
        }
        data?.children && data.children.forEach((s, i)=>{
            obj.children[i] = {
                id:s?.id,
                value:s?.id,
                locales: s?.locales,
                parent_id:s?.parent_id,
                yes: s?.yes,
                title:s?.locales?.find((t)=>{return t.local === localStorage.getItem( 'locale')}).name,
                description:s?.locales?.find((t)=>{return t.local === localStorage.getItem( 'locale')}).description,
                children:s?.children,
                locale:s.locale
            }
            createData(s)
        })

        setD([obj])
    }

    const MyNodeComponent = ({node}) => {
        console.log({node , test: 'test'})
        return (
            <div className={`initechNode ${node.yes === 1 ? 'green' : 'red'}`} >
                <div> {node.title ? node.title : node?.locales ? node?.locales?.find((s)=>{return s.local === localStorage.getItem('locale') }).name : node?.locale?.name}</div>
                <div><span style={{color: 'black'}} >Rəqəm</span> {node.description ? node.description : node?.locales ? node?.locales?.find((s)=>{return s.local === localStorage.getItem('locale') }).description : node?.locale?.description}</div>
                <Button  className="border-none" type="text" shape="circle"  onClick={() =>{setEditingObject(node.id ? node.id : node.value)}} ><EditFilled/></Button>
                <Popconfirm
                    placement="bottomRight"
                    title={t("areYouSure")}
                    onConfirm={() => deletePosition(node.id ? node.id : node.value)}
                    okText={t("yes")}
                    cancelText={t("no")}
                >
                    <Button className="border-none" type="text" shape="circle">
                        <DeleteFilled />
                    </Button>
                </Popconfirm>
            </div>
        );
    };

    const getProductList = async () => {
        await admin.get(`products`).then((res) => {
            setProductList(
                res.data.content.map((d, index) => {
                    return {
                        ...d,
                        key: index + 1,
                        index,
                        name: d.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).name,
                    };
                })
            );
        });
    }


    useEffect(() => {
        getProductList()
        getLangs();
    }, [t, props.render]);

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">Mexanizm şərhi</span>
                </div>
            </Col>
            <Col lg={24} xs={24}>
                <div className="bg-white p-1 ">
                    <Select
                        showSearch
                        onChange={(e)=>{setSelectedProduct(e); getPositions(e)}}
                        placeholder={'Məhsul'}
                        className={'w-100'}
                        notFoundContent={null}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {
                            productList.map((w, i) => {
                                return (
                                    <Option key={i} value={w.id}>
                                        {w.name}
                                    </Option>
                                );
                            })
                        }
                    </Select>
                </div>

                {structures.id &&
                <Card loading={spin} style={{overflowX: 'auto'}} className="strcte w-100 ">
                    <OrgChart tree={structures} NodeComponent={MyNodeComponent} />
                </Card>
                }
            </Col>

            <Col lg={24} xs={24}>
                {selectedProduct &&
                    <Card title={t("addTo")} className={"animated fadeInRight"}>
                        <Form layout="vertical" onFinish={savePosition} form={form}>
                            <p className="mb-5">Adı</p>
                            {props.langs ? (
                                <div>
                                    {props.langs.map((l, i) => {
                                        return (
                                            <div key={l.id} className="form-lang">
                                                <Form.Item
                                                    className="mb-5"
                                                    validateTrigger="onChange"
                                                    name={`name_${l.key}`}
                                                    rules={[whiteSpace(t("inputError"))]}
                                                >
                                                    <Input ref={nameInput}/>
                                                </Form.Item>
                                                <div className="input-lang">{l.key}</div>
                                            </div>
                                        );
                                    })}
                                    <p className="mb-5">Rəqəm</p>
                                    <div className="form-lang">
                                        <Form.Item
                                            className="mb-5"
                                            validateTrigger="onChange"
                                            name={`number`}
                                            rules={[noWhitespace(t("inputError"))]}
                                        >
                                            <InputNumber ref={nameInput}/>
                                        </Form.Item>
                                    </div>

                                    <p className="mb-5">Status</p>
                                    <Form.Item
                                        className="mb-5"
                                        validateTrigger="onChange"
                                        name={`yes`}
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Select>
                                            <Option value={1}>Qəbul edildi</Option>
                                            <Option value={0}>İmtina edildi</Option>
                                        </Select>
                                    </Form.Item>

                                    {structures.id &&
                                    <>
                                        <p className="mb-5">Bir üst mərhələ</p>
                                        <Form.Item
                                            className="mb-5"
                                            validateTrigger="onChange"
                                            name={`parent_id`}
                                            rules={structures.id !== editing && [noWhitespace(t("inputError"))]}
                                        >
                                            <TreeSelect
                                                disabled={disabled}
                                                showSearch
                                                style={{ width: '100%' }}
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                allowClear
                                                treeDefaultExpandAll
                                                treeData={data}
                                            />
                                        </Form.Item>
                                    </>
                                    }

                                </div>
                            ) : (
                                <Spin size={"large"}/>
                            )}

                            <div className="flex  flex-between mt-15">
                                <Button onClick={cancelEditing}>{t("cancel")}</Button>
                                <Button htmlType="submit">{t("save")}</Button>
                            </div>
                        </Form>
                    </Card>
                }
            </Col>
        </Row>
    );
};

const mapStateToProps = ({langs}) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, {notify, getLangs})(Chart);


