import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Spin,
  Col,
  Input,
  Modal,
  InputNumber,
  Form,
  Row,
  Select,
  Button,
  Switch,
} from "antd";
import { connect } from "react-redux";
import { getLangs, notify } from "../../redux/actions";
import { useTranslation } from "react-i18next";
import admin from "../../const/api";
import { whiteSpace, noWhitespace } from "../../utils/rules";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import MaskedInput from "antd-mask-input";
const { Option } = Select;

function EditBranches(props) {
  const [spin, setSpin] = useState(false);
  const [isActive, setActive] = useState(false);
  const [banks, setBanks] = useState([])
  const [regions, setRegions] = useState([])
  let editing = props.match.params.id;
  let { langs } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { getLangs, notify } = props;

  useEffect(() => {
    getLangs();
    form.resetFields();
    if (props.match.params.id) {
      setSpin(true);
      let obj = {};
      const getPost = async () => {
        await admin.get(`banks/branches`).then((res) => {
          let data = res.data.content.find((d) => d.id === parseInt(editing));
          setSpin(false);
          setActive(data.is_active === 1 ? true : false);
          // // temproray
          data.locales.forEach((d) => {
            obj[`name_${d.local}`] = d.name;
            obj[`address_${d.local}`] = d.address;
          });
          obj['phones'] =  data.phones[0].work;
          form.setFieldsValue(obj);
        });
      };
      getPost();
    }
    else{
      const getBanks = async () => {
        await admin.get(`banks`).then((res) => {
          setSpin(false);
          setBanks(
              res.data.content.map((d, index) => {
                return {
                  ...d,
                  index,
                  name: d.locales.find((l) => {
                    return l.local === localStorage.getItem("locale");
                  }).name,
                };
              })
          )
        });
      };
      const getRegions = async () => {
        await admin.get(`adm-regions`).then((res) => {
          setSpin(false);
          setRegions(res.data.content)
        });
      };
      getBanks()
      getRegions()
    }
  }, [t, editing]);

  const saveItem = async (values) => {
    let obj = {
      bank_id: values.bank_id,
      administrative_region_id: values.administrative_region_id,
      is_active: isActive ? 1 : 0,
      locales: props.langs.map((l) => {
        return {
          local: l.key,
          name: values[`name_${l.key}`],
          address: values[`address_${l.key}`],
        };
      }),
      phones: [{work: values.phones}],
    };
    if (!editing) {
      await admin
        .post(`/banks/branches`, obj)
        .then((res) => {
          notify("", true);
          form.resetFields();
          window.history.back();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    } else {
      obj["id"] = editing;
      await admin
        .put(`/banks/branches/${editing}`, obj)
        .then((res) => {
          notify("", true);
          form.resetFields();
          window.history.back();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    }
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <PicCenterOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Bank filialları</span>
            </div>
            <Link
              to={{
                pathname: `/bank-branches`,
                state: { locales: null },
              }}
            >
              <Button type={"primary"}>{t("cancel")}</Button>
            </Link>
          </div>
        </Col>
        <Col xs={24}>
          {spin ? (
            <div className="flex animated fadeIn p-2 bg-white all-center">
              <Spin size={"large"} />
            </div>
          ) : (
            <div className="p-2 animated edit fadeInUp bg-white">
              <Form form={form} onFinish={saveItem} layout="vertical">
                <Row gutter={[8, 8]}>

                  {!editing &&
                    <>
                      <Col md={12} sm={12} xs={24}>
                        <Form.Item
                            label={'Banklar'}
                            name={"bank_id"}
                            validateTrigger="onChange"
                            rules={[noWhitespace(t("inputError"))]}
                        >
                          <Select
                              showSearch
                              notFoundContent={null}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                          >
                            {
                              banks.map((w, i) => {
                                return (
                                    <Option key={i} value={w.id}>
                                      {w.name}
                                    </Option>
                                );
                              })
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={12} xs={24}>
                        <Form.Item
                            label={'Rayonlar'}
                            name={"administrative_region_id"}
                            validateTrigger="onChange"
                            rules={[noWhitespace(t("inputError"))]}
                        >
                          <Select
                              showSearch
                              notFoundContent={null}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                          >
                            {
                              regions.map((w, index) => {
                                return (
                                    <Option key={index} value={w.id}>
                                      {w.name}
                                    </Option>
                                );
                              })
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  }




                  <Col md={8} sm={12} xs={24}>
                    <p className={"mb-15"}>Ad</p>
                    {langs.map((l) => (
                      <div key={l.id} className="form-lang">
                        <Form.Item
                          validateTrigger="onChange"
                          name={`name_${l.key}`}
                          rules={[noWhitespace(t("inputError"))]}
                        >
                          <Input className="w-100" />
                        </Form.Item>
                        <span className="input-lang ">{l.key}</span>
                      </div>
                    ))}
                  </Col>

                  <Col md={16} sm={12} xs={24}>
                    <p className={"mb-15"}>Address</p>
                    {langs.map((l) => (
                      <div key={l.id} className="form-lang">
                        <Form.Item
                          validateTrigger="onChange"
                          name={`address_${l.key}`}
                          rules={[noWhitespace(t("inputError"))]}
                        >
                          <Input className="w-100" />
                        </Form.Item>
                        <span className="input-lang ">{l.key}</span>
                      </div>
                    ))}
                  </Col>

                  <Col xs={12}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={"phones"}
                      rules={[
                        whiteSpace(t("inputError")),
                        {
                          min: 18,
                          message: "Əlaqə nömrəsi tam daxil edilməlidir !",
                        },
                      ]}
                    >
                      <MaskedInput
                        className={"mr-10"}
                        mask="(+994WW) WWW-WW-WW"
                        placeholder="(+99412) 310-23-00"
                        {...props}
                        formatCharacters={{
                          W: {
                            validate(char) {
                              return /\w/.test(char);
                            },
                            transform(char) {
                              return char.toUpperCase();
                            },
                          },
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12}>
                    <div className="flex mt-10">
                      <Switch
                          className={"mr-15"}
                          checked={isActive}
                          onChange={() => {
                            setActive(!isActive);
                          }}
                      />
                      <span className="mr-15">Status:</span>
                      <span className={isActive ? "green" : "red"}>
                        {isActive ? "Aktiv" : "Deaktiv"}
                      </span>
                    </div>
                  </Col>

                  <Col xs={24}>
                    <div className={"flex mt-10"}>
                      <Button className={"mr-15"} htmlType="submit">
                        {t("save")}
                      </Button>
                      <Link
                        to={{
                          pathname: `/bank-branches`,
                          state: { locales: null },
                        }}
                      >
                        <Button type={"primary"}>{t("cancel")}</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = ({ langs }) => {
  return {
    langs: langs,
  };
};

export default connect(mapStateToProps, { notify, getLangs })(EditBranches);
