import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Spin,
  Col,
  Input,
  Modal,
  InputNumber,
  Form,
  Row,
  Select,
  Button,
  Switch,
} from "antd";
import { connect } from "react-redux";
import { getLangs, notify } from "../../redux/actions";
import { useTranslation } from "react-i18next";
import admin from "../../const/api";
import { whiteSpace, noWhitespace } from "../../utils/rules";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
const { Option } = Select;

function EditGallery(props) {
  const [spin, setSpin] = useState(false);
  const [isActive, setActive] = useState(false);
  const [file, setFile] = useState(null);
  const [postData, setPostData] = useState({});
  let editing = props.match.params.id;
  let { langs } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { getLangs, notify } = props;

  // file upload
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length <= 0) {
      setFile(null);
    }
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const setUploadFile = ({ onSuccess, onError, file }) => {
    let form_data = new FormData();
    const filename = Math.random(1, 999999) + Date.now() + file.name;
    form_data.append("image", file, filename);
    admin
      .post("file/upload/single/image", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setFile(res.data.content.image_uuid);
        onSuccess(null, file);
      })
      .catch((err) => onError());
  };

  useEffect(() => {
    getLangs();
    form.resetFields();
    if (props.match.params.id) {
      setSpin(true);
      let obj = {};
      const getPost = async () => {
        await admin.get(`galleries`).then((res) => {
          let data = res.data.content.find((d) => d.id === parseInt(editing));
          setSpin(false);
          let arr = [...fileList];
          setFile(data.thumbnail_id);
          arr.push({ uid: data.thumbnail_id, url: data.thumbnail.path });
          setFileList(arr);
          setActive(data.status === 1 ? true : false);
          // // temproray

          data.locales.forEach((d) => {
            obj[`name_${d.local}`] = d.name;
            obj[`description_${d.local}`] = d.description;
          });

          form.setFieldsValue(obj);
        });
      };
      getPost();
    }
  }, [t, editing]);

  const saveItem = async (values) => {
    let obj = {
      status: isActive ? 1 : 0,
      type: 1,
      // category_id:props.location.state.id,
      thumbnail_id: file,
      locales: props.langs.map((l) => {
        return {
          local: l.key,
          name: values[`name_${l.key}`],
          description: values[`description_${l.key}`],
        };
      }),
    };
    if (!editing) {
      await admin
        .post(`/galleries`, obj)
        .then((res) => {
          notify("", true);
          form.resetFields();
          window.history.back();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    } else {
      obj["id"] = editing;
      await admin
        .put(`/galleries/${editing}`, obj)
        .then((res) => {
          notify("", true);
          form.resetFields();
          window.history.back();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    }
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <PicCenterOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Qalereya</span>
            </div>
            <Link
              to={{
                pathname: `/gallery`,
                state: { locales: null },
              }}
            >
              <Button type={"primary"}>{t("cancel")}</Button>
            </Link>
          </div>
        </Col>
        <Col xs={24}>
          {spin ? (
            <div className="flex animated fadeIn p-2 bg-white all-center">
              <Spin size={"large"} />
            </div>
          ) : (
            <div className="p-2 animated edit fadeInUp bg-white">
              <Form form={form} onFinish={saveItem} layout="vertical">
                <Row gutter={[8, 8]}>
                  <Col xs={12}>
                    <Form.Item
                      validateTrigger="onChange"
                      name={`photo`}
                      required
                    >
                      <ImgCrop
                        className={"w-100"}
                        rotate
                        aspect={1024 / 576}
                        grid
                      >
                        <Upload
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture-card"
                          fileList={fileList}
                          onChange={onChange}
                          onPreview={handlePreview}
                          customRequest={setUploadFile}
                          beforeUpload={null}
                        >
                          {fileList.length < 1 && "+ Yüklə"}
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <div className="flex mb-15">
                      <Switch
                        className={"mr-15"}
                        checked={isActive}
                        onChange={() => {
                          setActive(!isActive);
                        }}
                      />
                      <span className="mr-15">Status:</span>
                      <span className={isActive ? "green" : "red"}>
                        {isActive ? "Aktiv" : "Deaktiv"}
                      </span>
                    </div>
                  </Col>

                  <Col md={8} sm={12} xs={24}>
                    <p className={"mb-15"}>Ad</p>
                    {langs.map((l) => (
                      <div key={l.id} className="form-lang">
                        <Form.Item
                          validateTrigger="onChange"
                          name={`name_${l.key}`}
                          rules={[noWhitespace(t("inputError"))]}
                        >
                          <Input className="w-100" />
                        </Form.Item>
                        <span className="input-lang ">{l.key}</span>
                      </div>
                    ))}
                  </Col>

                  <Col md={16} sm={12} xs={24}>
                    <p className={"mb-15"}>Qısa xülasə</p>
                    {langs.map((l) => (
                      <div key={l.id} className="form-lang">
                        <Form.Item
                          validateTrigger="onChange"
                          name={`description_${l.key}`}
                          rules={[noWhitespace(t("inputError"))]}
                        >
                          <Input className="w-100" />
                        </Form.Item>
                        <span className="input-lang ">{l.key}</span>
                      </div>
                    ))}
                  </Col>

                  <Col xs={24}>
                    <div className={"flex"}>
                      <Button className={"mr-15"} htmlType="submit">
                        {t("save")}
                      </Button>
                      <Link
                        to={{
                          pathname: `/gallery`,
                          state: { locales: null },
                        }}
                      >
                        <Button type={"primary"}>{t("cancel")}</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </Col>
      </Row>

      <Modal
        visible={previewVisible}
        title={false}
        footer={null}
        onCancel={() => {
          setPreviewVisible(false);
        }}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
}

const mapStateToProps = ({ langs }) => {
  return {
    langs: langs,
  };
};

export default connect(mapStateToProps, { notify, getLangs })(EditGallery);
