import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card, Form, Spin } from "antd";
import {
  PicCenterOutlined,
  MailOutlined,
  PrinterOutlined,RightSquareOutlined,
  PhoneOutlined, FacebookOutlined, InstagramOutlined, LinkedinOutlined, YoutubeFilled, TwitterOutlined, YoutubeOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import GoogleM from "../../../Elements/GoogleM";
import admin from "../../../../const/api";
import { useTranslation } from "react-i18next";
import history from '../../../../const/history'

function ContactInfo(props) {
  const [spin, setSpin] = useState(false);
  const [showmap, setShowMap] = useState(false);
  const [phones, setPhones] = useState([]);
  const [fax, setFax] = useState([]);
  const [platform, setPlatform] = useState([]);
  const [facebook, setFacebook] = useState([]);
  const [instagram, setInstagram] = useState([]);
  const [linkedin, setLinkedin] = useState([]);
  const [youtube, setYoutube] = useState([]);
  const [twitter, setTwitter] = useState([]);
  const [mail, setMail] = useState([]);
  const [infos, setInfos] = useState({});
  const { t } = useTranslation();
  const [map, setMap] = useState({
    lat: undefined,
    lng: undefined,
  });
  const getInfos = () => {
    setSpin(true);
    admin.get(`/contact/info`).then((res) => {
      res.data.content[0] && setSpin(false);
      setInfos(res.data.content[0]);
      if (res.data.content[0]){
        setPhones(
            res.data.content[0].contacts.filter((e) => {
              return e.type === 1;
            })
        );
        setFax(
            res.data.content[0].contacts.filter((e) => {
              return e.type === 2;
            })
        );
        setPlatform(
            res.data.content[0].contacts.filter((e) => {
              return e.type === 10;
            })
        );
        setMail(
            res.data.content[0].contacts.filter((e) => {
              return e.type === 3;
            })
        );

        setFacebook(
            res.data.content[0].contacts.filter((e) => {
              return e.type === 5;
            })
        );

        setInstagram(
            res.data.content[0].contacts.filter((e) => {
              return e.type === 9;
            })
        );
        setYoutube(
            res.data.content[0].contacts.filter((e) => {
              return e.type === 6;
            })
        );
        setTwitter(
            res.data.content[0].contacts.filter((e) => {
              return e.type === 7;
            })
        );

        setLinkedin(
            res.data.content[0].contacts.filter((e) => {
              return e.type === 8;
            })
        );
        let obj = {
          lat: eval(res.data.content[0].latitude),
          lng: eval(res.data.content[0].longitude),
        };
        setMap(obj);
        setShowMap(true);
      }
      else{
        history.push('contact-info/edit')
      }
    });
  };
  useEffect(() => {
    getInfos();
  }, [t]);
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div className="border  flex-between pag  e-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <PicCenterOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Əlaqə məlumatları</span>
            </div>
            <div>
              <Link to={"/contact-info/edit"}>
                {" "}
                <Button type={"primary"}>{t("edit")}</Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col md={8}>
          <Card loading={spin} className={"animated fadeInLeft"}>
            <div className="mb-20">
              <p className={"f-18"}>
                <PhoneOutlined /> Telefon
              </p>
              {phones.map((phone, i) => (
                <p key={i} className={"f-17"}>
                  {phone.value}
                </p>
              ))}
            </div>
            <div className="mb-20">
              <p className={"f-18"}>
                <PrinterOutlined /> Faks
              </p>
              {fax.map((phone, i) => (
                <p key={i} className={"f-17"}>
                  {phone.value}
                </p>
              ))}
            </div>
            <div className="mb-20">
              <p className={"f-18"}>
                <RightSquareOutlined/> Elektron kredit platforması
              </p>
              {platform.map((phone, i) => (
                <p key={i} className={"f-17"}>
                  {phone.value}
                </p>
              ))}
            </div>

              <div className="mb-20">
                  <p className={"f-18"}>
                      <MailOutlined /> Email
                  </p>
                  {mail.map((phone, i) => (
                      <p key={i} className={"f-17"}>
                          {phone.value}
                      </p>
                  ))}
              </div>

            <div className="mb-20">
              {facebook.map((phone, i) => (
                  <a target={'_blank'} href={phone.value} key={i} style={{color:'black'}} className={"f-18"}>
                    <FacebookOutlined /> Facebook
                  </a>
              ))}
            </div>

            <div className="mb-20">
              {instagram.map((phone, i) => (
                  <a target={'_blank'} href={phone.value} key={i} style={{color:'black'}} className={"f-18"}>
                    <InstagramOutlined /> Instagram
                  </a>
              ))}
            </div>

            <div className="mb-20">
              {linkedin.map((phone, i) => (
                  <a target={'_blank'} href={phone.value} key={i} style={{color:'black'}} className={"f-18"}>
                    <LinkedinOutlined /> Linkedin
                  </a>
              ))}
            </div>

            <div className="mb-20">
              {youtube.map((phone, i) => (
                  <a target={'_blank'} href={phone.value} key={i} style={{color:'black'}} className={"f-18"}>
                    <YoutubeOutlined /> Youtube
                  </a>
              ))}
            </div>

            <div className="mb-20">
              {twitter.map((phone, i) => (
                  <a target={'_blank'} href={phone.value} key={i} style={{color:'black'}} className={"f-18"}>
                    <TwitterOutlined /> Twitter
                  </a>
              ))}
            </div>
          </Card>
        </Col>
        <Col md={16}>
          <Card loading={spin} className={"d-block h-100 animated fadeInRight"}>
            <div>
              <p className={"f-20"}>{t("Address")}</p>
              <p className={"f-17"}>{infos?.locales?.find((l)=> {return l.local === localStorage.getItem('locale') })?.address}</p>
            </div>
            <div className={"w-100  h-100 d-block position-relative"}>
              {showmap ? (
                <div className="maps">
                  <GoogleM map={map} />
                </div>
              ) : (
                <Card className="flex all-center">
                  <Spin size={"large"}> </Spin>
                </Card>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ContactInfo;
