import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Spin,
  Col,
  Input,
  message,
  Modal,
  InputNumber,
  Form,
  Row,
  Select,
  Steps,
  Button,
  Switch,
} from "antd";
import { connect } from "react-redux";
import { getLangs, notify } from "../../../../redux/actions";
import { useTranslation } from "react-i18next";
import admin from "../../../../const/api";
import history from "../../../../const/history";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Main from "./Forms/Main";
import Direction from "./Forms/Direction";
import Documents from "./Forms/Documents";
import CreditTypes from "./Forms/CreditTypes";
import { first } from "lodash";
import { lang } from "moment";
const { Step } = Steps;

const EditProduct = (props) => {
  const [stepper, setStepper] = useState({
    0: {},
    1: {},
    2: {},
    3: {},
  });
  const [newProduct, setNewProduct] = useState(null);
  const [spin, setSpin] = useState(false);
  const [isActive, setActive] = useState(false);
  const [file, setFile] = useState(null);
  const [inputs, setInputs] = useState([{}]);

  let editing = props.match.params.id;
  let { langs } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { getLangs, notify } = props;
  // file upload
  const [fileList, setFileList] = useState([]);

  const [useCases, setUseCases] = useState({});

  useEffect(() => {
    getLangs();
    form.resetFields();
    if (props.match.params.id) {
      steps.pop();
      setSpin(true);
      let obj = {};
      const getPost = async () => {
        await admin.get(`products`).then((res) => {
          let response = res.data.content.find((p) => {
            return p.id === parseInt(props.match.params.id);
          });
          let docs = {};
          Object.keys(response.required_documents).forEach((k) => {
            docs[k] = response.required_documents[k].map((r) => {
              let ks = Object.keys(r);
              return r[ks[0]];
            });
          });
          let use_cases = {};
          Object.keys(response.use_cases).forEach((k) => {
            use_cases[k] = response.use_cases[k].map((r) => {
              let ks = Object.keys(r);
              return r[ks[0]];
            });
          });
          let st = { ...stepper };
          st["1"] = {
            use_cases,
          };
          st["2"] = {
            docs,
          };
          setStepper(st);
          setSpin(false);
          let arr = [...fileList];
          setFile(
            res.data.content.find((p) => {
              return p.id === parseInt(props.match.params.id);
            }).photo_id
          );
          arr.push({ uid: response.photo_id, url: response.photo.path });
          setFileList(arr);

          setFile(
            res.data.content.find((p) => {
              return p.id === parseInt(props.match.params.id);
            }).photo_id
          );

          setActive(response.status === 1 ? true : false);
          obj["loan_percent_by_sif"] = response.loan_percent_by_sif;
          obj["loan_percent_by_bank"] = response.loan_percent_by_bank;
          response.locales.forEach((d) => {
            obj[`name_${d.local}`] = d.name;
            obj[`description_${d.local}`] = d.description;
          });
          obj["use_cases"] = use_cases;
          obj["docs"] = docs;
          obj["photo"] = response.photo_id;
          form.setFieldsValue(obj);
        });
      };
      getPost();
    }
  }, [t, editing]);

  const setFormImage = (uid) => {
    form.setFieldsValue({
      photo: uid,
    });
  };

  const saveItem = async (values) => {
    let st = { ...stepper };
    st[current] = values;
    setStepper(st);
    if (current < steps.length - 1) {
      next();
    } else {
      let use_cases = {};
      let required_documents = {};
      langs.forEach((l) => {
        use_cases[l.key] = st["1"].use_cases[l.key].map((c, index) => {
          let obj = {};
          obj[index + 1] = c;
          return obj;
        });
        required_documents[l.key] = st["2"].docs[l.key].map((c, index) => {
          let obj = {};
          obj[index + 1] = c;
          return obj;
        });
      });
      let payload = {
          status: isActive ? 1 : 0,
        type: 1,
        photo_id: st["0"].photo,
        loan_percent_by_sif: +st["0"].loan_percent_by_sif,
        loan_percent_by_bank: +st["0"].loan_percent_by_bank,
        locales: langs.map((l) => {
          return {
            name: st["0"][`name_${l.key}`],
            description: st["0"][`description_${l.key}`],
            local: l.key,
          };
        }),
        use_cases,
        required_documents,
      };
      if (editing) {
        admin.put("/products/" + editing, payload).then((res) => {
          notify("" , true)
          history.push("/products");
        });
      } else {
        admin.post("/products", payload).then((res) => {
          if (res.data.content.product_id) {
            let obej = {
              product_id: res.data.content.product_id,
              max_amount: st["3"].max_amount,
              min_amount: st["3"].min_amount,
              max_loan_period: st["3"].max_loan_period,
              grace_period: st["3"].grace_period,
              locales: langs.map((l) => {
                return {
                  name: st["3"][`names_${l.key}`],
                  description: st["3"][`descriptions_${l.key}`],
                  local: l.key,
                };
              }),
            };
            admin.post("/products/properties", obej).then((res2) => {
              notify("" , true)
              history.push("/products");
            });
          }
        });
      }
    }
  };

  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const reset = () => {
    form.resetFields();
    history.push("/products");
    setCurrent(0);
    localStorage.removeItem("obj");
    setActive(false);
    setFileList([]);
    setFile(null);
  };

  const extra = !editing
    ? [
        {
          title: "Kredit növləri",
          content: (
            <CreditTypes
              setInputs={setInputs}
              inputs={inputs}
              form={form}
              langs={langs}
            />
          ),
        },
      ]
    : [];

  const steps = [
    {
      title: "Əsas",
      content: (
        <Main
          isActive={isActive}
          setActive={setActive}
          setFile={setFile}
          file={file}
          stepper={stepper}
          fileList={fileList}
          setFileList={setFileList}
          setFormImage={setFormImage}
          langs={langs}
        />
      ),
    },
    {
      title: "Proitet istiqamətlər",
      content: (
        <Direction
          useCases={useCases}
          form={form}
          isActive={isActive}
          setActive={setActive}
          setFile={setFile}
          file={file}
          stepper={stepper}
          fileList={fileList}
          setFileList={setFileList}
          langs={langs}
        />
      ),
    },
    {
      title: "Sənədlər",
      content: (
        <Documents
          useCases={useCases}
          form={form}
          isActive={isActive}
          setActive={setActive}
          setFile={setFile}
          file={file}
          fileList={fileList}
          stepper={stepper}
          setFileList={setFileList}
          langs={langs}
        />
      ),
    },
    ...extra,
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
          <div className="page-name">
            <PicCenterOutlined className="f-20 mr5-15" />
            <span className="f-20 bold">Məhsullar</span>
          </div>
          <Link
            to={{
              pathname: `/products`,
              state: { locales: null },
            }}
          >
            <Button type={"primary"}>{t("cancel")}</Button>
          </Link>
        </div>
      </Col>
      <Col xs={24}>
        {spin ? (
          <div className="flex animated fadeIn p-2 bg-white all-center">
            <Spin size={"large"} />
          </div>
        ) : (
          <div className="p-2 animated edit large fadeInUp bg-white">
            <Form form={form} onFinish={saveItem} layout="vertical">
              <Steps current={current}>
                {steps.map((item, i) => (
                  <Step key={i} title={item.title} />
                ))}
              </Steps>
              <div className="steps-content mt-20">
                {steps[current].content}
              </div>
              <div className="steps-action">
                {current === 0 && (
                  <Button
                    className="animated fadeIn"
                    style={{ margin: "0 8px" }}
                    onClick={reset}
                  >
                    Ləğv et
                  </Button>
                )}
                {current > 0 && (
                  <Button
                    className="animated fadeIn"
                    style={{ margin: "0 8px" }}
                    onClick={prev}
                  >
                    Geri
                  </Button>
                )}

                {current < steps.length && (
                  <Button
                    htmlType={"submit"}
                    className="animated fadeIn"
                    type="primary"
                  >
                    Yadda saxla
                  </Button>
                )}
              </div>
            </Form>
          </div>
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ langs }) => {
  return {
    langs: langs,
  };
};

export default connect(mapStateToProps, { notify, getLangs })(EditProduct);
