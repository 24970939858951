import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Table,
  Button,
  Tooltip,
  Spin,
  Popconfirm,
} from "antd";
import {
  PicCenterOutlined,
  DeleteFilled,
  EditFilled,
} from "@ant-design/icons";
import admin from "../../../../const/api";
import { notify, setEdit, getCats } from "../../../../redux/actions";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function BankBranches(props) {
  const { getCats } = props;
  const { id } = props.match.params;
  const [postlist, setPostList] = useState([]);
  const [spin, setSpin] = useState(false);
  const { t } = useTranslation();
  let [trigger, setTrigger] = useState(0);

  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "name", value: "Başlıq", con: true },
    { key: "address", value: "Adress", con: true },
    { key: "phone", value: "Əlaqə", con: true },
    { key: "is_active", value: "Status", con: false },
    { key: "index", value: "", con: false },
  ];

  const initialColumns = [
    {
      title: "#",
      dataIndex: "tableIndex",
      key: "1",
      width: 60,
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "2",
    },
    {
      title: "Ünvan",
      dataIndex: "address",
      key: "3",
    },
    {
      title: "Əlaqə nömrəsi",
      dataIndex: "phone",
      key: "3",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "6",
      render: (i) => {
        return i === 1 ? (
          <span className="green">Aktiv</span>
        ) : (
          <span className="red">Deaktiv</span>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "9",
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Tooltip className="ml-5" title={t("edit")} placement="topRight">
              <Link
                to={{
                  pathname: `/bank-branches/edit/${i}`,
                }}
              >
                <Button className="border-none" type="text" shape="circle">
                  <EditFilled />
                </Button>
              </Link>
            </Tooltip>
            <Popconfirm
              placement="topRight"
              title={t("areYouSure")}
              onConfirm={() => deletePost(i)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Tooltip className="ml-5" title={t("delete")}>
                <Button className="border-none" type="text" shape="circle">
                  <DeleteFilled />
                </Button>
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const { notify } = props;

  const deletePost = async (i) => {
    if (i === 0 || i) {
      await admin
        .delete(`banks/branches/${i}`)
        .then((res) => {
          setTrigger(++trigger);
          notify("silindi", true);
        })
        .catch((res) => {
          notify(res.err, false);
        });
    }
  };

  const getPostList = () => {
    setSpin(true);
    admin.get(`banks/branches`).then((res) => {
      res.data.content && setSpin(false);
      setPostList(
        res.data.content.map((d, index) => {
          return {
            ...d,
            key: index + 1,
            index,
            tableIndex: index + 1,
            phone: d.phones[0].work,
            name: d.locales.find((l) => {
              return l.local === localStorage.getItem("locale");
            }).name,
            address: d.locales.find((l) => {
              return l.local === localStorage.getItem("locale");
            }).address,
          };
        })
      );
    });
  };

  useEffect(() => {
    getPostList();
    getCats();
  }, [t, trigger, id]);

  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <PicCenterOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Bank filialları</span>
            </div>
            <div>
              <Link
                to={{
                  pathname: `/bank-branches/edit`,
                  state: { locales: null },
                }}
              >
                <Button type={"primary"}>Əlavə et</Button>
              </Link>
            </div>
          </div>
        </Col>

        <>
          {spin ? (
            <Col xs={24}>
              <div className="flex animated fadeInUp bg-white all-center p-2">
                <Spin size={"large"} />
              </div>
            </Col>
          ) : (
            <Col xs={24}>
              <Table
                size="small"
                className="bg-white animated fadeIn"
                columns={initialColumns}
                dataSource={convertColumns(postlist, cols)}
                pagination={{
                  pageSize: 25,
                  current_page: 1,
                }}
              />
            </Col>
          )}
        </>
      </Row>
    </div>
  );
}

const mapStateToProps = ({ edit, cats }) => {
  return { edit, cats };
};

export default connect(mapStateToProps, { notify, setEdit, getCats })(
  BankBranches
);
