import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card, Image } from "antd";
import {
    PicCenterOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import admin from "../../../../const/api";
import { useTranslation } from "react-i18next";
import history from "../../../../const/history";

function About(props) {
    const [spin, setSpin] = useState(false);
    const [infos, setInfos] = useState({});
    const [videoID , setVideoId] = useState('')
    const { t } = useTranslation();
    const getInfos = () => {
        setSpin(true);
        admin.get(`company/about`).then((res) => {
            res.data.content && setSpin(false);
            if(!res.data.content){
                history.push('/about/edit')
            }
            else{
                setInfos(res.data.content);
                function getId(url) {
                    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                    const match = url.match(regExp);
                    return (match && match[2].length === 11)
                        ? match[2]
                        : null;
                }
                const videoId = getId(res?.data?.content?.link);
                setVideoId(videoId)
            }
        });
    };
    useEffect(() => {
        getInfos();
    }, [t]);
    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <div className="border  flex-between page-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <PicCenterOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Şirkət haqqında</span>
                        </div>
                        <div>
                            <Link to={{
                                pathname: `/about/edit`
                            }}>
                                {" "}
                                <Button type={"primary"}>{t("edit")}</Button>
                            </Link>
                        </div>
                    </div>
                </Col>
                <Col md={16}>
                    <Card loading={spin} className={"d-block h-100 animated fadeInRight"}>
                        <h2 className={'font-weight-bold'}>Şuar</h2>
                        <div
                            className={'font-weight-bold f-16'}
                            dangerouslySetInnerHTML={{
                                __html:
                                infos?.locales?.find((l) => {
                                    return l.local === localStorage.getItem("locale");
                                }).slogan
                            }}
                        />
                        <h2 className={'font-weight-bold'}>Məlumat</h2>
                        <div
                        dangerouslySetInnerHTML={{
                            __html:
                            infos?.locales?.find((l) => {
                                return l.local === localStorage.getItem("locale");
                            }).about
                        }}
                        />

                    </Card>
                </Col>
                <Col md={8}>
                    <Card loading={spin} className={"animated fadeInLeft"}>
                        <Image
                            className={'w-100'}
                            src={infos?.picture?.path}
                        />
                    </Card>
                    <Card loading={spin} className={"animated fadeInLeft"}>
                        <iframe style={{height:'200px'}} className={'w-100'} src={`https://www.youtube.com/embed/${videoID}`}
                                frameBorder='0'
                                allow='autoplay; encrypted-media'
                                allowFullScreen
                                title='video'
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default About;

